<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4259 0.652017C11.2952 -0.217341 12.7048 -0.217338 13.5741 0.652018L23.348 10.4259C24.2173 11.2952 24.2173 12.7048 23.348 13.5741L13.5741 23.348C12.7048 24.2173 11.2952 24.2173 10.4259 23.348L0.652017 13.5741C-0.217341 12.7048 -0.217338 11.2952 0.652018 10.4259L10.4259 0.652017ZM12.5247 1.70143C12.2349 1.41164 11.7651 1.41164 11.4753 1.70142L1.70143 11.4753C1.41164 11.7651 1.41164 12.2349 1.70142 12.5247L11.4753 22.2986C11.7651 22.5884 12.2349 22.5884 12.5247 22.2986L22.2986 12.5247C22.5884 12.2349 22.5884 11.7651 22.2986 11.4753L12.5247 1.70143Z" fill="#F0CE0E"/>
    <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#F0CE0E"/>
    <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#F0CE0E"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>