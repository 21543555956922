<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3943_12444)">
      <path d="M24.5002 4H7.50024V6H24.5002V4Z" fill="#0A5CD5"/>
      <path d="M24.5002 11H7.50024V13H24.5002V11Z" fill="#0A5CD5"/>
      <path d="M24.5002 18H7.50024V20H24.5002V18Z" fill="#0A5CD5"/>
      <path d="M2.5 6.99999C3.60457 6.99999 4.5 6.10456 4.5 5C4.5 3.89543 3.60457 3 2.5 3C1.39543 3 0.5 3.89543 0.5 5C0.5 6.10456 1.39543 6.99999 2.5 6.99999Z" fill="#0A5CD5"/>
      <path d="M2.5 14C3.60457 14 4.5 13.1046 4.5 12C4.5 10.8954 3.60457 10 2.5 10C1.39543 10 0.5 10.8954 0.5 12C0.5 13.1046 1.39543 14 2.5 14Z" fill="#0A5CD5"/>
      <path d="M2.5 21C3.60457 21 4.5 20.1046 4.5 19C4.5 17.8954 3.60457 17 2.5 17C1.39543 17 0.5 17.8954 0.5 19C0.5 20.1046 1.39543 21 2.5 21Z" fill="#0A5CD5"/>
    </g>
    <defs>
      <clipPath id="clip0_3943_12444">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>


</template>

<script setup>

</script>

<style scoped>

</style>