<template>
    <LKLayout>
        <Sheet v-if="fetched" class="h-min !p-[28px] md:!rounded-[24px] gap-y-[50px]">
            <div class="flex flex-row justify-between">
                <div class="flex flex-col">
                    <div class="text-[16px] leading-[19.6px] font-medium">Cчет N{{ order.id }}</div>
                    <div class="text-[16px] leading-[19.6px]">{{ order.name }}</div>
                    <div class="text-[16px] leading-[19.6px]">Сумма {{ order.amount }} руб.</div>
                </div>
                <div class="text-[12px] leading-[14.7px] text-[#7B7A7A] font-light">{{
                    formatDateTime(order.created_at) }}
                </div>
            </div>

            <div>
                <div class="text-[16px] leading-[19.6px] font-medium mt-5 mb-5">Файл акта:</div>
                <UIFileUploader v-if="order.acceptance_certificate" v-model="order.acceptance_certificate">

                </UIFileUploader>
                <UIFileUploader v-else v-model="order.acceptance_certificate">

                </UIFileUploader>
            </div>

            <div>
                <div class="text-[16px] leading-[19.6px] font-medium mt-5 mb-5">Файл счета:</div>
                <UIFileUploader v-if="order.bill" v-model="order.bill">

                </UIFileUploader>
                <UIFileUploader v-else v-model="order.bill">

                </UIFileUploader>
            </div>

            <div class="text-[16px] leading-[19.6px] font-medium mt-5 mb-5">Статус:</div>
            <UISelect :options="statuses" v-model="order.status">
                    <template #prepend-dropdown>
                        <div class="w-[140px]"></div>
                    </template>
            </UISelect>

            <div class="text-[16px] leading-[19.6px] font-medium mt-5 mb-5">Реквизиты:</div>
            <div class="flex flex-col gap-y-3">


                <div class="inline-flex w-full">
                    <div class="flex flex-col w-[140px] mr-[16px]">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Вид
                            </div>
                        </div>
                        <UIInput v-model="order.type" id="form-name" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-[435px]">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Название комапании
                            </div>
                        </div>
                        <UIInput v-model="order.company_name" id="form-name" disabled
                            placeholder="Введите название компании">
                        </UIInput>
                    </div>
                </div>
                <div class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Контактное лицо
                            </div>
                        </div>
                        <UIInput v-model="order.contact_person_first_name" id="form-name" placeholder="Имя" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="h-[34px]"></div>
                        <UIInput v-model="order.contact_person_last_name" id="form-name" placeholder="Фамилия" disabled>
                        </UIInput>
                    </div>
                </div>
                <div class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Телефон
                            </div>
                        </div>
                        <UIInput v-model="order.phone" id="form-name" placeholder="+7" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Электронная почта
                            </div>
                        </div>
                        <UIInput v-model="order.email" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    <div class="flex gap-[8px] items-start">
                        <div class="form-label">Юридический адрес
                        </div>
                    </div>
                    <UIInput v-model="order.legal_address" id="form-name" disabled>
                    </UIInput>
                </div>
                <div class="flex flex-col w-full">
                    <div class="flex gap-[8px] items-start">
                        <div class="form-label">Фактический адрес
                        </div>
                    </div>
                    <UIInput v-model="order.fact_address" id="form-name" placeholder="" disabled>
                    </UIInput>
                </div>
                <div class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">ИНН
                            </div>
                        </div>
                        <UIInput v-model="order.tin" id="form-name" disabled placeholder="10 цифр">
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">ОГРН
                            </div>
                        </div>
                        <UIInput v-model="order.ogrn" id="form-name" disabled placeholder="10 цифр">
                        </UIInput>
                    </div>
                </div>
                <div class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Расчетный счет
                            </div>
                        </div>
                        <UIInput v-model="order.current_account" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Корреспондентский счет
                            </div>

                        </div>
                        <UIInput v-model="order.correspondent_account" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                </div>
                <div v-if="order.type !== 'ИП'" class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">КПП
                            </div>
                        </div>
                        <UIInput v-model="order.kpp" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">БИК
                            </div>
                        </div>
                        <UIInput v-model="order.bik" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                </div>
                <div v-else class="flex flex-row gap-x-[8px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">БИК
                            </div>
                        </div>
                        <UIInput v-model="order.bik" id="form-name" placeholder="" disabled>
                        </UIInput>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="flex gap-[8px] items-start">
                            <div class="form-label">Название банка
                            </div>
                        </div>
                        <UIInput v-model="order.bank_name" id="form-name" disabled>
                        </UIInput>
                    </div>
                </div>
                <div v-if="order.type !== 'ИП'" class="flex flex-col w-[50%]">
                    <div class="flex gap-[8px] items-start">
                        <div class="form-label">Название банка
                        </div>
                    </div>
                    <UIInput v-model="order.bank_name" id="form-name" placeholder="" disabled>
                    </UIInput>
                </div>



            </div>



        </Sheet>
        <div class="flex flex-row justify-end mt-[40px]">
            <div class="w-[237px]">
                <ButtonNew @click="save">Сохранить</ButtonNew>
            </div>
        </div>
        <div class="w-full flex flex-row-reverse mt-[32px] items-center cursor-pointer" @click="router.back()">
            <div class="ml-[11px]">Назад</div>
            <UIIcon name="longLeft"></UIIcon>

        </div>
    </LKLayout>

</template>
<script setup>
import LKLayout from "@/layouts/LKLayout";
import UIInput from "@/components/ui/UIInput.vue";
import { onMounted, reactive, ref, computed, watch, onBeforeMount } from "vue";
import { formatDateTime, daysUntil, formatDateTimeToDate } from "@/composables/utils";
import { useFetch } from "@/composables/useFetch";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import UIPagination from "@/components/ui/UIPagination.vue";
import router from "@/router";
import Sheet from "@/components/cards/Sheet.vue";
import UIIcon from "@/components/ui/UIIcon.vue";
import { useRoute } from "vue-router";
import UIRadioButton from "@/components/ui/UIRadioButton.vue";
import UISelect from "@/components/ui/UISelect.vue";
import UIFileUploader from "@/components/ui/UIFileUploader.vue";
import { useToast } from "vue-toast-notification";

const toast = useToast()

const types = ref([
    { title: 'ООО', value: 'ООО' },
    { title: 'ОАО', value: 'ОАО' },
    { title: 'ЗАО', value: 'ЗАО' },
    { title: 'ПАО', value: 'ПАО' },
    { title: 'АО', value: 'АО' },
    { title: 'УП', value: 'УП' },
    { title: 'ГУ', value: 'ГУ' },
    { title: 'ТОО', value: 'ТОО' },
    { title: 'ИП', value: 'ИП' },
    { title: 'НО', value: 'НО' }
])

const statuses =  ref([
    { title: 'Счет формируется', value: 'PENDING' },
    { title: 'Ожидает оплаты', value: 'WAITING' },
    { title: 'Услуга активирована', value: 'SUCCESS' },
])

const save = () => {
    fetch.authRequest(`/accountant/orders/${orderId.value}/`, {
        method: 'PATCH',
        body: {
            acceptance_certificate: order.value.acceptance_certificate,
            bill: order.value.bill,
            status: order.value.status
        }
    }).then(response => {
        toast.success('Сохранено')
        router.push('/accountant/lk/orders')
    })
}

const route = useRoute()

const fetch = useFetch()

const legalType = ref('legal')

const orderStatusColor = (status) => {
    if (status === 'PENDING')
        return 'w-3 h-3 rounded-full bg-[#F0CE0E]'
    else if (status === 'WAITING')
        return 'w-3 h-3 rounded-full bg-[#01B00A]'
    else if (status === 'SUCCESS')
        return 'w-3 h-3 rounded-full bg-[#0B54BF]'
}

const orderStatusLabel = (status) => {
    if (status === 'PENDING')
        return 'Счет формируется'
    else if (status === 'WAITING')
        return 'Ожидание оплаты'
    else if (status === 'SUCCESS')
        return 'Услуга активирована'
}

const downloadFile = (id) => {
    return `http://localhost:8000/api/files/${id}/download`
}

const orderStatusDescription = (order) => {
    if (order.status === 'PENDING')
        return 'В течение ближайших 3 рабочих дней счет будет доступен для скачивания'
    else if (order.status === 'WAITING')
        return 'Счет сформирован. После оплаты услуга будет активирована в течние дня.'
    else if (order.status === 'SUCCESS') {
        return `Ваша услуга активна до ${formatDateTimeToDate(order.subscription.date_end)}`
    }

}

const fetched = ref(false)
const order = ref({})

const orderId = ref(route.params.id)

const getOrder = () => {
    fetch.authRequest(`accountant/orders/${orderId.value}/`).then((data) => {
        order.value = data
        fetched.value = true
    })
}

onMounted(() => {
    getOrder()
    fetched.value = true
})

</script>

<style scoped></style>