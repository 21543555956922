<template>
    <DefaultLayout>
        <div v-if="fetched" class="w-full flex justify-center mt-[50px] mb-[50px] ">
            <div class="wj-container md:w-[992px] md:min-h-[300px] px-[60px] py-[52px]">
                <div class="flex justify-between mb-[60px]">
                    <div class="title">Модерация вакансий</div>
                </div>
                <Tabs :tabs="tabs" v-model="activeTab">
                    <template #tab1>
                        <div
                            class="pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
                            <div class="mb-[0.62rem]" v-for="(item, index) in vacancies" :key="index">
                                <VacancyCardListitem :vacancyId=item.id :vacancyName=item.name
                                    :priceFrom=item.price_from :experience=item.experience :priceTo=item.price_to
                                    :currencyName=item.currency_display_name :city=item.city :company=item.employer_name
                                    :publicationTime=item.created_at v-model="isChecked"></VacancyCardListitem>
                            </div>
                        </div>

                    </template>
                    <template #tab2>
                        <div
                            class="pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
                            <div class="mb-[0.62rem]" v-for="(item, index) in vacancies" :key="index">
                                <VacancyCardListitem :vacancyId=item.id :vacancyName=item.name
                                    :priceFrom=item.price_from :experience=item.experience :priceTo=item.price_to
                                    :currencyName=item.currency_display_name :city=item.city :company=item.employer_name
                                    :publicationTime=item.created_at v-model="isChecked"></VacancyCardListitem>
                            </div>
                        </div>
                    </template>
                </Tabs>

                <div class="mb-[0.61rem] md:mb-[19px]" v-if="vacancies.length < itemsPerPage"></div>
                <div v-if="totalPages > 1" class="flex flex-row justify-start md:mt-[46px]">
                    <Pagination v-model="page" :total="totalPages"></Pagination>
                </div>
            </div>
        </div>
    </DefaultLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import { onMounted, reactive, ref, computed, watch } from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import ResumeCard from "@/components/cards/ResumeCard.vue";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import Tabs from "@/components/ui/Tabs";
import Pagination from "@/components/ui/Pagination";
import VacancyCardListitem from "@/components/cards/VacancyCardListitem"
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
const fetch = useFetch()

const fetched = ref(false)

const tabs = [
    { name: "tab1", label: "Требует модерации" },
    { name: "tab2", label: "Архив" },
];

const vacancies = ref([]);
const employerProfileId = ref(null)
const loading = ref(false);
const error = ref(null);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)

const activeTab = ref(0);

const offset = computed(() => {
    return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
    return Math.floor((totalCount.value - 1) / itemsPerPage.value) + 1
})




const fetchVacanciesData = async (queryParams = {}) => {

    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку
    queryParams.limit = itemsPerPage.value
    queryParams.offset = offset.value

    try {
        // Создаем строку запроса вручную
        const queryArray = [];
        for (const key in queryParams) {
            const value = queryParams[key];
            // Проверьте, является ли значение массивом
            if (Array.isArray(value)) {
                // Если массив, объедините значения через запятую
                queryArray.push(`${key}=${value.join(',')}`);
            } else {
                queryArray.push(`${key}=${value}`);
            }
        }
        let queryString = queryArray.join('&');
        queryString
        const url = `/vacancies/${queryString ? '?' + queryString : ''}`;



        const response = await fetch.authRequest(url, {
            method: 'GET',
        });

         // Логируем конечный URL

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
            totalCount.value = response.count
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

onMounted(() => {
    fetch.authRequest('/me/').then((data) => {
        employerProfileId.value = data.employer_profile_id
        fetchVacanciesData({ status__in: 'MODERATION ' });
    }).then((data) => {
        fetched.value = true
    })
})

watch(activeTab, (newValue) => {
    if (newValue === 0) {
        page.value = 1
        fetchVacanciesData({
            status__in: 'MODERATION',
        });
    } else if (newValue === 1) {
        page.value = 1
        fetchVacanciesData({
            status__in: 'ARCHIVE',
        });
    }
});

watch(page, (newValue) => {
    if (activeTab.value === 0) {
        fetchVacanciesData({
            status__in: 'MODERATION',
        });
    } else if (activeTab.value === 1) {
        fetchVacanciesData({
            status__in: 'ARCHIVE',
        });
    } 
});

</script>

<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.title {
    /* Создание вакансии */


    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    color: #4A4A4A;


}
</style>