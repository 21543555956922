<template>
    <DefaultLayout >

    <authTemplateCard>

      <UIPageHeader class="md:hidden" title="Вход">
        <template #search> <div></div></template>
      </UIPageHeader>

      <div class="hidden md:block mb-[32px] registration-header ">Смена пароля</div>

        <Input inputClass=" !p-[16px_16px]" class="mb-[12px]" type="email" v-model="email" :errorText="errorText"
            placeholder="Электронная почта"></Input>
        <div class="md:w-[310px] text-[12px] leading-[14.7px] font-light">Укажите адрес электронной почты, на которую придет ссылка для смены пароля</div>
        <ButtonNew type="blue-medium" @click="reset" class="mt-[32px]">Готово</ButtonNew>


        <div class="flex flex-row justify-between items-center md:w-[345px] w-[335px] h-[44px] mt-[32px]">
            <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem]">
                У меня нет аккаунта.
            </div>
            <div @click="router.push('/signup')"
                class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer">
                Зарегистрироваться
            </div>
        </div>
        <SupportCardNew></SupportCardNew>

    </authTemplateCard>
    </DefaultLayout>
<!--    <FooterNew></FooterNew>-->

</template>


<script setup>
import authTemplateCard from '@/components/cards/authTemplateCard.vue';
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import Input from '@/components/ui/Input.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import {useAuthStore} from "@/stores/authStore";
import {auth} from '@/composables/auth';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";
import { useFetch } from '@/composables/useFetch';
import { usePopupsStore } from '@/stores/popupsStore';

const { anonRequest } = useFetch();
const popupsStore = usePopupsStore()

const email = ref('')
const errorText = ref('')
// const description = ref('')

const reset = () => {
    if (!isNotEmpty(email.value)) {
        errorText.value = 'Поле должно быть заполнено'
        // description.value = ''
        return
    }
    if (!isValidEmail(email.value)) {
        errorText.value = 'Невалидный email'
        // description.value = ''
        return
    }
    email.value = email.value.trim()
    fetchEmailCheck().then(result => {
      if (result === false) {
        errorText.value = 'Данный email не зарегистрирован в системе.'
        return
      } 
      fetchResetPassword().then(result => {
        if (result)
            errorText.value = 'Неизвестная ошибка'
        else
            errorText.value = ''
            // description.value = 'Укажите адрес электронной почты, на которую придет ссылка для смены пароля'
            router.push('/login')
            popupsStore.openInfo(
                'Проверьте почту',
                'Для завершения смены пароля пройдите по ссылке, указанной в письме.',
            )
      })
    })
};

const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

const isNotEmpty = (string) => {
    return string.length > 0;
}


const fetchEmailCheck = async () => {
  const url = `/auth/email-check/`;
  const responseBody = {
    "email": email.value,
  }

  const response = await anonRequest(url, {
    method: 'POST',
    body: responseBody
  });
  return response

};

const fetchResetPassword = async () => {
    const response = await anonRequest('auth/reset-password/', {
    method: 'POST',
    body: {
        email: email.value
    }
  });
  return response
}


const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});



const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>