<template>
  <Sheet class="p-[1rem_1.37rem_0.75rem_1.37rem] md:p-[15px_20px_11px_20px] md:w-[315px] cursor-pointer"
         @click="toVacancy">
    <div class="flex justify-between mb-[0.37rem] md:mb-[6px]">
      <span class="font-inter font-semibold text-[1.12rem] md:text-[16px] text-black">{{ vacancyName }}</span>
      <CheckboxStar v-if="authStore.isAuthenticated" v-model:checked="checkedStar"></CheckboxStar>
    </div>
    <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)"
         class="font-inter font-normal md:text-[16px] md:mb-[20px] text-black">От {{ formatPrice(priceFrom) }}
      {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}
    </div>
    <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)"
         class="font-inter font-normal md:text-[16px] md:mb-[20px] text-black">{{ formatPrice(priceFrom) }}
      {{ currencyName }}
    </div>
    <div v-else-if="(priceFrom)" class="font-inter font-normal md:text-[16px] md:mb-[20px] text-black">От
      {{ formatPrice(priceFrom) }} {{ currencyName }}
    </div>
    <div v-else-if="(priceTo)" class="font-inter font-normal md:text-[16px] md:mb-[20px] text-black">От
      {{ formatPrice(priceTo) }} {{ currencyName }}
    </div>
    <div v-else class="mb-[26px] md:mb-[50px]"></div>
    <!-- <div class="font-inter font-normal md:text-[16px] md:mb-[20px] text-black">{{ formatPrice(priceFrom) }}</div> -->
    <div class="flex flex-row justify-between mb-[1.5rem] md:mb-[20px] items-center">
      <span v-if="city" class="font-inter font-medium text-[1.12rem]  md:text-[16px] text-[#00C8EF]">{{ city }}</span>
      <span v-else class="mb-[1.12rem] md:mb-[25px]"></span>
      <span class="font-inter font-normal text-[0.81rem]  md:text-[12px] text-[#777777]">{{
          formatDateTime(publicationTime)
        }}</span>
    </div>
    <div>

      <Button v-if="authStore.me && authStore.me.role === 'employee'" type="send-welcome-1"
              class="md:h-[46px] md:w-[275px]"
              @click.stop="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)">Откликнуться
      </Button>

    </div>
  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import {defineProps, defineEmits, computed, onMounted} from 'vue';
import {formatPrice, formatDateTime} from '@/composables/utils';
import {useAuthStore} from '@/stores/authStore';
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const toVacancy = () => {
  router.push(`/employee/vacancies/${props.vacancyId}`)
};

const props = defineProps({
  vacancyId: {
    type: Number,
    default: null,
  },
  vacancyName: {
    type: String,
    default: 'Текст',
  },
  priceFrom: {
    type: Number,
    default: null,
  },
  priceTo: {
    type: Number,
    default: null,
  },
  currencyName: {
    type: String,
    default: null,
  },
  city: {
    type: String,
    default: 'Текст',
  },
  publicationTime: {
    type: String,
    default: 'Текст',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

// onMounted(() => { 
//   authStore.getMe()
// })

const emit = defineEmits(['update:modelValue']);
const checkedStar = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped></style>