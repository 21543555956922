<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#01B00A"/>
    <path d="M16.4545 7.4545C16.4439 7.46511 16.4339 7.47635 16.4247 7.48816L11.2161 14.1251L8.07581 10.9848C7.63647 10.5455 6.92416 10.5455 6.48482 10.9848C6.04548 11.4242 6.04548 12.1365 6.48482 12.5758L10.4545 16.5455C10.8938 16.9848 11.6061 16.9848 12.0455 16.5455C12.0553 16.5357 12.0645 16.5254 12.0731 16.5146L18.0615 9.02921C18.4848 8.58872 18.4794 7.88846 18.0455 7.4545C17.6061 7.01516 16.8938 7.01516 16.4545 7.4545Z" fill="#01B00A"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>