<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.48483 1.73483C3.63128 1.58839 3.86872 1.58839 4.01517 1.73483L8.51516 6.23484C8.66161 6.38128 8.66161 6.61872 8.51516 6.76516L4.01517 11.2652C3.86872 11.4116 3.63128 11.4116 3.48483 11.2652C3.33839 11.1187 3.33839 10.8813 3.48483 10.7348L7.71967 6.5L3.48483 2.26517C3.33839 2.11872 3.33839 1.88128 3.48483 1.73483Z" fill="#1F1E1E"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>