<template>
  <div ref="iconElement">
    <component :is="iconComponent"></component>
<!--    <HouseDoorIcon ></HouseDoorIcon>-->
  </div>
</template>

<script setup>
import {computed, defineProps, nextTick, onMounted, ref, watch} from 'vue'
import HouseDoorIcon from "@/components/icons/HouseDoorIcon";
import EnvelopeIcon from "@/components/icons/EnvelopeIcon";
import PersonPlusIcon from "@/components/icons/PersonPlusIcon";
import CardHeadingIcon from "@/components/icons/CardHeadingIcon";
import GearIcon from "@/components/icons/GearIcon";
import QuestionCircleIcon from "@/components/icons/QuestionCircleIcon";
import LogoutIcon from "@/components/icons/LogoutIcon";
import PlusIcon from "@/components/icons/PlusIcon";
import GeoAltIcon from "@/components/icons/GeoAltIcon";
import TelephoneIcon from "@/components/icons/TelephoneIcon";
import TelegramIcon from "@/components/icons/TelegramIcon";
import WhatsAppIcon from "@/components/icons/WhatsAppIcon";
import CameraIcon from "@/components/icons/CameraIcon";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
import ExclamationDiamondIcon from "@/components/icons/ExclamationDiamondIcon";
import CalendarFrameIcon from "@/components/icons/CalendarFrameIcon";
import PersonIcon from "@/components/icons/PersonIcon";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon";
import ChevronUpIcon from "@/components/icons/ChevronUpIcon";
import AsteriskIcon from "@/components/icons/AsteriskIcon";
import CheckedIcon from "@/components/icons/CheckedIcon";
import XIcon from "@/components/icons/XIcon";
import CrossedEye from "@/components/icons/CrossedEye";
import Circle from '../icons/Circle.vue';
import Pencil from '../icons/Pencil.vue';
import Eye from '../icons/Eye.vue';
import MoneyIcon from '../icons/MoneyIcon.vue';
import Check from '../icons/Check.vue';
import DotsIcon from '../icons/DotsIcon.vue';
import Pdf from '../icons/Pdf.vue';
import LongArrowLeft from '../icons/LongArrowLeft.vue';
import ArrowLeftIcon from '../icons/ArrowLeftIcon.vue';
import ChevronLeftIcon from '../icons/ChevronLeftIcon.vue';
import ChevronRightIcon from '../icons/ChevronRightIcon.vue';
import FiRsListIcon from '../icons/FiRsListIcon.vue';
import ChevronDoubleLeftIcon from '../icons/ChevronDoubleLeftIcon.vue';
import Trash from '../icons/Trash.vue';
import Repeat from '../icons/Repeat.vue';
import RoundedX from '../icons/RoundedX.vue';


const iconNames = {
  homeDoor: 'homeDoor',
  envelope: 'envelope',
  personPlus: 'personPlus',
  cardHeading: 'cardHeading',
  gear: 'gear',
  questionCircle: 'questionCircle',
  logout: 'logout',
  plus: 'plus',
  geoAlt: 'geoAlt',
  telephone: 'telephone',
  telegram: 'telegram',
  whatsApp: 'whatsApp',
  camera: 'camera',
  checkCircle: 'checkCircle',
  exclamationDiamond: 'exclamationDiamond',
  calendarFrame: 'calendarFrame',
  person: 'person',
  chevronDown: 'chevronDown',
  chevronUp: 'chevronUp',
  asterisk: 'asterisk',
  checked: 'checked',
  x: 'x',
  crossedEye: 'crossedEye',
  circle: 'circle',
  pencil: 'pencil',
  eye: 'eye',
  money: 'money',
  check: 'check',
  pdf: 'pdf',
  longLeft: 'longLeft',
  dots: 'dots',
  arrowLeft: 'arrowLeft',
  chevronLeft: 'chevronLeft',
  chevronRight: 'chevronRight',
  firsList: 'firsList',
  chevronDoubleLeft: 'chevronDoubleLeft',
  trash: 'trash',
  repeat: 'repeat',
  roundedX: 'roundedX',
}

const iconNamesPlain = Object.values(iconNames)

const iconMap = {}
iconMap[iconNames.homeDoor] = HouseDoorIcon
iconMap[iconNames.envelope] = EnvelopeIcon
iconMap[iconNames.personPlus] = PersonPlusIcon
iconMap[iconNames.cardHeading] = CardHeadingIcon
iconMap[iconNames.gear] = GearIcon
iconMap[iconNames.questionCircle] = QuestionCircleIcon
iconMap[iconNames.logout] = LogoutIcon
iconMap[iconNames.plus] = PlusIcon
iconMap[iconNames.geoAlt] = GeoAltIcon
iconMap[iconNames.telephone] = TelephoneIcon
iconMap[iconNames.telegram] = TelegramIcon
iconMap[iconNames.whatsApp] = WhatsAppIcon
iconMap[iconNames.camera] = CameraIcon
iconMap[iconNames.checkCircle] = CheckCircleIcon
iconMap[iconNames.exclamationDiamond] = ExclamationDiamondIcon
iconMap[iconNames.calendarFrame] = CalendarFrameIcon
iconMap[iconNames.person] = PersonIcon
iconMap[iconNames.chevronDown] = ChevronDownIcon
iconMap[iconNames.chevronUp] = ChevronUpIcon
iconMap[iconNames.asterisk] = AsteriskIcon
iconMap[iconNames.checked] = CheckedIcon
iconMap[iconNames.x] = XIcon
iconMap[iconNames.crossedEye] = CrossedEye
iconMap[iconNames.circle] = Circle
iconMap[iconNames.pencil] = Pencil
iconMap[iconNames.eye] = Eye
iconMap[iconNames.money] = MoneyIcon
iconMap[iconNames.check] = Check
iconMap[iconNames.pdf] = Pdf
iconMap[iconNames.longLeft] = LongArrowLeft
iconMap[iconNames.dots] = DotsIcon
iconMap[iconNames.arrowLeft] = ArrowLeftIcon
iconMap[iconNames.chevronLeft] = ChevronLeftIcon
iconMap[iconNames.chevronRight] = ChevronRightIcon
iconMap[iconNames.firsList] = FiRsListIcon
iconMap[iconNames.chevronDoubleLeft] = ChevronDoubleLeftIcon
iconMap[iconNames.trash] = Trash
iconMap[iconNames.repeat] = Repeat
iconMap[iconNames.roundedX] = RoundedX

const iconComponent = computed(() => {
  if (props.name in iconMap) {
    return iconMap[props.name]
  }
  return null
})

const iconElement = ref(null)


onMounted(() => {
  updateSVG()
})

const updateSVG = () => {
  if (!iconElement.value) {
    return
  }
  const svgElement = iconElement.value.children[0]
  if (!svgElement) {
    return
  }
  svgElement.setAttribute('width', props.size)
  svgElement.setAttribute('height', props.size)

  if (props.color !== undefined) { // Если undefined то цвет используется оригинальный с иконки. Полезно для whatsapp, telegram
    const color = props.color ? props.color : iconElement.value.style.color
    for (const subSvg of svgElement.children) {
      subSvg.setAttribute('fill', color)
    }
  }
  if (props.stroke !== undefined) { // Если undefined то цвет используется оригинальный с иконки. Полезно для whatsapp, telegram
    const color = props.stroke ? props.stroke : iconElement.value.style.color
    for (const subSvg of svgElement.children) {
      subSvg.setAttribute('stroke', color)
    }
  }



  // svgElement.height = props.size
}





const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'homeDoor'
  },
  size: {
    type: String,
    required: true,
    default: '16',
  },
  color: {
    type: String,
    default: undefined
  },
  stroke: {
    type: String,
    default: undefined
  }
})

watch(props, () => {
  updateSVG()
})

const iconStyle = {
  width: props.size,
  height: props.size,
  fill: props.color
}
</script>