<template>
  <EducationLayout class="md:pb-[100px]" :loading="loading">
    <UIEventCalendar
      class="!w-[652px] shadow-[4px_4px_1.5rem_0.125rem_rgba(10,92,213,0.1)] rounded-[1.5rem]"
      :events="calendarEvents"
      :current="current"
      @update:current="current = $event"
    ></UIEventCalendar>

  </EducationLayout>
</template>

<script setup>

import EducationLayout from "@/layouts/EducationLayout";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import {onBeforeMount, onMounted, ref, watch} from "vue";
import {useArticlesStore} from "@/stores/articlesStore";
import UIIcon from "@/components/ui/UIIcon";
import {useFetch} from "@/composables/useFetch";
import {formatDateTime} from "@/composables/utils";
import UIEventCalendar from "@/components/ui/UIEventCalendar";
import {useEventsStore} from "@/stores/eventsStore";

const fetch = useFetch()

// const articlesStore = useArticlesStore()
//
// onBeforeMount(() => {
//   articlesStore.getPublicArticles()
// })

const eventsStore = useEventsStore()

const current = ref(new Date())

const loading = ref(false)

const getEvents = () => {
  loading.value = true
  eventsStore.getPublicEvents(current.value.getFullYear(), current.value.getMonth()+1).then(() => {
    calendarEvents.value = eventsStore.items
    loading.value = false

  })
}

watch(() => current.value, (v, ov) => {
  if (v && ov && (v.toISOString() == ov.toISOString())) {
    return
  }

  if (v) {
    getEvents()
  }
})

onBeforeMount(() => {
  getEvents()
})
const calendarEvents = ref([
  {
    id: 1,
    date: '2024-12-10T00:00:00Z',
    name: 'Вебинар по Vue.js',
  },
  {
    id: 2,
    date: '2024-12-10T12:00:00Z',
    name: 'Встреча с командой',
  },
  {
    id: 3,
    date: '2024-12-25T00:00:00Z',
    name: 'Рождество',
  },
])
</script>

<style scoped>

</style>