<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.51516 1.73483C8.66161 1.88128 8.66161 2.11872 8.51516 2.26517L4.28033 6.5L8.51516 10.7348C8.66161 10.8813 8.66161 11.1187 8.51516 11.2652C8.36872 11.4116 8.13128 11.4116 7.98484 11.2652L3.48483 6.76516C3.33839 6.61872 3.33839 6.38128 3.48483 6.23484L7.98484 1.73483C8.13128 1.58839 8.36872 1.58839 8.51516 1.73483Z" fill="#1F1E1E"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>