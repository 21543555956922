<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6262 1.95451C11.7726 2.10095 11.7726 2.33839 11.6262 2.48484L10.844 3.267L9.34403 1.767L10.1262 0.984835C10.2726 0.838388 10.5101 0.838388 10.6565 0.984835L11.6262 1.95451Z"
            fill="#0A5CD5" />
        <path
            d="M10.3137 3.79733L8.8137 2.29733L3.70396 7.40707C3.66279 7.44824 3.63178 7.49842 3.61337 7.55365L3.00995 9.36391C2.96109 9.51049 3.10054 9.64994 3.24712 9.60108L5.05738 8.99766C5.11261 8.97925 5.16279 8.94824 5.20396 8.90707L10.3137 3.79733Z"
            fill="#0A5CD5" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.75 10.625C0.75 11.2463 1.25368 11.75 1.875 11.75H10.125C10.7463 11.75 11.25 11.2463 11.25 10.625V6.125C11.25 5.91789 11.0821 5.75 10.875 5.75C10.6679 5.75 10.5 5.91789 10.5 6.125V10.625C10.5 10.8321 10.3321 11 10.125 11H1.875C1.66789 11 1.5 10.8321 1.5 10.625V2.375C1.5 2.16789 1.66789 2 1.875 2H6.75C6.95711 2 7.125 1.83211 7.125 1.625C7.125 1.41789 6.95711 1.25 6.75 1.25H1.875C1.25368 1.25 0.75 1.75368 0.75 2.375V10.625Z"
            fill="#0A5CD5" />
    </svg>



</template>

<script setup>

</script>

<style scoped></style>
