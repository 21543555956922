<template>
  <popup @close="emit('update:modelValue', false)">
    <div>

      <div
        class="mt-[32px] md:mt-0 mb-[12px] font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] text-center md:text-left md:leading-[1.8125rem] text-black">
        Сопроводительное письмо выделит вас среди работодателей
      </div>
      <UITextArea v-model="popupsStore.invitation.message"
                  placeholder="Напишите соискателю, почему вы хотите пригласить его"
                  textAreaClass="font-light text-xs leading-4 text-gray-500 "
      ></UITextArea>
      <div class="flex justify-end">
        <div class="flex flex-col md:flex-row gap-[16px] mt-[40px] w-full">
          <ButtonNew type="stroke" class="w-fit whitespace-nowrap p-[14px_16px]" @click="send()">Пригласить без письма
          </ButtonNew>
          <ButtonNew class="w-fit p-[14px_16px]" @click="send()">Пригласить</ButtonNew>
        </div>


      </div>
    </div>
  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import {defineProps, defineEmits} from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

const popupsStore = usePopupsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const send = () => {
  if (popupsStore.invitation.callback) {
    popupsStore.invitation.callback(popupsStore.invitation.resumeId, popupsStore.invitation.message)
    popupsStore.invitation.visible = false
  }
}
</script>

<style scoped>

</style>