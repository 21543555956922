<template>
    <div class="contact-field">
        <div class="flex flex-row gap-x-[16px] h-[20px] items-center justify-start w-full">
            <div>
                <slot name="icon"></slot>
            </div>
            <div ref="inputContainer" class="flex-grow">
                <slot name="input"></slot>
            </div>
        </div>
        <div class="flex flex-row h-[15px] items-center justify-start cursor-pointer w-full">
            <div v-if="isVisible" class="inline-flex gap-[8px] w-[33%]" @click="toggleVisible">
                <UIIcon size="12" name="eye" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Виден</div>
            </div>
            <div v-else class="inline-flex gap-[8px] w-[33%]" @click="toggleVisible">
                <UIIcon size="12" name="crossedEye" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Сейчас скрыт</div>
            </div>
            <div v-if="isMain" class="inline-flex gap-[8px] w-[40%]" @click="toggleMain">
                <UIIcon size="12" name="circle" color="#01B00A"></UIIcon>
                <div class="contact-action-item-text">Основной способ связи</div>
            </div>
            <div v-else class="inline-flex gap-[8px] w-[40%]" @click="toggleMain">
                <div class="contact-action-item-text">Сделать основным способом связи</div>
            </div>
            <div class="inline-flex gap-[8px] justify-end w-[27%]" @click="focusInput">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import UIIcon from "@/components/ui/UIIcon";

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false,
    },
    isMain: {
        type: Boolean,
        default: false,
    },
});

// Определяем события, которые будут использоваться для эмита
const emit = defineEmits(['update:isVisible', 'update:isMain']);

const inputContainer = ref(null);
const isVisible = ref(props.isVisible);
const isMain = ref(props.isMain);

// Следим за изменениями в пропсах и обновляем реактивные переменные
watch(() => props.isVisible, (newValue) => {
    isVisible.value = newValue;
});

watch(() => props.isMain, (newValue) => {
    isMain.value = newValue;
});

const focusInput = () => {
    const inputElement = inputContainer.value?.querySelector('input, textarea'); // Находим инпут или текстовое поле
    if (inputElement) {
        inputElement.focus(); // Устанавливаем фокус
    }
};

// Изменение видимости и эмит события
const toggleVisible = () => {
    isVisible.value = !isVisible.value;
    emit('update:isVisible', isVisible.value); // Пробрасываем новое значение
}

// Изменение основного статуса и эмит события
const toggleMain = () => {
    isMain.value = !isMain.value;
    emit('update:isMain', isMain.value); // Пробрасываем новое значение
}
</script>

<style scoped>
.contact-field {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 12px 12px;

    background: transparent;
    border: 1px solid #7B7A7A;
    border-radius: 15px;

    height: 67px;
}

.contact-action-item-text {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #0A5CD5;
    flex: none;
    order: 1;
    flex-grow: 0;
}
</style>