<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 11.6299L11.1923 16.5L19.5 9" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>







</template>

<script setup>

</script>

<style scoped>

</style>