<template>
  <ActionSheet @close="emit('update:modelValue', false)">
    <div>
      <div class="font-bold text-[1.25rem] leading-[1.5rem] text-black">Сортировка</div>

      <div>
        <div class="wj-item-sort">
          Сначала новые
          <UIRadioButton v-model="actionSheets.ordering.value" value="newest">
          </UIRadioButton>
        </div>
        <div class="wj-item-sort">
          Сначала старые
          <UIRadioButton v-model="actionSheets.ordering.value" value="oldest">
          </UIRadioButton>
        </div>
        <div class="wj-item-sort">
          По убыванию зарплат
          <UIRadioButton v-model="actionSheets.ordering.value" value="salaryDec">
          </UIRadioButton>
        </div>
        <div class="wj-item-sort">
          По возрастанию зарплат
          <UIRadioButton v-model="actionSheets.ordering.value" value="salaryInc">
          </UIRadioButton>
        </div>
        <!-- <div  v-for="(item, index) in options" :key="index"
              class="p-2 hover:bg-gray-200 cursor-pointer md:text-[16px] text-[0.75rem] flex justify-between items-center">
              <div>
                {{ item.title }}

              </div>
              
            </div> -->
        <!-- <UIRadioButtonGroup :options="[{label: 'Нет опыта', value: '123'},{label: 'От 3 мес до 1 года', value: '456'},{label: 'От 1 до 3 лет', value: '789'},]"></UIRadioButtonGroup> -->
      </div>

      <div class="mt-[40px] flex justify-end">
        <div>
          <ButtonNew type="stroke" class="w-fit p-[14px_16px]" @click="actionSheets.ordering.confirmCallback">Готово
          </ButtonNew>

        </div>
      </div>
    </div>
  </ActionSheet>
</template>

<script setup>

import ActionSheet from "@/components/ui/ActionSheet";
import { defineEmits, defineProps, ref } from "vue";
import ButtonNew from "@/components/ui/ButtonNew";
import { useActionSheetsStore } from "@/stores/actionSheets";
import Select from "../ui/Select.vue";
import UIRadioButton from "../ui/UIRadioButton.vue";
import UIRadioButtonGroup from "../ui/UIRadioButtonGroup.vue";
const actionSheets = useActionSheetsStore()

const ordering = ref('oldest')

const options = ref([
  {
    title: 'Сначала новые',
    value: 'newest',
  },
  {
    title: 'Сначала старые',
    value: 'oldest',
  },
  {
    title: 'По убыванию зарплат',
    value: 'salaryDec',
  },
  {
    title: 'По возрастанию зарплат',
    value: 'salaryInc',
  },

])

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])
</script>

<style scoped>
.wj-item-sort {
  @apply h-[48px] cursor-pointer text-[14px] flex justify-between items-center
}
</style>