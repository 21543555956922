<template>
    <div class="flex flex-col p-5 md:items-center md:w-[317px] w-[343px] bg-white rounded-[1rem]">
        <div class="flex flex-col items-center">
            <div class="mb-[20px] md:mr-0 mr-[3px] flex-shrink-0">
                <slot name="picture"></slot>
            </div>
            <div class="mb-2 md:h-[58px] text-left font-medium md:text-[24px] md:leading-[29px] text-[18px] text-[1.12rem] w-full">
                {{ props.name }}
            </div>
        </div>

        <slot name="text">
            <div class="flex flex-col w-full">

            </div>
        </slot>

    </div>
</template>
<script setup>
import router from '@/router';
import { defineProps } from 'vue';
const props = defineProps({
    name: {
        type: String,
        default: 'Текст',
    },
    text: {
        type: String,
        default: 'Текст',
    },
});
</script>

<style scopen></style>