

<template>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3919 0.529579C12.7598 0.156807 13.3562 0.156807 13.7241 0.529579C14.0875 0.897781 14.0919 1.49195 13.7375 1.86569L6.21122 10.7624C6.20398 10.7715 6.19625 10.7803 6.18806 10.7886C5.82018 11.1614 5.22374 11.1614 4.85587 10.7886L0.275907 6.14767C-0.0919689 5.7749 -0.0919689 5.17051 0.275907 4.79774C0.643782 4.42496 1.24023 4.42496 1.6081 4.79774L5.49358 8.73494L12.3669 0.558133C12.3747 0.548117 12.383 0.538582 12.3919 0.529579Z" fill="#01B00A"/>
    </svg>
  
  
  
  </template>
  
  <script setup>
  
  </script>
  
  <style scoped>
  
  </style>