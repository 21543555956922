<!-- UISelect.vue -->
<template>
  <UIDropdown ref="dropdown" @update:modelValue="onUpdate" :autoTriggerToogle="false">
    <template #trigger="data">
      <div @click.prevent="toggle" :class="computedTriggerClass">
        <span  class="text-[14px] cursor-default select-none">{{ selectedLabel }}</span>
        <!-- <span>{{ data }}</span> -->
        <Icon @click.stop="toggle" v-if="data.isOpen" name="chevron-up-black"></Icon>
        <Icon @click.stop="toggle" v-else name="chevron-down-black"></Icon>
      </div>
    </template>

    <template #dropdown>
      <slot name="dropdown">
        <ul class="bg-white border rounded shadow-lg w-full select-none">
          <li v-for="(option, index) in options" :key="index"
            class="px-4 py-2 hover:bg-[#0A5CD51A] text-[14px] cursor-pointer w-full select-none" @click="selectOption(option)">
            {{ option.title }}
          </li>
        </ul>
      </slot>

    </template>
  </UIDropdown>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import UIDropdown from './UIDropdown.vue'
import Icon from './Icon.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
  triggerClass: {
    default: ''
  },
  // dropdownClass: {
  //   default: ''
  // },
  placeholder: {
    default: 'Выберите...'
  }
})

const standardTriggerClass = 'h-[40px] px-4 bg-transparent border-[1px] border-[#0A5CD5] rounded-[12px] flex justify-between items-center'

const standartDropdownClass = ''

const computedTriggerClass = computed(() => {
  return standardTriggerClass + ' ' + props.triggerClass
});

const emit = defineEmits(['update:modelValue'])

const dropdown = ref(null)

const selectedOption = ref(props.options.find(option => option.value === props.modelValue) || null)

const selectedLabel = computed(() => selectedOption.value ? selectedOption.value.title : props.placeholder)

const selectOption = (option) => {
  selectedOption.value = option
  emit('update:modelValue', option.value)
  dropdown.value.hide()
}

defineExpose({
  hideDropdown: () => {
    dropdown.value.hide();
  }
});

const toggle = () => {

  dropdown.value.toggle()
}

const onUpdate = (value) => {
  // Обработка внешних изменений modelValue, если необходимо
}

watch(() => props.modelValue, (newVal) => {
  selectedOption.value = props.options.find(option => option.value === newVal) || null
})
</script>

<style scoped>
/* Добавьте стили по необходимости */
</style>