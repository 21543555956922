<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6.5" r="6" fill="#0A5CD5" />
    </svg>




</template>

<script setup>

</script>

<style scoped></style>