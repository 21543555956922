<template>
  <DefaultLayout>

    <div class="flex flex-col bg-[#F8F8F8]">
      <!--    <div class="mb-[3.75rem] md:mb-[80px]">-->
      <!--      <Header @toggleScroll="handleToggleScroll"></Header>-->
      <!--    </div>-->
      <CompanySignUpCardFull v-if="!$authStore.isAuthenticated"></CompanySignUpCardFull>

      <div class="px-5 md:px-0 py-15 flex flex-col items-center">
        <div class=" font-bold text-[24px] lg:w-[992px] text-[#4A4A4A] ">
          Тарифная сетка
        </div>
        <div class=" font-bold text-[18px] lg:w-[992px] text-[#8A8A8A] mb-10">
          Стоимость указана за календарный месяц
        </div>
        <div class="flex flex-col md:flex-row  gap-[10px] w-full md:w-[992px]  ">
          <TarifCard v-for="plan in billingStore.plans.items" :key="plan.id" :name="plan.name"
                     :active="$authStore.me ? ($authStore.me.user_subscription && $authStore.me.user_subscription.tariff === plan.id) : false"
                     :id="plan.id" :byeButton="true"
                     :dateEnd="($authStore.me && $authStore.me.user_subscription) ? $authStore.me.user_subscription.date_end : null"
                     :price="plan.price_per_month" :vacanciesForPublication="plan.vacancies_for_publication"
                     :canSeeContacts="plan.can_see_contacts" :canSendResponses="plan.can_send_responses"
          ></TarifCard>
        </div>
      </div>

      <div class="flex flex-row justify-center">
        <WelcomeGetCandidateCard></WelcomeGetCandidateCard>
      </div>

      <!--    <Footer></Footer>-->
    </div>
  </DefaultLayout>

</template>

<script setup>
import Header from './../components/ui/Header.vue'
import {ref, reactive, watch, onMounted, onUnmounted, onBeforeMount} from 'vue';

import Footer from './../components/ui/Footer.vue'
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import WelcomeCard from './../components/cards/WelcomeCard.vue'
import CompanySignUpCardFull from './../components/cards/CompanySignUpCardFull.vue';
import WelcomeGetCandidateCard from './../components/cards/WelcomeGetCandidateCard.vue'
import TarifCard from "@/components/cards/TarifCard";
import {useBillingStore} from "@/stores/billingStore";
import DefaultLayout from "@/layouts/DefaultLayout";

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};
const billingStore = useBillingStore()

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

// const activeTarif = () => computed({
//   if ($authStore.state.me) {

//   }
// })


const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onBeforeMount(() => {
  billingStore.getTariffs()
})

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);

});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


const activeTab = ref(0);

</script>

<style>
</style>