<template>
  <!--    <div class="mb-[3.75rem] !bg-[#F8F8F8] md:mb-[80px]">-->
  <!--        <Header @toggleScroll="handleToggleScroll"></Header>-->
  <!--    </div>-->
  <DefaultLayout>


    <div class="!bg-[#F8F8F8] pb-[1.81rem] flex flex-col items-center ">
      <UIContainer>
        <UIPageHeader class="w-full" title="Вакансии" search-placeholder="Профессия, должность или компания"
          @search="handleSearch">


        </UIPageHeader>
        <div class="mt-[20px]  ">
          <router-link v-if="!$authStore.isAuthenticated & !isMobile" to="/signup"
            class="text-[0.87rem] md:text-[16px] font-light">
            <span class="md:h-[20px] underline cursor-pointer">Зарегистрируйтесь</span>
            чтобы разблокировать больше возможностей
          </router-link>
        </div>


        <div class="mt-[32px] ">


          <Sheet v-if="vacancy" class="w-full p-[16px] md:p-[56px]">
            <div class="flex flex-col md:flex-row  justify-between">
              <div>
                <div class="flex flex-col md:gap-[9px]">
                  <div
                    class="font-bold text-[1.25rem] md:text-[2.25rem] leading-[1.5rem] md:leading-[2.75rem] text-blue-700 md:text-[#0A5CD5]">
                    {{
                      vacancy.name
                    }}
                  </div>

                  <div
                    class="font-normal text-[0.875rem] md:text-[1.25rem] leading-[1.0625rem] md:leading-[1.5rem] text-black   md:text-[#1F1E1E]">
                    {{ vacancy?.employer?.company_name }}
                  </div>

                  <div
                    class="font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] md:leading-[1.8125rem] text-[#1F1E1E] flex justify-between">
                    <div>{{ vacancy?.city }}</div>
                    <div class="md:hidden">от {{ formatPrice(vacancy.price_from) }}</div>

                  </div>
                </div>


                <div class="flex flex-col gap-[12px] mt-[41px] md:min-w-[426px] md:max-w-[600px]">
                  <div class=" flex justify-between">
                    <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">Требуемый опыт</div>
                    <div class="text-[1rem] leading-[1.25rem] text-[#01B00A] ">
                      {{ formatexperienceForVacancy(vacancy.experience) }}
                    </div>
                  </div>

                  <div class="flex justify-between">
                    <div class="font-normal text-base leading-[1.25] text-[#1F1E1E] ">Тип занятости</div>
                    <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                      {{ humanizeEmploymentType(vacancy.employment_type) }}
                    </div>
                  </div>

                  <div class="flex justify-between">
                    <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">График работы</div>
                    <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                      {{ humanizeEmploymentShedule(vacancy.employment_schedule) }}
                    </div>
                  </div>

                  <div class=" flex justify-between">
                    <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">Образование</div>
                    <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                      {{ humanizeEducationLevel(vacancy.education) }}
                    </div>
                  </div>


                </div>

              </div>

              <div class="flex flex-col">
                <div class="hidden md:block text-black font-bold text-[1.875rem] leading-[2.3125rem]">от
                  {{ formatPrice(vacancy.price_from) }}
                </div>
                <div v-if="vacancy?.employer?.avatar" class="mt-[52px]">
                  <img class="max-w-[214px]" :src="`${baseURL}/files/${vacancy?.employer?.avatar}/download`">
                </div>
                <!-- <div v-else class="w-[214px] h-[54px] bg-[#FFFAFA]">
                </div> -->
                <div v-else
                  class="w-full mt-10 h-[91px] flex flex-row justify-center items-center border-[1px] border-[#B7B7B7] rounded-[15px]">
                  <UIIcon size="50" name="camera" color="#B7B7B7"></UIIcon>
                </div>


                <!-- <div class="flex items-center mt-[13px] gap-[12px]">
                  <div class="font-bold text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">4.5</div>
                  <Grade model-value="4.5"></Grade>
                </div> -->

                <div v-if="$authStore.isAuthenticated" class=" flex-col mt-[21px] md:mt-[56px]">
                  <div class="flex items-center gap-[12px] px-[10px]">
                    <img src="/icons/envelope-12-blue.svg" class="w-[24px] h-[24px]">

                    <div v-if="vacancy?.employer?.email"
                      class="flex items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E]">
                      {{ vacancy?.employer?.email }}
                    </div>
                  </div>
                  <div class="flex items-center gap-[12px] px-[10px]">
                    <img src="/icons/telephone-12-blue.svg" class="w-[24px] h-[24px]">
                    <div v-if="vacancy?.employer?.phone"
                      class="flex flex-grow items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E] justify-between">
                      {{ vacancy?.employer?.phone }}
                      <div
                        class="md:hidden  text-blue-600 font-medium text-[0.875rem] leading-[1.0625rem] underline cursor-pointer"
                        @click="$router.push(`/employee/vacancies?profession=${vacancy?.employer?.company_name}`)">Все
                        вакансии
                      </div>
                    </div>
                  </div>

                  <div v-if="$authStore.isAuthenticated"
                    class=" mt-[40px] md:mt-[50px] flex flex-col gap-[21px] items-center">
                    <ButtonNew @click.stop="popupsStore.openResponsePopup(vacancy.id, $router)">Откликнуться
                    </ButtonNew>
                    <div
                      class="hidden md:block font-medium text-[1rem] leading-[1.25rem] underline text-[#0A5CD5] cursor-pointer"
                      @click="$router.push(`/employee/vacancies?profession=${vacancy?.employer?.company_name}`)">Все
                      вакансии компании
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="mt-[40px] md:mt-[16px]">

              <div
                class="font-medium text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] text-[#1F1E1E] md:text-[#1F1E1E]">
                Описание вакансии</div>

              <div
                class="mt-[32px] text-black font-normal text-sm md:text-base leading-4 md:leading-5 whitespace-pre-line">
                {{ vacancy.full_description }}
              </div>
            </div>


            <div class="mt-[32px] flex flex-col gap-[21px] items-center  md:items-end">
              <ButtonNew @click.stop="popupsStore.openResponsePopup(vacancy.id, $router)"
                class="w-full md:w-fit md:p-[14px_69px]">Откликнуться
              </ButtonNew>
            </div>
          </Sheet>

          <div class="hidden md:block mt-[72px] text-[2.25rem] leading-[2.75rem] text-[#0A5CD5]  cursor-pointer">
            Возможно, вас заинтересуют
          </div>


          <!-- <CardsSlider class="hidden md:block ">
            <div v-for="(item, index) in vacancy.similar" :key="index">
              <router-link :to="{ path: `/employer/resumes/${item.id}` }">
                <VacancyCardNew class="min-w-[486px]" :vacancyId=item.id :vacancyName=item.name
                  :priceFrom=item.price_from :priceTo=item.price_to :currencyName=item.currency_display_name
                  :city=item.city :publicationTime=item.created_at :experience=item.experience>
                </VacancyCardNew>


              </router-link>

            </div>

          </CardsSlider> -->

          <div class="md:hidden mt-[40px] font-medium text-center underline text-blue-600"
            @click="$router.push('/employee/vacancies')">
            Вернуться к списку вакансий
          </div>


          <!--          <VacancyCardPartOpen :vacancyName=vacancy.name :priceFrom=vacancy.price_from :priceTo=vacancy.price_to-->
          <!--                               :experience=vacancy.experience :currencyName=vacancy.currency_display_name-->
          <!--                               v-model="isChecked3"-->
          <!--                               :employmentType=vacancy.employment_type :employmentShedule=vacancy.employment_schedule>-->
          <!--          </VacancyCardPartOpen>-->
          <!--        </div>-->
          <!--        <div class="mb-[3.81rem] mr-[1.5rem] ml-[1.5rem] md:ml-0 md:mr-0 md:mb-[41px]">-->
          <!--          <CompanyCard :companyName=employer.company_name-->
          <!--                       :grade=employer.grade :reportsCount=employer.reports_count :imageSrcId=employer.avatar>-->
          <!--          </CompanyCard>-->
          <!--        </div>-->
          <!--        <div style="white-space: normal; overflow-wrap: anywhere;"-->
          <!--             class="md:w-[993px] h-full ml-[1.5rem] mr-[1.5rem] md:ml-0 md:mr-0 mb-[3.43rem] md:mb-[51px] text-[1rem] md:text-[18px] text-[#000000]  font-normal">-->
          <!--          {{ vacancy.full_description }}-->
          <!--        </div>-->
          <!--        <div class="flex flex-col md:flex-row ml-[1.5rem] mr-[1.5rem] md:mr-0 md:ml-0 md:mb-0">-->
          <!--          <Button v-if="authStore.me && authStore.me.role === 'employee'" type="send-welcome-1"-->
          <!--                  class="mb-[0.43rem] md:mr-[24px] md:!h-[46px] md:w-[300px]"-->
          <!--                  @click="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)">Откликнуться-->
          <!--          </Button>-->
          <!--          <div></div>-->
          <!--          &lt;!&ndash;                <Button type="get-contacts" class="md:mr-0 md:ml-0 md:mb-0 md:!h-[46px]">Показать контакты</Button>&ndash;&gt;-->
        </div>

      </UIContainer>
      <div class="w-full mb-[430px] md:mb-[335px] relative">

        <div class="absolute w-full">
          <CardsSlider class="pl-[10px] md:pl-0">
            <div v-for="(item, index) in vacancy.similar" :class="[
              index === 0 ? 'md:ml-[calc((100vw-1032px)/2.0)]' : '',
              index === vacancy.similar.length - 1 ? 'md:mr-[calc((100vw-1032px)/2.0)]' : '',
            ]">
              <router-link :to="{ path: `/employee/vacancies/${item.id}` }">
                <VacancyCardNew class="min-w-[486px]" :vacancyId=item.id :vacancyName=item.name
                  :priceFrom=item.price_from :priceTo=item.price_to :currencyName=item.currency_display_name
                  :city=item.city :publicationTime=item.created_at :experience=item.experience>
                </VacancyCardNew>


              </router-link>
            </div>
          </CardsSlider>
        </div>
      </div>


    </div>
  </DefaultLayout>
  <!--    <Footer></Footer>-->
</template>

<script setup>
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import VacancyCardPartOpen from './../components/cards/VacancyCardPartOpen.vue'
import CompanyCard from './../components/cards/CompanyCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onMounted, onUnmounted } from 'vue';
import Footer from './../components/ui/Footer.vue'
import Icon from './../components/ui/Icon.vue';
import Button from './../components/ui/Button.vue';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIContainer from "@/components/ui/UIContainer";
import UIPageHeader from "@/components/ui/UIPageHeader";
import Sheet from "@/components/cards/Sheet";
import {
  formatexperienceForVacancy, formatPrice,
  humanizeEducationLevel,
  humanizeEmploymentShedule,
  humanizeEmploymentType
} from "@/composables/utils";
import Grade from "@/components/ui/Grade";
import ButtonNew from "@/components/ui/ButtonNew";
import CardsSlider from "@/components/ui/CardsSlider";
import VacancyCardNew from "@/components/cards/VacancyCardNew";
import { usePopupsStore } from "@/stores/popupsStore";
import UIIcon from '@/components/ui/UIIcon.vue';

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const vacancyId = ref(route.params.id)

const { anonRequest, authRequest, baseURL } = useFetch();

const popupsStore = usePopupsStore()
const vacancy = ref({});
const employer = ref({});
const loading = ref(false);
const error = ref(null);

const searchTerm = ref(null);

const handleSearch = (query) => {
  if (query) {
    router.push(`/employee/vacancies?profession=${query}`)
  } else router.push(`/employee/vacancies`)

}

onMounted(() => {
  fetchVacancyData();
});

const fetchVacancyData = async () => {

  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку

  try {

    let response
    if (authStore.isAuthenticated) {
      response = await authRequest(`/vacancies/${vacancyId.value}/`, {
        method: 'GET',
      });
    } else {
      response = await anonRequest(`/vacancies/${vacancyId.value}/`, {
        method: 'GET',
      });
    }

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      vacancy.value = response; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

watch(vacancy, (newValue) => {
  if (newValue.employer) {
    employer.value = newValue.employer
  }
});

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const isChecked3 = ref(false)
const gradeCompany = ref(3.9)

const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {

  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Все вакансии" },
];

const activeTab = ref(0);

</script>

<style></style>