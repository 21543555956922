import { defineStore } from 'pinia';
import { useFetch } from '@/composables/useFetch';

import { jwtDecode } from 'jwt-decode';

export const useFormsStore = defineStore('forms', {
  state: () => ({
    employerProfileDetails: { // Форма для реквизитов в ЛК работодателя в настройках
      form: { // Сама форма
        bank_name: null,
      },
      validator: null, // Валидатор для формы

    },
  }),
  actions: {

  },
});