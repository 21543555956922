<template>
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.125 2.25H11.875C12.2202 2.25 12.5 2.52982 12.5 2.875V4.125H7.5V2.875C7.5 2.52982 7.77982 2.25 8.125 2.25ZM13.75 4.125V2.875C13.75 1.83947 12.9105 1 11.875 1H8.125C7.08947 1 6.25 1.83947 6.25 2.875V4.125H3.13207C3.12782 4.12496 3.12356 4.12496 3.1193 4.125H1.875C1.52982 4.125 1.25 4.40482 1.25 4.75C1.25 5.09518 1.52982 5.375 1.875 5.375H2.548L3.61395 18.6994C3.71789 19.9986 4.80259 21 6.10599 21H13.894C15.1974 21 16.2821 19.9986 16.386 18.6994L17.452 5.375H18.125C18.4702 5.375 18.75 5.09518 18.75 4.75C18.75 4.40482 18.4702 4.125 18.125 4.125H16.8807C16.8764 4.12496 16.8722 4.12496 16.8679 4.125H13.75ZM16.198 5.375L15.14 18.5997C15.0881 19.2493 14.5457 19.75 13.894 19.75H6.10599C5.45429 19.75 4.91194 19.2493 4.85997 18.5997L3.802 5.375H16.198ZM6.8383 6.62608C7.18288 6.60581 7.47865 6.86872 7.49892 7.2133L8.12392 17.8383C8.14419 18.1829 7.88128 18.4787 7.5367 18.4989C7.19212 18.5192 6.89635 18.2563 6.87608 17.9117L6.25108 7.2867C6.23081 6.94212 6.49372 6.64635 6.8383 6.62608ZM13.1617 6.62608C13.5063 6.64635 13.7692 6.94212 13.7489 7.2867L13.1239 17.9117C13.1037 18.2563 12.8079 18.5192 12.4633 18.4989C12.1187 18.4787 11.8558 18.1829 11.8761 17.8383L12.5011 7.2133C12.5213 6.86872 12.8171 6.60581 13.1617 6.62608ZM10 6.625C10.3452 6.625 10.625 6.90482 10.625 7.25V17.875C10.625 18.2202 10.3452 18.5 10 18.5C9.65482 18.5 9.375 18.2202 9.375 17.875V7.25C9.375 6.90482 9.65482 6.625 10 6.625Z"
            fill="#FF3B30" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>



</template>

<script setup>

</script>

<style scoped></style>