<!-- UISearchSelect.vue -->
<template>
  <UIDropdown ref="dropdown">
    <template #trigger>
      <button class="px-4 py-2 bg-white border rounded flex justify-between items-center">
        <span>{{ selectedLabel }}</span>
        <svg
          class="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
    </template>

    <template #dropdown="{ hide }">
      <div class="p-2">
        <input
          type="text"
          v-model="searchQuery"
          @input="onSearch"
          class="w-full px-2 py-1 border rounded mb-2"
          placeholder="Поиск..."
        />
        <ul class="max-h-60 overflow-y-auto">
          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            @click="selectOption(option)"
          >
            {{ option.label }}
          </li>
          <li v-if="filteredOptions.length === 0" class="px-4 py-2 text-gray-500">
            Нет результатов
          </li>
        </ul>
      </div>
    </template>
  </UIDropdown>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import UIDropdown from './UIDropdown.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const dropdown = ref(null)

const searchQuery = ref('')

const selectedOption = ref(props.options.find(option => option.value === props.modelValue) || null)

const selectedLabel = computed(() => selectedOption.value ? selectedOption.value.label : 'Выберите...')

const filteredOptions = computed(() => {
  const query = searchQuery.value.toLowerCase()
  return props.options.filter(option =>
    option.label.toLowerCase().includes(query)
  )
})

const selectOption = (option) => {
  selectedOption.value = option
  emit('update:modelValue', option.value)
  dropdown.value.hide()
}

const onSearch = () => {
  // Можно добавить дополнительные действия при поиске
}

watch(() => props.modelValue, (newVal) => {
  selectedOption.value = props.options.find(option => option.value === newVal) || null
})
</script>

<style scoped>
/* Добавьте стили по необходимости */
</style>