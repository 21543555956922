<template>
    <div class="flex flex-col">
        <img v-if="isMobile" :src="topImage" alt="Background" class="w-full h-auto object-cover" />
        <div
            class="bg-[#3D7CD1] pr-[1.5rem] pl-[1.5rem] pt-[1.5rem] pb-[2.5rem] lg:mt-[30px] lg:mb-[91px] lg:pt-[31px] lg:pb-[47px] lg:pr-0 lg:pl-0 flex flex-col items-center lg:w-[1200px]">

            <div class="flex flex-col lg:w-[992px]">
                <div class="flex flex-row justify-center">
                    <div class="text-center text-[1.5rem] text-[#FFFFFF] leading-[1.68rem] mb-[1.12rem]  font-bold lg:w-[545px]
                md:mb-[43px] md:text-[36px] md:leading-[44px]">
                        Найдите своего идеального кандидата прямо сейчас
                    </div>
                </div>

                <div class="flex flex-col md:flex-row" @keyup.enter="handleEnter">
                    <Input class="h-[3.31rem] md:h-[53px]" type="type1" v-model="profession"
                        placeholder="Профессия, должность или компания"></Input>
                    <div class="mb-[1.5rem] mt-[0.65rem] lg:mt-0 lg:mb-0 lg:ml-[24px]">
                        <router-link :to="{ path: '/employer/resumes', query: { profession: profession } }">
                            <Button type="send-welcome-1" class="md:!h-[55px] md:!w-[230px]">Найти</Button>
                        </router-link>
                    </div>


                </div>
                <template v-if="!$authStore.isAuthenticated">
                  <div v-if="isMobile" class="flex flex-row text-center leading-[1.31rem]">

                    <div  class="text-[1.12rem] text-[#FFFFFF]  font-light">
                      <router-link to="/signup">
                            <span class="custom-underline cursor-pointer">Зарегистрируйтесь
                            </span> чтобы создать вакансию
                      </router-link>

                    </div>


                  </div>
                  <div v-else>
                    <div class="text-[18px] text-[#FFFFFF]  font-light text-center mb-[12px] md:mt-[20px]">
                      <router-link to="/signup">
                        <span class="custom-underline cursor-pointer">Зарегистрируйтесь</span>
                      </router-link>
                      или
                      <router-link to="/login">
                        <span class="custom-underline cursor-pointer">войдите</span>
                      </router-link>

                      в личный кабинет чтобы создать вакансию
                    </div>
                    <div class="text-[18px] text-[#FFFFFF]  font-light text-center mb-[12px]">
                      Ищите несколько сотрудников?
                      <router-link to="/employer/resumes">
                        <span class="custom-underline cursor-pointer"> Перейти к просмотру всех резюме</span>
                      </router-link>

                    </div>
                  </div>
                </template>

            </div>

        </div>
    </div>



</template>

<script setup>
import Sheet from './Sheet.vue';
import Input from './../../components/ui/Input.vue';
import Button from './../../components/ui/Button.vue';
// const customCardClass = 'bg-[#3D7CD1]';
import topImage from '@/assets/images/phone.webp';
import { defineProps, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const isMobile = ref(window.innerWidth <= 768);

const profession = ref('')

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const handleEnter = () => {

  router.push(`/employer/resumes?profession=${profession.value}`)
};
</script>

<style scoped></style>