<template>
    <Card class="cursor-pointer" @click="router.push(`/employee/vacancies/${vacancyId}/`)">
        <template #header>
            <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col md:mb-[16px]">
                    <div class="
                    md:text-[20px]
                    md:leading-[24.46px]
                    mb-[4px]
                    font-medium
                    text-[1.25rem]
                    leading-[1.5rem]
                    w-full
                    md:h-[48px]
                ">{{ vacancyName }}</div>
                    <div class="header-price">От {{ formatPrice(priceFrom) }} рублей</div>
                    <!-- <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)" НЕ УДАЛЯТЬ!!!! ОБСУДИТЬ ПРО PRICE И CURRENCY!!!
                class="header-price">От {{ formatPrice(priceFrom)
                }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}</div>
            <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)" class="header-price">От {{
                formatPrice(priceFrom)
            }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}</div>
            <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)" class="header-price">{{
                formatPrice(priceFrom) }}
                {{ currencyName }}</div>
            <div v-else-if="(priceFrom)" class="header-price">От {{
                formatPrice(priceFrom) }} {{ currencyName }}</div>
            <div v-else-if="(priceTo)" class="header-price">От {{
                formatPrice(priceTo) }} {{ currencyName }}</div>
            <div v-else class="md:mb-[20px] mb-[1.25rem]"></div> -->
                </div>
                <div class="flex-none w-[80px] h-[80px] ml-[8px]">
                    <div v-if="employerAvatar !== null">
                        <img :src="`${baseURL}/files/${employerAvatar}/download`" class="w-[80px] h-[80px] object-fill">
                    </div>
                </div>
            </div>


        </template>
        <!-- <template #sub-action> Добавление в закладки
            <div><BoolIcon name="bookmark-gold" :checked="checked" @click.stop="checked = !checked"></BoolIcon></div>
        </template> -->
        <template #body>
           
        </template>
        <template #footer>
            <div class="flex flex-col justify-end gap-y-[4px]">
                <div v-if="city"
                    class="md:text-[16px] md:leading-[19.6px] text-[1rem] leading-[1.2rem] font-medium">{{
                        city }}</div>
                <div v-else class="md:mb-[19.6px]"></div>
                <div v-if="experience"
                    class="md:text-[16px] md:leading-[19.6px] md:font-normal font-light text-[0.75rem] leading-[0.86rem] text-[#01B00A]">
                    Опыт: {{ formatexperienceForVacancy(experience) }}</div>
                <div v-else class="md:mb-[19.6px]"></div>
                <div class="font-light text-[#7B7A7A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem]">
                    {{ formatDateTime(publicationTime) }}</div>
            </div>
            
        </template>
        <template #action>
            <div class="md:w-[149px] w-[9.3rem]">
                <ButtonNew @click.stop="popupsStore.openResponsePopup(vacancyId, $router)">Откликнуться</ButtonNew>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Sheet from './Sheet.vue';
import BoolIcon from '../ui/BoolIcon.vue';
import Card from './Card.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import ButtonNew from '../ui/ButtonNew.vue';
import { defineProps, defineEmits, computed, onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useFetch } from '@/composables/useFetch';
import {
    formatPrice,
    formatDateTime,
    formatDateTimeVacancyCreated,
    formatexperienceForVacancy
} from '@/composables/utils';
import { usePopupsStore } from "@/stores/popupsStore";
const { baseURL } = useFetch();

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['update:modelValue', 'clickOnButton']);
const checked = ref(false)

const authStore = useAuthStore();
const popupsStore = usePopupsStore()

const toVacancy = () => {
    emit('clickOnButton', props.vacancyId)

};

const props = defineProps({
    vacancyId: {
        type: Number,
        default: null,
    },
    vacancyName: {
        type: String,
        default: 'Текст',
    },
    priceFrom: {
        type: Number,
        default: null,
    },
    priceTo: {
        type: Number,
        default: null,
    },
    currencyName: {
        type: String,
        default: null,
    },
    city: {
        type: String,
        default: null,
    },
    employerAvatar: {
        type: Number,
        default: null,
    },
    publicationTime: {
        type: String,
        default: 'Текст',
    },
    experience: {
        type: String,
        default: null,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
});

// onMounted(() => { 
//   authStore.getMe()
// })


const checkedStar = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<style scoped>
.header-price {
    @apply md:font-normal md:text-[16px] font-light text-[0.75rem] md:mb-0 mb-[0.75rem];
}
</style>