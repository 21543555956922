<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L16 20.5858L27.2929 9.29289C27.6834 8.90237 28.3166 8.90237 28.7071 9.29289C29.0976 9.68342 29.0976 10.3166 28.7071 10.7071L16.7071 22.7071C16.3166 23.0976 15.6834 23.0976 15.2929 22.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289Z" fill="#0A5CD5" stroke="#0A5CD5" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>


</template>

<script setup>

</script>

<style scoped>

</style>