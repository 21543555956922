<template>
  <popup @close="emit('update:modelValue', false)" class="!p-[32px_29px_38px_29px]">
    <template #close>
      <span></span>
    </template>
    <div class="flex flex-col items-center">

      <div
        class="mt-[32px] md:mt-0 mb-[12px] md:mb-[24px] md:mx-[48px] font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] text-center md:text-left md:leading-[1.8125rem] text-black">
        Вы уверены, что хотете выйти из профиля?
      </div>

      <div class="text-center text-[#1F1E1E] text-[1rem] leading-[1.25rem] font-normal max-w-[500px]">
        После выхода Вы не сможете получать оповещения о приглашениях и новых вакансиях
      </div>

      <div class="flex flex-col md:flex-row gap-[16px] mt-[54px] w-full ">
        <ButtonNew
          type="stroke"
          class="w-fit whitespace-nowrap p-[14px_16px]"
          @click="emit('update:modelValue', false)"
        >
          Назад
        </ButtonNew>
        <ButtonNew
          type="stroke"
          class="w-fit p-[14px_16px] border-[#FF3B30] text-[#FF3B30] hover:bg-[#FF3B30]"
          @click="popupsStore.logout.callback"
        >
          Выйти
        </ButtonNew>
      </div>


    </div>
  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import {defineProps, defineEmits} from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

const popupsStore = usePopupsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const send = () => {
  if (popupsStore.invitation.callback) {
    popupsStore.invitation.callback(popupsStore.invitation.resumeId, popupsStore.invitation.message)
    popupsStore.invitation.visible = false
  }
}
</script>

<style scoped>

</style>