<template>
    <div :class=computedClasses>{{ computedName }}</div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';

// Определяем пропсы компонента
const props = defineProps({
    type: {
        type: String,
        default: 'blue'
    },
    class: {
      type: String,
      default: ''
    },
    add: {
        default: false
    }
});

const standardClass = "h-[2.8rem] md:h-[48px] md:rounded-[12px] rounded-[0.75rem] flex items-center justify-center font-normal text-[0.85rem] md:text-[16px] leading-[1.2rem] md:leading-[19.6px] w-[227px] "

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
    if (props.type == 'employer') {
        return  standardClass + "bg-[#0A5CD5] hover:bg-[#0B54BF] text-[#FFFFFF] " + props.class
    }
    else if (props.type == 'employee') {
        return standardClass + "bg-[#FFFFFF] hover:bg-[#0A5CD5] hover:text-[#FFFFFF] text-[#0A5CD5] border-[1px] border-[#0A5CD5] " + props.class
    } else return ''
});

const computedName = computed(() => {
    if (props.type == 'employer') {
        return  'Войти как работодатель'
    }
    else if (props.type == 'employee') {
        return 'Войти как соискатель'
    } else return ''
});
</script>

<style scoped>

</style>