import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";

export const useResumesStore = defineStore('resumesStore', {
  state: () => ({
    item: null, // текущая вакансия
    items: [],
  }),
  actions: {
    getAccountResumes(){
      const fetch = useFetch()
      const authStore = useAuthStore()
      let params = {
        user: authStore.me.id
      }
      fetch.authRequest('/resumes/', {
        params: params
      }).then((data) => {
        this.items = data.results
      })
    },
  },
});