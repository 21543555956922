<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50021 0.144531C7.9736 0.144531 8.35735 0.528287 8.35735 1.00167V6.14453H13.5002C13.9736 6.14453 14.3574 6.52829 14.3574 7.00167C14.3574 7.47506 13.9736 7.85882 13.5002 7.85882H8.35735V13.0017C8.35735 13.4751 7.9736 13.8588 7.50021 13.8588C7.02682 13.8588 6.64307 13.4751 6.64307 13.0017V7.85882H1.50021C1.02682 7.85882 0.643066 7.47506 0.643066 7.00167C0.643066 6.52829 1.02682 6.14453 1.50021 6.14453H6.64307V1.00167C6.64307 0.528287 7.02682 0.144531 7.50021 0.144531Z" fill="white"/>
  </svg>

</template>

<script setup>

</script>

<style scoped>

</style>