<template>
    <div :class=standardClass>
        <div class="flex flex-row justify-center h-full">
            <div
                class="flex flex-col bg-[#0A5CD5] pt-[2.93rem] pb-[3rem] md:pt-[40px] md:pb-[32px] justify-start md:justify-between md:items-center footer w-full">

                <div class="flex flex-row justify-between md:w-[992px] mb-[55px] md:mb-0">

                    <div class="flex flex-col md:flex-row">
                        <div class="flex flex-col md:mr-[24px]">
                            <router-link to="/employee">
                                <div class="footer-menu-item">Соискателям</div>
                            </router-link>

                            <router-link to="/employer">
                                <div class="footer-menu-item">Работодателям</div>
                            </router-link>

                            <router-link to="/">
                                <div class="footer-menu-item">Студентам</div>
                            </router-link>

                            <router-link to="/education/articles">
                                <div class="footer-menu-item">Обучающая платформа</div>
                            </router-link>

                            <router-link to="/education/events">
                                <div class="footer-menu-item">Мероприятия</div>
                            </router-link>

                        </div>

                        <div class="flex flex-col md:mr-[24px]">
                            <router-link to="/">
                                <div class="footer-menu-item">Партнерская программа</div>
                            </router-link>

                            <router-link to="/">
                                <div class="footer-menu-item">О компании</div>
                            </router-link>

                            <router-link to="/employee/vacancies">
                                <div class="footer-menu-item">Вакансии</div>
                            </router-link>

                        </div>

                        <div class="flex flex-col">
                            <router-link to="/">
                                <div class="footer-menu-item">Тех. поддержка</div>
                            </router-link>

                            <router-link to="/">
                                <div class="footer-menu-item">FAQ</div>
                            </router-link>

                        </div>

                    </div>
                    <router-link to="/">
                        <img v-if="!isMobile" src="./../../assets/images/logo.webp"
                            class="w-[317px] h-[129px] object-cover">
                    </router-link>
                    <router-link to="/" v-if="isMobile">
                        <img src="./../../assets/images/footer-logo-mobile.webp"
                            class="w-[131px] h-[138px] object-cover">
                    </router-link>
                </div>


                <div class="flex flex-row flex-wrap md:w-[992px] md:mt-[125px]">
                    <div class="footer-menu-item-down">
                        Юридическая информация
                    </div>
                    <div class="footer-menu-item-down">
                        Договор оферты
                    </div>
                    <div class="footer-menu-item-down">
                        Политика конфиденциальности
                    </div>
                </div>


            </div>
        </div>

    </div>

</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits, ref, onMounted, onUnmounted } from 'vue';

const standardClass = "w-full md:h-[356px] ";
const props = defineProps({
    class: {
        type: String,
        default: ''
    },
});


const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style scoped>
@media (min-width: 1800px) {
    .footer {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

.footer-menu-item {
    @apply text-[0.85rem] md:text-[12px] md:mb-[12px] leading-[1rem] md:leading-[15px] text-[#FFFFFF] mb-[0.85rem] cursor-pointer;
    font-weight: 300;
}

.footer-menu-item-down {
    @apply text-[0.85rem] md:text-[12px] mb-[0.85rem] md:mb-0 leading-[1rem] md:leading-[14px] text-[#B7B7B7] cursor-pointer mr-[0.5rem] md:mr-[90px];
    font-weight: 300;
}
</style>