<template>
  <div class="z-50 fixed top-0 left-0 w-screen h-screen bg-transparent text-black">
    <div class="absolute top-0 left-0 w-full h-full bg-[#0A5CD5] bg-opacity-10" @click="close"></div>

    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-start pointer-events-none">

      <sheet class="mt-[56px] w-full min-h-[10vh] h-fit max-h-[80vh] md:p-[56px_64px] overflow-y-auto relative pointer-events-auto rounded-[0px_0px_24px_24px]">
        <div class="absolute top-[20px] right-[20px] cursor-pointer" @click="close">
          <img src="/icons/x-lg-32-blue.svg">
        </div>
        <slot>
          Содержимое action sheet
        </slot>
      </sheet>
    </div>

  </div>

</template>

<script setup>
import {defineEmits} from 'vue';
import Sheet from "@/components/cards/Sheet";

const emit = defineEmits(['close']);
const close = () => {
  emit('close');
};

</script>

<style scoped></style>