

<template>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.743 11.375H25.1332C25.4777 11.375 25.6658 11.7767 25.4453 12.0413L22.2502 15.8755C22.0878 16.0704 21.7884 16.0704 21.626 15.8755L18.4309 12.0413C18.2104 11.7767 18.3985 11.375 18.743 11.375Z" fill="#0A5CD5"/>
    <path d="M0.867964 14.625H7.25824C7.60268 14.625 7.79084 14.2233 7.57033 13.9587L4.37519 10.1245C4.21278 9.92961 3.91343 9.92961 3.75101 10.1245L0.555874 13.9587C0.335373 14.2233 0.52353 14.625 0.867964 14.625Z" fill="#0A5CD5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0006 4.875C10.4778 4.875 8.22402 6.02382 6.73247 7.82988C6.44673 8.17588 5.9346 8.22472 5.58861 7.93898C5.24262 7.65323 5.19377 7.14111 5.47952 6.79512C7.26653 4.63129 9.97261 3.25 13.0006 3.25C17.7809 3.25 21.7561 6.68932 22.5899 11.2282C22.5988 11.277 22.6074 11.3259 22.6157 11.375H20.9632C20.2108 7.66639 16.9309 4.875 13.0006 4.875ZM5.03797 14.625C5.79037 18.3336 9.07033 21.125 13.0006 21.125C15.5234 21.125 17.7772 19.9762 19.2687 18.1701C19.5545 17.8241 20.0666 17.7753 20.4126 18.061C20.7586 18.3468 20.8074 18.8589 20.5217 19.2049C18.7347 21.3687 16.0286 22.75 13.0006 22.75C8.22034 22.75 4.24507 19.3107 3.41134 14.7718C3.40238 14.723 3.39378 14.6741 3.38554 14.625H5.03797Z" fill="#0A5CD5"/>
    </svg>
  
  
  </template>
  
  <script setup>
  
  </script>
  
  <style scoped>
  
  </style>