<template>
    <Sheet class="pt-[1.75rem] pl-[2rem] pr-[2rem] pb-[1.75rem] md:pt-[28px] md:pl-[17px] md:pb-[28px] md:pr-[17px]  flex flex-col items-center">
        <div class=" font-medium text-[0.87rem] mb-[1rem] md:text-[14px] md:mb-[15px]">
            Еще не зарегистрированы?
        </div>
        <Button @click="router.push('/signup')" type="signup-logincard">Регистрация</Button>  
    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Checkbox from './../../components/ui/Checkbox.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()

</script>

<style scoped></style>