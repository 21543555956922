<template>
    <LKLayout :loading="loading">

        <div class="flex flex-col gap-[20px]">



            <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                    <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Обложка</div>
                    <!--          <UIIcon size="10" name="asterisk"></UIIcon>-->
                </div>
                <UIFileUploader v-model="form.cover" :is-image="true"></UIFileUploader>
            </div>

            <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                    <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Название</div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="form.name" :error-text="formValidator.errors.name" placeholder="Введите название">
                </UIInput>
            </div>

            <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                    <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Краткое описание</div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="form.short_description" :error-text="formValidator.errors.short_description"
                    placeholder="Введите название">
                </UIInput>
            </div>

            <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                    <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Содержание</div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UITextArea v-model="form.content" :error-text="formValidator.errors.content"
                    placeholder="Введите содержание" textAreaClass="min-h-[300px]">

                </UITextArea>
            </div>

            <div class="flex flex-col gap-[8px]">
                <div class="flex gap-[8px] items-start">
                    <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Дата мероприятия</div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIDatePicker v-model="form.date" :error-text="formValidator.errors.date"></UIDatePicker>
            </div>

            

            <div>
                <ButtonNew class="!w-fit px-10" @click="formValidator.handleSubmit(id !== 'create' ? update : create)">
                    {{ id !== 'create' ? 'Сохранить' : 'Создать' }}
                </ButtonNew>
            </div>


        </div>




    </LKLayout>
</template>

<script setup>
import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import UIIcon from "@/components/ui/UIIcon";
import UIDatePicker from "@/components/ui/UIDatePicker.vue";
import { useArticlesStore } from "@/stores/articlesStore";
import { onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import UIInput from "@/components/ui/UIInput";
import UITextArea from "@/components/ui/UITextArea";
import { useFormValidator } from "@/composables/useFormValidator";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import UIFileUploader from "@/components/ui/UIFileUploader";
import { useVacanciesStore } from "@/stores/vacanciesStore";
import UISelect from "@/components/ui/UISelect";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useResumesStore } from "@/stores/resumesStore";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { useEventsStore } from "@/stores/eventsStore";
import { data } from "autoprefixer";

const router = useRouter()
const toast = useToast()

const eventsStore = useEventsStore()
const vacanciesStore = useVacanciesStore()
const route = useRoute()

const authStore = useAuthStore()
const resumesStore = useResumesStore()



const validatorFuncs = useValidateFuncs()




const form = reactive({
    name: null,
    content: null,
    cover: null,
    short_description: null,
    date: null,
})

const formValidator = useFormValidator(
    form,
    {
        'name': [validatorFuncs.isNotEmpty],
        'content': [validatorFuncs.isNotEmpty],
        'short_description': [validatorFuncs.isNotEmpty],
        'date': [validatorFuncs.isNotEmpty],
    },
)

const loading = ref(true)

const id = ref(null)



onBeforeMount(() => {
    id.value = route.params.id
    loading.value = true
    const tasks = [
        authStore.getMe(),
    ]
    if (id.value !== 'create') {
        tasks.push(eventsStore.getAccountEvent(id.value).then(data => {
            form.name = data.name
            form.content = data.content
            form.cover = data.cover
            form.short_description = data.short_description
            form.date = data.date
        }))
    }

    Promise.all(tasks).finally(() => {
        loading.value = false
    })


})


const create = () => {
    loading.value = true
    let params = {
        name: form.name,
        content: form.content,
        cover: form.cover,
        short_description: form.short_description,
        date: form.date
    }
    eventsStore.create({
        ...params
    }).finally(() => {
        router.push('/account/employer/events')
        toast.success('Сохранено')
        loading.value = false
    })
}

const update = () => {
    loading.value = true
    let params = {
        name: form.name,
        content: form.content,
        cover: form.cover,
        short_description: form.short_description,
        date: form.date
    }
    eventsStore.update(id.value, {
        ...params
    }).finally(() => {

        router.push('/account/employer/events')
        toast.success('Сохранено')
        loading.value = false
    })
}


</script>

<style scoped></style>