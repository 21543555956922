<template>
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 4.5C12 4.5 9.75 0.375 6 0.375C2.25 0.375 0 4.5 0 4.5C0 4.5 2.25 8.625 6 8.625C9.75 8.625 12 4.5 12 4.5ZM0.879524 4.5C0.922219 4.43491 0.970906 4.36258 1.02545 4.28431C1.27662 3.92395 1.64723 3.44468 2.12408 2.96783C3.09077 2.00114 4.41046 1.125 6 1.125C7.58954 1.125 8.90923 2.00114 9.87592 2.96783C10.3528 3.44468 10.7234 3.92395 10.9745 4.28431C11.0291 4.36258 11.0778 4.43491 11.1205 4.5C11.0778 4.56509 11.0291 4.63742 10.9745 4.71569C10.7234 5.07605 10.3528 5.55532 9.87592 6.03217C8.90923 6.99886 7.58954 7.875 6 7.875C4.41046 7.875 3.09077 6.99886 2.12408 6.03217C1.64723 5.55532 1.27662 5.07605 1.02545 4.71569C0.970906 4.63742 0.922219 4.56509 0.879524 4.5Z"
            fill="#0A5CD5" />
        <path
            d="M6 2.625C4.96447 2.625 4.125 3.46447 4.125 4.5C4.125 5.53553 4.96447 6.375 6 6.375C7.03553 6.375 7.875 5.53553 7.875 4.5C7.875 3.46447 7.03553 2.625 6 2.625ZM3.375 4.5C3.375 3.05025 4.55025 1.875 6 1.875C7.44975 1.875 8.625 3.05025 8.625 4.5C8.625 5.94975 7.44975 7.125 6 7.125C4.55025 7.125 3.375 5.94975 3.375 4.5Z"
            fill="#0A5CD5" />
    </svg>


</template>

<script setup>

</script>

<style scoped></style>