import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";

export const useVacanciesStore = defineStore('vacanciesStore', {
  state: () => ({
    item: null, // текущая вакансия
    items: [],
  }),
  actions: {
    getAccountVacancies(){
      const fetch = useFetch()
      const authStore = useAuthStore()
      let params = {
        employer: authStore.me.employer_profile_id,
      }
      fetch.authRequest('/vacancies/', {
        params: params
      }).then((data) => {
        this.items = data.results
      })
    },
    setStatus(vacancyId, status) {
      const fetch = useFetch()
      return fetch.authRequest(`/vacancies/${vacancyId}/`, {
        method: 'PUT',
        body: {
          status,
        }
      })
    },
    clone(vacancyId) {
      const fetch = useFetch()
      return fetch.authRequest(`/vacancies/${vacancyId}/clone`, {
        method: 'POST',
      })
    }
  },
});