<template>
  <LKLayout :loading="loading">

    <div class="flex flex-col gap-[20px]">



      <div class="flex flex-col gap-[8px]">
        <div class="flex gap-[8px] items-start">
          <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Обложка</div>
          <!--          <UIIcon size="10" name="asterisk"></UIIcon>-->
        </div>
        <UIFileUploader v-model="form.cover" :is-image="true"></UIFileUploader>
      </div>

      <div class="flex flex-col gap-[8px]">
        <div class="flex gap-[8px] items-start">
          <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Название</div>
          <UIIcon size="10" name="asterisk"></UIIcon>
        </div>
        <UIInput v-model="form.name" :error-text="formValidator.errors.name" placeholder="Введите название">
        </UIInput>
      </div>

      <div class="flex flex-col gap-[8px]">
        <div class="flex gap-[8px] items-start">
          <div class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Содержание</div>
          <UIIcon size="10" name="asterisk"></UIIcon>
        </div>
        <UITextArea v-model="form.content" :error-text="formValidator.errors.content" placeholder="Введите содержание" textAreaClass="min-h-[300px]">

        </UITextArea>
      </div>

      <div class="flex flex-col gap-[8px]">
        <div class="flex gap-[8px] items-start">
          <div v-if="authStore.me.role === 'employer'" class="font-medium text-[1.25rem] leading-[1.5rem] text-black">
            Релевантные вакансии</div>
          <div v-else-if="authStore.me.role === 'employee'"
            class="font-medium text-[1.25rem] leading-[1.5rem] text-black">Прикрепленное резюме</div>
        </div>

        <div v-if="authStore.me.role === 'employer'" class="flex flex-col gap-[12px]">
          <UISelect :options="vacanciesStore.items.map(i => ({ title: i.name, value: i.id }))"
            v-model="form.related_vacancies[0].id"></UISelect>
          <UISelect :options="vacanciesStore.items.map(i => ({ title: i.name, value: i.id }))"
            v-model="form.related_vacancies[1].id"></UISelect>
          <UISelect :options="vacanciesStore.items.map(i => ({ title: i.name, value: i.id }))"
            v-model="form.related_vacancies[2].id"></UISelect>

        </div>
        <div v-else-if="authStore.me.role === 'employee'" class="flex flex-col gap-[12px]">
          <UISelect :options="resumesStore.items.map(i => ({ title: i.speciality_name, value: i.id }))"
            v-model="form.resume.id">
          </UISelect>

        </div>

      </div>




      <div>
        <ButtonNew class="!w-fit px-10" @click="formValidator.handleSubmit(id !== 'create' ? update : create)">
          {{ id !== 'create' ? 'Сохранить' : 'Создать' }}
        </ButtonNew>
      </div>


    </div>




  </LKLayout>
</template>

<script setup>
import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import UIIcon from "@/components/ui/UIIcon";
import { useArticlesStore } from "@/stores/articlesStore";
import { onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import UIInput from "@/components/ui/UIInput";
import UITextArea from "@/components/ui/UITextArea";
import { useFormValidator } from "@/composables/useFormValidator";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import UIFileUploader from "@/components/ui/UIFileUploader";
import { useVacanciesStore } from "@/stores/vacanciesStore";
import UISelect from "@/components/ui/UISelect";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useResumesStore } from "@/stores/resumesStore";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";

const router = useRouter()
const toast = useToast()

const articlesStore = useArticlesStore()
const vacanciesStore = useVacanciesStore()
const route = useRoute()

const authStore = useAuthStore()
const resumesStore = useResumesStore()



const validatorFuncs = useValidateFuncs()




const form = reactive({
  name: null,
  content: null,
  cover: null,
  resume: { id: null },
  related_vacancies: [{ id: null }, { id: null }, { id: null }],

})

const formValidator = useFormValidator(
  form,
  {
    'name': [validatorFuncs.isNotEmpty],
    'content': [validatorFuncs.isNotEmpty],
  },
)

const loading = ref(true)

const id = ref(null)



onBeforeMount(() => {
  id.value = route.params.id
  loading.value = true
  const tasks = [
    authStore.getMe(),
    vacanciesStore.getAccountVacancies(),
    resumesStore.getAccountResumes()
  ]
  if (id.value !== 'create') {
    tasks.push(articlesStore.getAccountArticle(id.value).then(data => {
      form.name = data.name
      form.content = data.content
      form.cover = data.cover
      // form.related_vacancies = data.related_vacancies.map(item => ({id: item.id}))
      if (data.resume)
        form.resume = data.resume
      while (form.related_vacancies.length < 3) {
        form.related_vacancies.push({ id: null })
      }
    }))
  }

  Promise.all(tasks).finally(() => {
    loading.value = false
  })


})



onMounted(() => {

})

const create = () => {
  loading.value = true
  let params = {
    name: form.name,
    content: form.content,
    cover: form.cover,
    related_vacancies: form.related_vacancies.map(item => item.id).filter(item => item),
  }
  if (form.resume.id !== null) {
    params.resume = form.resume.id
  }
  articlesStore.create({
    ...params
  }).finally(() => {
    router.push('/account/articles')
    toast.success('Сохранено')
    loading.value = false
  })
}

const update = () => {
  loading.value = true
  let params = {
    name: form.name,
    content: form.content,
    cover: form.cover,
    related_vacancies: form.related_vacancies.map(item => item.id).filter(item => item),
  }
  if (form.resume.id !== null) {
    params.resume = form.resume.id
  }
  articlesStore.update(id.value, {
    ...params
  }).finally(() => {

    router.push('/account/articles')
    toast.success('Сохранено')
    loading.value = false
  })
}


</script>

<style scoped></style>