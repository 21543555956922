<template>
  <div :class="computedClasses">
    <div class="relative w-full ">
<!--      <input :disabled="props.disabled" :class="computedInput" :style="{ outline: outlineColor }" :placeholder="placeholder"-->
      <input :disabled="props.disabled" :class="computedInput" :style="{ border: outlineColor, outline: 'none' }" :placeholder="placeholder"
        v-model="inputValue" :type="computedType" @focus="onFocus" @blur="onBlur" >
      <div v-if="props.type === 'password'" class="absolute top-[1.2rem] right-[1.2rem] md:top-[19px] md:right-[16px]" @mousedown.prevent="toggleHidden">
        <Icon v-if="hidden === true" name="eyeNovision"></Icon>
        <Icon v-else name="eye"></Icon>
      </div>
<!--      <div v-if="props.type === 'lupa'" class="absolute top-[0.6rem] left-[0.6rem] pointer-events-none">-->
<!--        <Icon name="lupa-grey"></Icon>-->
<!--      </div>-->
    </div>
    <div v-if="errorText" class="w-full text-left font-light text-[12px] mt-[12px]">{{errorText}}</div>
  </div>
    

  


</template>

<script setup>
import { computed } from 'vue';
import { defineProps, onMounted, ref, watch, defineEmits } from 'vue';
import Icon from "@/components/ui/Icon";


const toggleHidden = () => {
  hidden.value = !hidden.value
}

// Определяем пропсы компонента
const props = defineProps({
  type: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: 'Введите что нибудь'
  },
  class: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  hiddenDefault: {
    default: true,
  },
  outlineColor: {
    default: '#1F1E1E'
  },
  errorText: {
    default: null
  },
  disabled: {
    default: false
  },
  inputClass: {
    default: '',
  }
});

const placeholder = ref(props.placeholder)

const isFocused = ref(false)
const onFocus = () => {
  placeholder.value = null
  isFocused.value = true; // Установить фокус
}
const onBlur = () => {
  isFocused.value = false; // Снять фокус

  if (inputValue.value === '') {
    placeholder.value = props.placeholder
  }
}

const outlineColor = computed(() => {
  // Условие для изменения цвета outline в зависимости от состояния фокуса
  if (props.errorText) {
    return `1px solid #FF2D2D`;
  }
  if (isFocused.value === false) {
    return '1px solid #B7B7B7'; // Цвет при фокусе
  } else {
    return `1px solid ${props.outlineColor}`; // Цвет, когда не в фокусе
  }
}
)

const inputValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue', 'search']);

watch(() => props.modelValue, () => {
  inputValue.value = props.modelValue
})


const hidden = ref(props.hiddenDefault);

watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});

const standardClass = ` bg-[#FFFFFF] md:p-[12px_8px] py-[1.06rem] md:py-auto rounded-[0.94rem] md:rounded-[12px] text-left placeholder-color font-normal text-[1rem] md:text-[16px] leading-[0.9375rem] md:leading-[0.9375rem] w-full border`

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
  return 'w-full ' + props.class
});

const computedInput = computed(() => {
  if (props.type === 'lupa') {
    // return standardClass + ' px-[3.5rem]' + props.inputClass
    return standardClass + ' px-[1rem]' + props.inputClass
  } else {
    if (props.type === 'password') {
      return standardClass + ' px-[1rem] md:!text-[14px] md:!leading-[14.7px]' + props.inputClass
    } else return standardClass + ' px-[1rem]' + props.inputClass
  }
  
});



const computedType = computed(() => {
  if (props.type === 'email') {
    return 'email'
  }
  else if (props.type === 'password') {
    if (hidden.value === true) {
      return 'password'
    } else {
      return 'text'
    }
  }
  else if (props.type === 'number') {
    return 'number'
  }
  else {
    return props.type || 'text'
  }

})

</script>

<style scoped>
/* Можете добавить стили, если это необходимо */
/* .font-normal {
    font-weight: 400;
  } */
.custom-inner-border {
  position: relative;
}

.custom-inner-border::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #888888;
  border-radius: calc(0.94rem - 1px);
  /* Adjusting border radius */
  pointer-events: none;
  /* Disable pointer events to make sure this pseudo-element doesn't interfere with button interactions */
}


@media (min-width: 769px) {
  .placeholder-color::placeholder {
    color: #B7B7B7;
    /* Цвет плейсхолдера */
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .placeholder-color::placeholder {
    color:  #B7B7B7;
    /* Цвет плейсхолдера */
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }
}




/* Класс для ввода текста по левому краю */
input:not(:placeholder-shown) {
  text-align: left;
}

</style>
