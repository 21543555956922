<template>
  <slot name="default" :data="{triggerFileInput, imageId, imageUrl, uploading, uploadMessage, onDeleteFile, fileData}">
    <div
      class="file-upload-area"
      @click="triggerFileInput"
      :class="{ 'file-selected': imageUrl }"
    >
      <p v-if="!imageId">Файл не загружен</p>
      <img v-if="imageId && ['png', 'jpeg', 'jpg', 'webp'].includes(fileData?.ext)" :src="imageUrl" class="max-h-[200px]"
           alt="Uploaded Image"/>
      <div v-else-if="fileData">{{ fileData?.filename }}</div>
    </div>
    <p v-if="uploading">{{ uploadMessage }}</p>
    <Button v-if="imageId" type="login-logincard" class="md:w-[362px] !text-[14px] mt-2 whitespace-nowrap"
            @click="onDeleteFile">
      Удалить файл
    </Button>
  </slot>

  <!--    <input-->
  <!--      type="file"-->
  <!--      @change="onFileChange"-->
  <!--      accept="image/*"-->
  <!--      ref="fileInput"-->
  <!--      style="display: none;"-->
  <!--      class="absolute left-0 top-0 w-0 h-0"-->
  <!--    />-->

  <input
    type="file"
    @change="onFileChange"
    ref="fileInput"
    style="display: none;"
    class="absolute left-0 top-0 w-0 h-0"
  />


</template>

<script setup>
import {ref, defineEmits, defineProps, watch, onMounted} from 'vue';
import {useFetch} from '@/composables/useFetch';
import {useToast} from "vue-toast-notification";
import Button from "@/components/ui/Button";
import {useFilesStore} from "@/stores/filesStore";

const fetch = useFetch()

const toast = useToast();

const props = defineProps({
  maxFileSize: {
    type: Number,
    default: 16 * 1024 * 1024
  },
  modelValue: {
    type: String,
    default: null,
  },
  isImage: {
    default: false,
  }
});

const emit = defineEmits(['fileUploaded', 'update:modelValue']);

const uploading = ref(false);
const uploadMessage = ref('');
const imageUrl = ref('');
const imageId = ref(null);
const fileInput = ref(null);
const filesStore = useFilesStore()

watch(() => props.modelValue, (newValue) => {
  imageId.value = newValue;
});

onMounted(() => {
  imageId.value = props.modelValue
  if (imageId.value && !fileData.value) {
    filesStore.getFile(imageId.value).then(data => {
      fileData.value = data.data
    })
  }

})

watch(() => imageId.value, (id) => {
  imageUrl.value = `${fetch.baseURL}/files/${id}/download`;
  emit('update:modelValue', imageId.value)

})

const triggerFileInput = () => {
  fileInput.value.click();
};

const fileData = ref(null)

const onFileChange = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  // if (!file.type.startsWith('image/')) {
  //   toast.warning('Пожалуйста, выберите изображение');
  //   return;
  // }
  //
  // if (file.size > props.maxFileSize) {
  //   toast.warning('Размер файла не должен превышать 1 МБ');
  //   return;
  // }

  uploading.value = true;
  uploadMessage.value = 'Загрузка...';

  const fetch = useFetch();
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);

  try {
    const response = await fetch.authRequest('/files/', {
      method: 'POST',
      body: formData,
    });
    imageId.value = response.data.id;
    fileData.value = response.data
    emit('fileUploaded', response.data);
    emit('update:modelValue', response.data.id);
  } catch (error) {
    toast.error('Ошибка загрузки файла');
  } finally {
    uploading.value = false;
    uploadMessage.value = '';
  }
};

const onDeleteFile = async () => {
  fileData.value = null
  if (!imageId.value) return;

  const fetch = useFetch();

  try {
    await fetch.authRequest(`/files/${imageId.value}/`, {
      method: 'DELETE',
    });
    imageUrl.value = '';
    imageId.value = null;
    emit('update:modelValue', null);
    toast.success('Файл успешно удалён');
  } catch (error) {
    toast.error('Ошибка удаления файла');
  }
};
</script>

<style scoped>
.file-upload-area {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-upload-area.file-selected {
  border-color: #66bb6a;
}
</style>
