<template>
  <UIContainer >
    <div class="flex gap-[12px]">
      <div class="min-w-[250px] bg-orange-50 p-[12px] flex flex-col">
        <div>Компоненты</div>
        <hr>
        <a href="#ButtonNew">Кнопка</a>
        <a href="#UIInput">Инпут</a>
        <a href="#UIIcon">Иконки</a>
      </div>
      <div  class="docs max-h-[100vh] overflow-auto">
        <ButtonNewDocs id="ButtonNew"></ButtonNewDocs>
        <UIInputDocs id="UIInput"></UIInputDocs>
        <UIIconDocs id="UIIcon"></UIIconDocs>
      </div>
    </div>

  </UIContainer>
</template>

<script setup>
import Button from './../components/ui/Button.vue';  // Импортируем компонент Button
import Input from './../components/ui/Input.vue';
import Checkbox from './../components/ui/Checkbox.vue';
import CheckboxStar from './../components/ui/CheckboxStar.vue';
import Grade from './../components/ui/Grade.vue';
import {ref, reactive} from 'vue';
import ButtonNew from "@/components/ui/ButtonNew";
import UIContainer from "@/components/ui/UIContainer";
import ButtonNewDocs from "@/pages/docs/ButtonNewDocs";
import UIInputDocs from "@/pages/docs/UIInputDocs";
import UIIconDocs from "@/pages/docs/UIIconDocs";

const count = ref(0);

const isChecked = ref(true);
const isCheckedStar = ref(true);
// export default {
//     data() {
//       return {
//         grade: 0
//       };
//     },
// }
</script>

<style scoped>


.docs >>> h1 {
  font-size: 2rem;
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.docs >>> h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.docs  >>> h3 {
  font-size: 1.25rem;
  margin-top: 1.2rem;
}

.docs >>>  p {
  margin: 0.5rem 0;
}

.docs >>>  pre {
  background-color: #e9ecef;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
}

.docs >>>  code {
  background-color: #e9ecef;
  padding: 8px 12px;
  border-radius: 3px;
  font-family: monospace;
}

.docs  >>> ul {
  list-style-type: disc;
  margin-left: 40px;
}

.docs >>>  li {
  margin-bottom: 0.5rem;
}

.docs  >>> #documentation {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.docs  >>> .example-group {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


</style>
