<template>
    <LKLayout>
        <div class="p-[12px] flex gap-[40px] mb-[32px]">
            <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
                :class="`${miActive('/employer/lk/tarif?type=tarif') ? '!text-[#0A5CD5] !font-medium' : ''}`"
                @click="$router.push('/employer/lk/tarif?type=tarif')">
                Тариф
            </div>
            <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
                :class="`${miActive('/employer/lk/tarif?type=acts') ? '!text-[#0A5CD5] !font-medium' : ''}`"
                @click="$router.push('/employer/lk/tarif?type=acts')">
                Счета и акты
            </div>
            <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
                :class="`${miActive('/employer/lk/tarif?type=paid') ? '!text-[#0A5CD5] !font-medium' : ''}`"
                @click="$router.push('/employer/lk/tarif?type=paid')">
                Оплата
            </div>
            <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
                :class="`${miActive('/employer/lk/tarif?type=promocodes') ? '!text-[#0A5CD5] !font-medium' : ''}`"
                @click="$router.push('/employer/lk/tarif?type=promocodes')">
                Промокоды
            </div>
        </div>
        <div v-if="type === 'tarif'">
            <Sheet v-if="$authStore.me && fetched" class="flex flex-col justify-between h-min !p-[32px] gap-y-[32px]">
                <div v-if="!authStore.me.user_subscription" class="flex flex-col gap-y-[16px]">
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">У вас не активирован тарифный
                        план
                    </div>
                    <div>Выберите подходящий вариант, оплатите и публикуйте вакансии</div>
                </div>
                <div v-else class="flex flex-col gap-y-[32px]">
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">Активный тарифный план</div>
                    <div v-if="activeTarif" class="active-tarif-frame">
                        <div class="flex flex-col w-[180px]">
                            <div class="font-light text-[12px] leading-[15px]">Тариф</div>
                            <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{ activeTarif.name }}
                            </div>
                            <div class="mb-[12px]"></div>
                            <div class="text-[16px] mb-[7px]">{{ activeTarif.vacancies_for_publication }} вакансий</div>
                            <div class="flex flex-row items-center h-[24px]">
                                <div class="ml-[-6px] flex flex-row items-center" v-if="!activeTarif.branding">
                                    <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                                    <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">Брендирование</div>
                                </div>
                                <div v-else class="flex flex-row gap-x-[4px] items-center">
                                    <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                                    <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">Брендирование</div>
                                </div>
                            </div>
                            <div class="flex flex-row  items-center mb-[12px] h-[24px]">
                                <div class="ml-[-6px] flex flex-row items-center" v-if="!activeTarif.resumes_base">
                                    <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                                    <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">База резюме</div>
                                </div>
                                <div v-else class="flex flex-row gap-x-[4px] items-center">
                                    <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                                    <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">База резюме</div>
                                </div>
                            </div>
                            <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{
                                activeTarif.price_per_month }} ₽</div>

                        </div>
                        <div class="flex flex-col gap-y-[39px] w-[290px]">
                            <div class="flex flex-col gap-y-[12px]">
                                <div>Активирован {{ formatDateTime(activeSubscribition.created_at) }}</div>
                                <div class="font-light text-[#01B00A] text-[12px]">{{ authStore.me.vacancies_count }} из
                                    {{
                                        activeTarif.vacancies_for_publication }} вакансий опубликовано</div>
                                <div class="font-light text-[#FF3B30] text-[12px]">Срок истекает через {{
                                    daysUntil(activeSubscribition.date_end) }} дней</div>
                            </div>
                            <div>
                                <ButtonNew class="md:h-[40px]" type="stroke"
                                    @click="router.push(`/employer/lk/tarif?type=paid`)">Продлить</ButtonNew>
                            </div>

                        </div>
                    </div>
                    <div class="font-light text-[12px] text-[#7B7A7A]">
                        Продлите тарифный план или перейдите на более высокий, что бы продолжить публиковать вакансии и
                        получать дополнительные услуги
                    </div>
                </div>
                <!-- {{ avaliableTarifs }} -->
            </Sheet>



            <div class="mt-[40px] mb-[32px] font-medium text-[20px] leading-[24px] text-[#0A5CD5]">Доступные варианты
            </div>
            <div class="flex flex-row gap-x-[20px]">
                <Sheet v-for="tarif in avaliableTarifs"
                    class="flex flex-col justify-between items-center h-min !p-[24px] w-[211px]">
                    <div class="font-light text-[12px] leading-[15px]">Тариф</div>
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{ tarif.name }}</div>
                    <div class="mb-[12px]"></div>
                    <div class="text-[16px] mb-[7px]">{{ tarif.vacancies_for_publication }} вакансий</div>
                    <div class="flex flex-row items-center h-[24px] w-full">
                        <div class="ml-[-6px] flex flex-row items-center" v-if="!tarif.branding">
                            <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                            <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">Брендирование</div>
                        </div>
                        <div v-else class="flex flex-row gap-x-[4px] items-center">
                            <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                            <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">Брендирование</div>
                        </div>
                    </div>
                    <div class="flex flex-row  items-center mb-[12px] h-[24px] w-full">
                        <div class="ml-[-6px] flex flex-row items-center" v-if="!tarif.resumes_base">
                            <UIIcon size="24" name="x" color="#FF3B30"></UIIcon>
                            <div class="text-[14px] mb-[2px] leading-[17px] text-[#7B7A7A]">База резюме</div>
                        </div>
                        <div v-else class="flex flex-row gap-x-[4px] items-center">
                            <UIIcon size="14" name="check" color="#01B00A"></UIIcon>
                            <div class="text-[16px] mb-[2px] leading-[17px] text-[#1F1E1E]">База резюме</div>
                        </div>
                    </div>
                    <div class="font-medium text-[20px] leading-[24px] text-[#0A5CD5]">{{ tarif.price_per_month }} ₽
                    </div>
                    <ButtonNew class="md:!h-[40px] mt-[12px]" type="stroke"
                        @click="router.push(`/employer/lk/tarif?type=paid`)">
                        Активировать</ButtonNew>
                </Sheet>
            </div>
        </div>
        <div v-else-if="type === 'paid' && fetched">
            <div class="choice-tarif-frame">
                <div class="font-medium text-[24px] leading-[29.3px] text-[#0A5CD5] mb-[32px]">Выбор услуги и оплаты
                </div>
                <div class="inline-flex gap-x-[16px] w-full">
                    <div class="flex flex-col w-full">
                        <div class="text-[20px] leading-[24px] mb-3">Тариф или услуга</div>
                        <UISelectNew v-model="tarifForBuy" :options="allTarifs">
                        </UISelectNew>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="text-[20px] leading-[24px] mb-3">Срок дейсвия</div>
                        <UISelectNew v-model="tarifDuration" :options="tarifDurations">
                        </UISelectNew>

                    </div>



                </div>
            </div>
            <Sheet class="h-min !p-[32px] md:!rounded-[24px] mt-[16px]">

                <DetailsForm label="Проверьте правильность данных"></DetailsForm>



                <div class="flex flex-row justify-end mt-[40px]">
                    <div class="w-[237px]">
                        <ButtonNew @click="formsStore.employerProfileDetails.validator.handleSubmit(doBid)">Отправить
                            заявку
                        </ButtonNew>
                    </div>
                </div>
            </Sheet>
        </div>
        <div v-else-if="type === 'acts' && order === null" class="">

            <div v-for="order in orders" class="choice-tarif-frame mb-[20px] cursor-pointer" @click="SetOrder(order)">
                <div class="flex flex-row justify-between w-full">
                    <div class="flex flex-col w-[50%]">
                        <div class="text-[16px] leading-[19.6px] font-medium">Cчет N{{ order.id }}</div>
                        <div class="text-[16px] leading-[19.6px]">{{ order.name }}</div>
                        <div class="text-[16px] leading-[19.6px]">Сумма {{ order.amount }} руб.</div>
                    </div>
                    <div class="flex flex-col w-[50%] items-end">
                        <div class="text-[12px] leading-[14.7px] text-[#7B7A7A] font-light">{{
                            formatDateTime(order.created_at)
                            }}</div>
                        <div class="flex flex-row items-center">
                            <div :class="orderStatusColor(order.status)"></div>
                            <div class="ml-2 text-[12px] leading-[14.7px] font-light">{{ orderStatusLabel(order.status)
                                }}</div>
                        </div>
                        <a @click.stop v-if="order.bill" download class="text-[14px] leading-[17px] text-[#0A5CD5] mt-3"
                            :href="downloadFile(order.bill)">
                            <div class="flex flex-row">
                                <UIIcon name="pdf"></UIIcon>
                                <div class="ml-1">Скачать Cчет N{{ order.id }}</div>
                            </div>
                        </a>
                        <a @click.stop v-if="order.acceptance_certificate"
                            class="text-[14px] leading-[17px] text-[#0A5CD5] mt-3"
                            :href="downloadFile(order.acceptance_certificate)">
                            <div class="flex flex-row">
                                <UIIcon name="pdf"></UIIcon>
                                <div class="ml-1">Скачать Акт N{{ order.id }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-center">
                <UIPagination class="mb-[50px] md:mt-[33px]" v-if="pagination.totalPages > 0"
                    :total-pages="pagination.totalPages" :current-page="pagination.page"
                    @page-changed="pagination.page = $event"></UIPagination>

            </div>

        </div>
        <div v-else-if="type === 'acts' && order !== null">
            <Sheet class="h-min !p-[28px] md:!rounded-[24px]">
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col">
                        <div class="text-[16px] leading-[19.6px] font-medium">Cчет N{{ order.id }}</div>
                        <div class="text-[16px] leading-[19.6px]">{{ order.name }}</div>
                        <div class="text-[16px] leading-[19.6px]">Сумма {{ order.amount }} руб.</div>
                    </div>
                    <div class="text-[12px] leading-[14.7px] text-[#7B7A7A] font-light">{{
                        formatDateTime(order.created_at) }}
                    </div>
                </div>
                <div class="flex flex-row justify-center mt-[32px]">
                    <div v-if="order.status === 'PENDING'" class="flex flex-row items-center">
                        <div class="w-3 h-3 rounded-full bg-[#F0CE0E]"></div>
                        <div class="h-[1px] bg-[#B7B7B7] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#D9D9D9]"></div>
                        <div class="h-[1px] bg-[#B7B7B7] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#D9D9D9]"></div>
                    </div>
                    <div v-else-if="order.status === 'WAITING'" class="flex flex-row items-center">
                        <div class="w-3 h-3 rounded-full bg-[#F0CE0E]"></div>
                        <div class="h-[1px] bg-[#F0CE0E] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#01B00A]"></div>
                        <div class="h-[1px] bg-[#B7B7B7] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#D9D9D9]"></div>
                    </div>
                    <div v-else-if="order.status === 'SUCCESS'" class="flex flex-row items-center">
                        <div class="w-3 h-3 rounded-full bg-[#F0CE0E]"></div>
                        <div class="h-[1px] bg-[#F0CE0E] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#01B00A]"></div>
                        <div class="h-[1px] bg-[#01B00A] w-[200px]"></div>
                        <div class="w-3 h-3 rounded-full bg-[#0A5CD5]"></div>
                    </div>
                </div>
                <div class="flex flex-col mt-[32px]">
                    <div class="flex flex-row items-center">
                        <div :class="orderStatusColor(order.status)"></div>
                        <div class="ml-2">{{ orderStatusLabel(order.status) }}
                        </div>
                    </div>
                    <div>{{ orderStatusDescription(order) }}</div>
                </div>
                <div class="flex flex-col mt-[32px] w-full items-end">
                    <a v-if="order.bill" download class="text-[14px] leading-[17px] text-[#0A5CD5]"
                        :href="downloadFile(order.bill)">
                        <div class="flex flex-row">
                            <UIIcon name="pdf"></UIIcon>
                            <div class="ml-1">Скачать Cчет N{{ order.id }}</div>
                        </div>
                    </a>
                    <a v-if="order.acceptance_certificate" class="text-[14px] leading-[17px] text-[#0A5CD5] mt-2"
                        download :href="downloadFile(order.acceptance_certificate)">
                        <div class="flex flex-row">
                            <UIIcon name="pdf"></UIIcon>
                            <div class="ml-1">Скачать Акт N{{ order.id }}</div>
                        </div>
                    </a>
                </div>
            </Sheet>
            <div class="w-full flex flex-row-reverse mt-[32px] items-center cursor-pointer" @click="order = null">
                <div class="ml-[11px]">Назад</div>
                <UIIcon name="longLeft"></UIIcon>

            </div>
        </div>

        <div v-else-if="type === 'promocodes' && fetched">
            <Sheet>
                <div>Для активации вашей подписки с использованием промокода, пожалуйста, введите код в соответствующее
                    поле
                    ниже.
                    Это позволит вам использовать все возможности нашей платформы
                </div>
                <div class="my-[20px]">
                    <div class="flex gap-[20px] items-end">

                        <div class="mt-[10px] flex-grow">
                            <div class="flex gap-[8px] items-start">
                                <div class="form-label">Промокод
                                </div>
                            </div>
                            <UIInput v-model="promo" id="form-promocode" placeholder="Введите промокод..." type="text">
                            </UIInput>
                        </div>

                        <ButtonNew class="!w-[50%]" @click="activatePromo">Активировать</ButtonNew>
                    </div>
                </div>

            </Sheet>
        </div>


    </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch, onBeforeMount } from "vue";
import DetailsForm from "@/components/forms/DetailsForm.vue";
import { useFetch } from "@/composables/useFetch";
import Select from "@/components/ui/Select.vue";
import Sheet from "@/components/cards/Sheet.vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toast-notification";
import UIPagination from "@/components/ui/UIPagination.vue";
import { useBillingStore } from "@/stores/billingStore";
import TarifCard from "@/components/cards/TarifCard";
import UIIcon from "@/components/ui/UIIcon.vue";
import { formatDateTime, daysUntil, formatDateTimeToDate } from "@/composables/utils";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import { useFormValidator } from "@/composables/useFormValidator";
import { useFormsStore } from "@/stores/formsStore";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import UIInput from "@/components/ui/UIInput";
import UISelectNew from "@/components/ui/UISelectNew";


const promo = ref(null)

const activateTarif = async () => {
    const errorStatuses = [400, 422, 500];
    return await fetch.authRequest(`/account/subscriptions/activate`, {
        method: 'POST',
        body: {
            code: promo.value,
            // tariff: route.params.id,
        }
    }).then((data) => {
        if (errorStatuses.includes(data.status)) {
            toast.error('Ошибка при активации промокода',
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        } else {
            toast.success('Тариф активирован!')
            router.push('/employer/lk/tarif?type=tarif')
        }
        authStore.getMe(true)
    })
}

const activatePromo = async () => {
    if (promo.value.length === 0) {
        toast.error('Промокод не должен быть пустым',
            { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
        )
        return
    }

    await fetch.authRequest(`/promo/validate`, {
        method: 'POST',
        body: {
            code: promo.value,
            // period: currentPeriod.value,
            // tariff: route.params.id,
        }
    }).then((data) => {
        if (data.status === 400) {
            toast.error(data.data,
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        }
        else if (data.status === 422) {
            toast.error('Ошибка валидации',
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        } else {
            // toast.success('Промокод активирован!')
            activateTarif()
            // discountPercent.value = data.percent
            // activePromo.value = promo.value
        }
    })
}

const orderStatusColor = (status) => {
    if (status === 'PENDING')
        return 'w-3 h-3 rounded-full bg-[#F0CE0E]'
    else if (status === 'WAITING')
        return 'w-3 h-3 rounded-full bg-[#01B00A]'
    else if (status === 'SUCCESS')
        return 'w-3 h-3 rounded-full bg-[#0B54BF]'
}

const orderStatusLabel = (status) => {
    if (status === 'PENDING')
        return 'Счет формируется'
    else if (status === 'WAITING')
        return 'Ожидание оплаты'
    else if (status === 'SUCCESS')
        return 'Услуга активирована'
}

const orderStatusDescription = (order) => {
    if (order.status === 'PENDING')
        return 'В течение ближайших 3 рабочих дней счет будет доступен для скачивания'
    else if (order.status === 'WAITING')
        return 'Счет сформирован. После оплаты услуга будет активирована в течние дня.'
    else if (order.status === 'SUCCESS') {
        return `Ваша услуга активна до ${formatDateTimeToDate(order.subscription.date_end)}`
    }

}

const miActive = (path) => {
    if (route.fullPath.includes(path)) {
        return true
    }
    return false
}

const SetOrder = (orderInfo) => {
    order.value = orderInfo
}

const pagination = reactive({
    page: 1,
    limit: 3,
    totalPages: 1,
})

const downloadFile = (id) => {
    return `http://localhost:8000/api/files/${id}/download`
}


const billingStore = useBillingStore();
const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore()
const toast = useToast()
const formsStore = useFormsStore()
const validateFuncs = useValidateFuncs()

const activeTarif = ref(null)
const activeSubscribition = ref(null)
const avaliableTarifs = ref([])


const allTarifs = ref([])
const tarifForBuy = ref(null)

const order = ref(null)

// const detailsForm = ref({})
const employerProfile = ref(null)

const orders = ref([])

formsStore.employerProfileDetails.validator = useFormValidator(
    formsStore.employerProfileDetails.form,
    {
        bank_name: [validateFuncs.isNotEmpty],
        bik: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        kpp: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        correspondent_account: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        current_account: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        ogrn: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        tin: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
        fact_address: [validateFuncs.isNotEmpty],
        legal_address: [validateFuncs.isNotEmpty],
        email: [validateFuncs.isNotEmpty, validateFuncs.isValidEmail],
        phone: [validateFuncs.isNotEmpty, validateFuncs.isValidPhoneNumber],
        contact_person_first_name: [validateFuncs.isNotEmpty],
        contact_person_last_name: [validateFuncs.isNotEmpty],
        company_name: [validateFuncs.isNotEmpty]
    }
)

const tarifDurations = ref([
    {
        title: 'Месяц',
        value: 'month'
    },
    {
        title: '3 месяца',
        value: 'months_3'
    },
    {
        title: '6 месяцев',
        value: 'months_6'
    },
    {
        title: 'Год',
        value: 'year'
    },
])
const tarifDuration = ref('month')

const amount = computed(() => {
    const active = billingStore.plans.items.find(item => item.id === tarifForBuy.value);
    if (active) {
        if (tarifDuration.value === 'month')
            return active.price_per_month
        else if (tarifDuration.value === 'months_3')
            return active.price_per_3_month
        else if (tarifDuration.value === 'months_6')
            return active.price_per_6_month
        else if (tarifDuration.value === 'year')
            return active.price_per_year
        else return '-1'
    } else return '-1'

})

const fetched = ref(false)

watch(() => authStore.me, () => {
    if (authStore.me.user_subscription) {
        activeTarif.value = billingStore.plans.items.find(item => item.id === authStore.me.user_subscription.tariff);
        activeSubscribition.value = authStore.me.user_subscription
        avaliableTarifs.value = avaliableTarifs.value.filter(item => item.id !== authStore.me.user_subscription.tariff);
    }
})

onBeforeMount(() => {
    if (type.value == null) {
        router.push({
            path: route.path,
            query: {
                ...route.query,
                type: 'tarif'
            }
        })
    }

    const meRequest = authStore.getMe(true);
    const billingRequest = billingStore.getTariffs()
    Promise.all([meRequest, billingRequest]).then(() => {


        avaliableTarifs.value = billingStore.plans.items
        allTarifs.value = billingStore.plans.items.map(item => ({
            title: item.name,
            value: item.id
        }))
        tarifForBuy.value = allTarifs.value[0].value


        if (authStore.me.user_subscription) {
            activeTarif.value = billingStore.plans.items.find(item => item.id === authStore.me.user_subscription.tariff);
            activeSubscribition.value = authStore.me.user_subscription
            avaliableTarifs.value = avaliableTarifs.value.filter(item => item.id !== authStore.me.user_subscription.tariff);
        }

        employerProfile.value = authStore.me.employer_profile

        formsStore.employerProfileDetails.form.company_name = employerProfile.value.company_name
        formsStore.employerProfileDetails.form.type = employerProfile.value.type
        formsStore.employerProfileDetails.form.contact_person_first_name = employerProfile.value.contact_person_first_name
        formsStore.employerProfileDetails.form.contact_person_last_name = employerProfile.value.contact_person_last_name
        formsStore.employerProfileDetails.form.phone = employerProfile.value.phone
        formsStore.employerProfileDetails.form.email = employerProfile.value.email
        formsStore.employerProfileDetails.form.legal_address = employerProfile.value.legal_address
        formsStore.employerProfileDetails.form.fact_address = employerProfile.value.fact_address
        formsStore.employerProfileDetails.form.tin = employerProfile.value.tin
        formsStore.employerProfileDetails.form.ogrn = employerProfile.value.ogrn
        formsStore.employerProfileDetails.form.current_account = employerProfile.value.current_account
        formsStore.employerProfileDetails.form.correspondent_account = employerProfile.value.correspondent_account
        formsStore.employerProfileDetails.form.kpp = employerProfile.value.kpp
        formsStore.employerProfileDetails.form.bik = employerProfile.value.bik
        formsStore.employerProfileDetails.form.bank_name = employerProfile.value.bank_name

        fetched.value = true

        getOrders()


    });

})

const getOrders = () => {
    let queryParams = {}
    queryParams.limit = pagination.limit
    queryParams.offset = (pagination.page - 1) * pagination.limit
    fetch.authRequest(`account/orders/?offset=${queryParams.offset}&limit=${queryParams.limit}`).then((data) => {
        orders.value = data.results
        pagination.totalPages = Math.ceil(data.count / pagination.limit)
    })
}


watch(() => pagination.page, (newValue) => {
    getOrders();
});

const doBid = () => {
    fetch.authRequest(`account/employer/`, {
        method: 'PATCH',
        body: {
            ...formsStore.employerProfileDetails.form
        }
    }).then(response => {
        fetch.authRequest(`account/orders/`, {
            method: 'POST',
            body: {
                amount: amount.value,
                tariff: tarifForBuy.value,
                name: `Активация тарифа ${tarifForBuyName.value}`,
                duration: tarifDuration.value,
                ...formsStore.employerProfileDetails.form
            }
        }).then(response => {
            getOrders();
            toast.success('Заявка отправлена')
            router.push('/employer/lk/tarif?type=acts')
        })

    })
}


const type = computed(() => {
    return route.query.type
})

const tarifForBuyName = computed(() => {
    const active = billingStore.plans.items.find(item => item.id === tarifForBuy.value);
    return active.name
})


watch(() => type.value, (v) => {
    if (!v) {
        router.push({
            path: route.path,
            query: {
                ...route.query,
                type: 'tarif'
            }
        })
        return
    }
})


</script>

<style scoped>
.active-tarif-frame {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    gap: 65px;

    width: 591px;

    /* White */
    background: #FFFFFF;
    /* Blue */
    border: 1px solid #0A5CD5;
    border-radius: 12px;

    /* Inside auto layout */
}

.choice-tarif-frame {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;

    width: 655px;

    /* White */
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    /* Blue */

    border-radius: 24px;

    /* Inside auto layout */
}

.choice-tarif-frame:hover {
    border: 1px solid #0A5CD5;
}
</style>