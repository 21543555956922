<template>

  <label class="custom-checkbox relative">
    <input
      type="checkbox"
      v-model="isChecked"
      class="checkbox-input"
    />
    <span :class="{ 'checkbox-checked': isChecked }" class="checkbox  rounded-[3.54px]"></span>
    <UIIcon v-if="isChecked" class="absolute" size="24" name="checked" stroke="white"></UIIcon>
    <div class="text-[#1F1E1E] font-light text-[1rem] leading-[1.25rem]">
      <slot></slot>
    </div>

  </label>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import UIIcon from "@/components/ui/UIIcon";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const isChecked = ref(props.modelValue)

const toggle = () => {
  emit('update:modelValue', isChecked.value)
}


// let skip = true
watch(() => props.modelValue, (newValue) => {
  isChecked.value = newValue
  // skip = true
})
watch(() => isChecked.value, (newValue, oldValue) => {
  // if (skip) {
  //   skip = false
  //   return
  // }
  if (newValue === props.modelValue) {
    return
  }
  emit('update:modelValue', newValue)
})
</script>

<style scoped>
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid #0A5CD5;
  background-color: white;
  position: relative;
  margin-right: 8px;
  transition: background-color 0.3s;
}

/*.checkbox::after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: 4px;*/
/*  top: 2px;*/
/*  width: 6px;*/
/*  height: 12px;*/
/*  border: solid white;*/
/*  border-width: 0 2px 2px 0;*/
/*  transform: rotate(45deg);*/
/*  opacity: 0;*/
/*  transition: opacity 0.3s;*/
/*}*/

.checkbox-checked {
  background-color: #0A5CD5;
}

.checkbox-checked::after {
  opacity: 1;
}
</style>