<template>
  <div class="z-50 fixed top-0 left-0 w-screen h-screen bg-transparent text-black">
    <div class="absolute top-0 left-0 w-full h-full bg-[#0A5CD5] bg-opacity-10" @click="close"></div>

    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center pointer-events-none">
      <!-- <sheet
        class="w-[337px] md:w-[781px] h-min min-h-[100px] md:p-[56px_64px] m-[17px] md:m-0 relative pointer-events-auto"> -->
        <sheet
        :class="computedClasses">
        <slot name="close">
          <div class="absolute top-[20px] right-[20px] cursor-pointer" @click="close">
            <img src="/icons/x-lg-32-blue.svg">
          </div>
        </slot>
        <slot>
          Содержимое попапа
        </slot>
      </sheet>
    </div>

  </div>
  <!--    <div @click="closePopup" class="z-10 absolute top-0 left-0 bg-[#0126A9] h-[1000px] w-screen opacity-20">-->

  <!--    </div>-->
  <!--    <div :class="computedClasses">-->
  <!--        <div class="flex flex-row justify-between align center h-[1.5rem] mb-[1.93rem]">-->
  <!--            <button class="flex items-center justify-center p-0" @click="closePopup">-->
  <!--                <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <!--                    <path d="M18 6L6 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />-->
  <!--                    <path d="M6 6L18 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />-->
  <!--                </svg>-->
  <!--            </button>-->
  <!--            <span class="text-[1.12rem] text-[#000000] mb-[0.87rem]  font-bold">{{ title }}</span>-->
  <!--            <Button type="reset" @click="resetFilters">Сбросить</Button>-->
  <!--        </div>-->
  <!--        <div class="overflow-y-auto max-h-[70vh]">-->
  <!--            <slot></slot>-->
  <!--        </div>-->
  <!--        -->
  <!--    </div>-->
</template>

<script setup>
import {computed} from 'vue';
import {defineProps, defineEmits} from 'vue';
import Button from "@/components/ui/Button";
import Sheet from "@/components/cards/Sheet";

// const standardClass = "fixed bottom-0 bg-[#FFFFFF] rounded-t-[1rem] p-[1rem] z-10 w-screen max-h-[80vh]";
const standardClass = "w-[337px] md:w-[781px] h-min min-h-[100px] md:p-[56px_64px] m-[17px] md:m-0 relative pointer-events-auto "
const props = defineProps({
  class: {
    type: String,
    default: ''
  },
  reset: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
});

const computedClasses = computed(() => {
  return standardClass + props.class;
});
const emit = defineEmits(['close']);
// const resetFilters = () => {
//   emit('resetFilters'); // Эмитим событие сброса фильтров
// };
const close = () => {
  emit('close');
};

</script>

<style scoped></style>