<template>
    <button :class="computedClasses">
            <div v-if="props.add" class="mr-[0.4rem] md:mr-[6.5px]">
            <Icon v-if="props.type === 'blue'" name="plus-white"></Icon>
            <Icon v-else name="plus-blue"></Icon>
            </div>
            <slot></slot>
    </button>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';
import Icon from "@/components/ui/Icon";

// Определяем пропсы компонента
const props = defineProps({
    type: {
        type: String,
        default: 'blue'
    },
    class: {
      type: String,
      default: ''
    },
    add: {
        default: false
    }
});

const standardClass = "h-[2.8rem] md:h-[48px] md:rounded-[12px] rounded-[0.75rem] flex items-center justify-center font-normal text-[0.85rem] md:text-[16px] leading-[1.2rem] md:leading-[19.6px] w-full "

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
    if (props.type == 'blue') {
        return  standardClass + "bg-[#0A5CD5] hover:bg-[#0B54BF] text-[#FFFFFF] " + props.class
    }
    else if (props.type == 'stroke') {
        return standardClass + "bg-[#FFFFFF] hover:bg-[#0A5CD5] hover:text-[#FFFFFF] text-[#0A5CD5] border-[1px] border-[#0A5CD5] " + props.class
    }
    else if (props.type == 'stroke-disabled') {
        return standardClass + "bg-transparent text-[#7B7A7A] border-[1px] border-[#7B7A7A] cursor-default " + props.class
    }
    else if (props.type == 'stroke-red') {
        return standardClass + "bg-[#FFFFFF] hover:bg-[#FF3B30] hover:text-[#FFFFFF] text-[#FF3B30] border-[1px] border-[#FF3B30] " + props.class
    }
    if (props.type == 'blue-medium') {
        return  standardClass + "bg-[#0A5CD5] hover:bg-[#0B54BF] !font-medium text-[#FFFFFF] " + props.class
    }
    else if (props.type == 'stroke-medium') {
        return standardClass + "bg-[#FFFFFF] hover:bg-[#0A5CD5] !font-medium hover:text-[#FFFFFF] text-[#0A5CD5] border-[1px] border-[#0A5CD5] " + props.class
    }
    else if (props.type == 'stroke2') {
        return standardClass + "bg-[#FFFFFF] hover:!text-[1.47rem] hover:md:!text-[20px] hover:md:!font-bold text-[#0A5CD5] border-[1px] border-[#0A5CD5] " + props.class
    }
    else if (props.type == 'white') {
        return standardClass + "bg-[#FFFFFF] hover:bg-[#0A5CD5] hover:text-[#FFFFFF] text-[#0A5CD5] border-none " + props.class
    }
    else if (props.type == 'nocolor') {
        return standardClass + "hover:bg-[#0A5CD5] hover:text-[#FFFFFF] text-[#0A5CD5] border-[1px] border-[#0A5CD5] " + props.class
    }
    else {
        return "" + props.class
    }
});
</script>

<style scoped>

</style>