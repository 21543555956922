// composables/useFormValidator.js
import {ref, reactive, watch, onMounted} from 'vue';

export function useFormValidator(reactiveFormObject, validations, passive = false) {
  const values = reactiveFormObject;
  const errors = reactive({});
  const isValid = ref(true);

  const validateField = (field) => {
    const value = values[field];
    const validationRules = validations[field];
    for (const validationRule of validationRules) {
      if (validationRule) {
        const error = validationRule(value);
        if (error) {
          errors[field] = error
          return
        }

      }
    }
    errors[field] = null
  };

  const validateAll = () => {
    isValid.value = true;
    for (const field in validations) {
      validateField(field);
      if (errors[field]) {
        isValid.value = false;
      }
    }
    return isValid.value;
  };
  if (passive) {
    watch(values, () => { // Отслеживаем в фоне изменения

      validateAll()
    })

    onMounted(() => { // Отслеживаем изменения с самого начала
      validateAll()
    })
  }




  const handleSubmit = (callback) => {
    if (validateAll()) {
      callback(values);
    }
  };

  return {
    values,
    errors,
    isValid,
    validateField,
    validateAll,
    handleSubmit,
  };
}