<template>
    <div class="wj-checkbox-list">
        <div class="wj-checkbox-container" v-bind:class="{ 'scrollable': !showAll && props.items.length > 8 }">
            <div v-for="(item, index) in displayedItems" :key="index" class="checkbox-item flex flex-row items-center">
                <Checkbox :id="generateId(index)" :title="item" :model-value="checkedItems[index]"
                    @update:modelValue="checkedItems[index] = $event" />
            </div>
        </div>
        <div @click="toggleShowAll" v-if="props.items.length > 5"
            class="text-[14px] mt-[16px] !text-[#0A5CD5] font-semi-bold underline cursor-pointer">
            {{ showAll ? 'Скрыть' : 'Еще' }}
        </div>
    </div>
</template>

<script setup>
import Checkbox from './Checkbox.vue';
import { ref, reactive, watch, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});

const showAll = ref(false);
const checkedItems = ref([...props.modelValue]);

const displayedItems = computed(() => {
    if (showAll.value === false) {
        return props.items.slice(0, 5);
    } else {
        return props.items;
    }
});

const emit = defineEmits(['update:modelValue']);

watch(() => props.modelValue, () => {
    checkedItems.value = props.modelValue
})

watch(checkedItems, (newValue) => {
    emit('update:modelValue', newValue);
}, { deep: true });

const toggleShowAll = () => {
    showAll.value = !showAll.value;
}

const generateId = (index) => {
    return `checkbox-${index}`;
}
</script>

<style scoped>
.wj-checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.wj-checkbox-container {
    max-height: 200px;
    /* Ограничение высоты контейнера */
    overflow-y: auto;
    /* Добавление прокрутки по вертикали */
}



@media (max-width: 768px) {
    .wj-checkbox-container {
        max-height: 300px;
        /* Ограничение высоты контейнера */
        overflow-y: auto;
        /* Добавление прокрутки по вертикали */
    }
}

@media (max-width: 768px) {
    .scrollable {
        max-height: 180px;
        /* Высота для прокрутки, если items больше 8 и showAll = false */
    }
}

@media (min-width: 769px) {
    .scrollable {
        max-height: 120px;
        /* Высота для прокрутки, если items больше 8 и showAll = false */
    }
}



@media (max-width: 768px) {
    .checkbox-item {
        height: 48px;
        /* Стили для элементов чекбокса, если необходимо */
    }
}

/* Добавьте стили для вашего toggle-button и других элементов, если это необходимо */
</style>
