<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14 5V14.5C14 15.6046 13.1046 16.5 12 16.5H11V15.5H12C12.5523 15.5 13 15.0523 13 14.5V5H11C10.1716 5 9.5 4.32843 9.5 3.5V1.5H4C3.44772 1.5 3 1.94772 3 2.5V11.5H2V2.5C2 1.39543 2.89543 0.5 4 0.5H9.5L14 5ZM1.59961 12.35H0V16.349H0.791016V15.0072H1.59375C1.88086 15.0072 2.125 14.9496 2.32617 14.8344C2.5293 14.7172 2.68359 14.559 2.78906 14.3598C2.89648 14.1586 2.9502 13.933 2.9502 13.683C2.9502 13.433 2.89746 13.2074 2.79199 13.0063C2.68652 12.8051 2.5332 12.6459 2.33203 12.5287C2.13281 12.4096 1.88867 12.35 1.59961 12.35ZM2.14453 13.683C2.14453 13.8315 2.11621 13.9584 2.05957 14.0639C2.00488 14.1674 1.92578 14.2475 1.82227 14.3041C1.71875 14.3588 1.59375 14.3861 1.44727 14.3861H0.788086V12.9799H1.44727C1.66602 12.9799 1.83691 13.0404 1.95996 13.1615C2.08301 13.2826 2.14453 13.4565 2.14453 13.683ZM3.36211 12.35V16.349H4.82109C5.22344 16.349 5.55645 16.2699 5.82012 16.1117C6.08574 15.9535 6.28398 15.724 6.41484 15.4232C6.5457 15.1225 6.61113 14.7611 6.61113 14.3393C6.61113 13.9193 6.5457 13.5609 6.41484 13.2641C6.28594 12.9672 6.08965 12.7406 5.82598 12.5844C5.5623 12.4281 5.22734 12.35 4.82109 12.35H3.36211ZM4.15312 12.9945H4.71562C4.96367 12.9945 5.1668 13.0453 5.325 13.1469C5.48516 13.2484 5.60332 13.3998 5.67949 13.601C5.75762 13.8022 5.79668 14.0531 5.79668 14.3539C5.79668 14.5805 5.77422 14.7777 5.7293 14.9457C5.68633 15.1137 5.6209 15.2543 5.53301 15.3676C5.44707 15.4789 5.33574 15.5629 5.19902 15.6195C5.0623 15.6742 4.90117 15.7016 4.71562 15.7016H4.15312V12.9945ZM7.89609 14.7582V16.349H7.10508V12.35H9.65391V13.0033H7.89609V14.1195H9.50156V14.7582H7.89609Z"
            fill="#0A5CD5" />
    </svg>




</template>

<script setup>

</script>

<style scoped></style>