<!-- UIMenu.vue -->
<template>
  <UIDropdown ref="dropdown">
    <template #trigger>
      <button class="px-4 py-2 bg-blue-500 text-white rounded">
        Меню
      </button>
    </template>

    <template #dropdown>
      <ul class="bg-white border rounded shadow-lg">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
          @click="handleSelect(item)"
        >
          {{ item }}
        </li>
      </ul>
    </template>
  </UIDropdown>
</template>

<script setup>
import { ref } from 'vue'
import UIDropdown from './UIDropdown.vue'

// Пример элементов меню
const items = ref(['Пункт 1', 'Пункт 2', 'Пункт 3'])

const dropdown = ref(null)

const handleSelect = (item) => {
  dropdown.value.hide()
}
</script>

<style scoped>
/* Добавьте стили по необходимости */
</style>