<template>
<!--    <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>-->
  <DefaultLayout>

    <authTemplateCard>
<!--        <div class="flex flex-row w-full">-->
<!--            <div class="flex flex-row items-center relative w-full md:mb-[32px] mb-[2.9rem]">-->
<!--                <div class="absolute left-0">-->
<!--                    <Icon @click="router.back()" v-if="isMobile" name="chevron-left-back-mobile"></Icon>-->
<!--                </div>-->
<!--                <div class="flex flex-row justify-center w-full">-->
<!--                    <div v-if="isMobile" class="registration-header">Я соискатель</div>-->
<!--                    <div v-else class="registration-header">Регистрация</div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
      <UIPageHeader class="md:hidden mb-[32px]" title="Я соискатель">
        <template #search>
          <div></div>
        </template>
      </UIPageHeader>
      <div class="hidden md:block mb-[32px] registration-header ">Регистрация</div>

        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="text"  :errorText="registrationFormErrors.first_name" v-model="registrationForm.first_name" placeholder="Имя"></Input>
        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="text"  :errorText="registrationFormErrors.last_name" v-model="registrationForm.last_name" placeholder="Фамилия"></Input>
        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="email" placeholder="Электронная почта" :errorText="registrationFormErrors.email" v-model="registrationForm.email"></Input>
        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="tel" placeholder="Телефон" :errorText="registrationFormErrors.phone_number" v-model="registrationForm.phone_number"></Input>
        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="password" placeholder="Пароль" :errorText="registrationFormErrors.password" v-model="registrationForm.password"></Input>
        <Input inputClass=" md:!p-[16px_16px]" class="mb-[12px]" type="password" placeholder="Повторите пароль" :errorText="registrationFormErrors.repeat_password" v-model="registrationForm.repeat_password"></Input>
        <ButtonNew type="blue-medium" @click="registration" class="md:mt-[32px] mt-[1.7rem]">Зарегистрироваться</ButtonNew>
        <div class="flex flex-row justify-between items-center w-[14.37rem] md:w-[260px] md:h-[44px] md:mt-[32px] h-[2.7rem] mt-[2rem]">
            <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem]">
                У меня есть аккаунт.
            </div>
            <div @click="router.push('/login')"
                class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer">
                Войти
            </div>
        </div>

        <SupportCardNew></SupportCardNew>

    </authTemplateCard>
  </DefaultLayout>

<!--  <FooterNew></FooterNew>-->

</template>

<script setup>
import ButtonNew from '@/components/ui/ButtonNew.vue';
import authTemplateCard from '@/components/cards/authTemplateCard.vue';
import Icon from '@/components/ui/Icon.vue';
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import Input from '@/components/ui/Input.vue';
import router from '@/router';
import { useFetch } from '@/composables/useFetch';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";

const { anonRequest, authRequest } = useFetch();

const registrationError = ref(false)

const registrationForm = reactive({
    type: 'employee',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    repeat_password: '',
})

const registrationFormErrors = reactive({
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    password: null,
    repeat_password: null,
})

const fetchSignup = async () => {

    const url = `/signup/`;
    const response = await anonRequest(url, {
        method: 'POST',
        body: registrationForm
    });
    return response
};

const registration = () => {
    // Обнуляем все ошибки перед проверкой
    Object.entries(registrationFormErrors).forEach(([key, value]) => {
        registrationFormErrors[key] = null
    });
    registrationError.value = false

    // теперь просто проверяем все поля на заполненность
    Object.entries(registrationForm).forEach(([key, value]) => {
        registrationForm[key] = value.trim()
        if (!isNotEmpty(value)) {
            registrationFormErrors[key] = 'Поле должно быть заполненно'
            registrationError.value = true
        } else {
            registrationFormErrors[key] = null
        }
    });
    
    // а далее специфические правила
    if (!isValidPassword(registrationForm.password)) {
        registrationFormErrors.password = 'Ведите пароль не менее 6 символов'
        registrationError.value = true
    }
    if (!isValidPassword(registrationForm.repeat_password)) {
        registrationFormErrors.repeat_password = 'Ведите пароль не менее 6 символов'
        registrationError.value = true
    }
    if (!isValidEmail(registrationForm.email)) {
        registrationFormErrors.email = 'Неверный email'
        registrationError.value = true
    }
    else {
        fetchEmailCheck().then(result => {
                if (result === true) {
                    registrationError.value = true
                    registrationFormErrors.email = 'Email уже зарегистрирован'
                }
        })
    }
    if (!isValidPhoneNumber(registrationForm.phone_number)) {
        registrationFormErrors.phone_number = 'Неверный номер телефона'
        registrationError.value = true
    }
    if (!isMatchingPasswords(registrationForm.password, registrationForm.repeat_password)) {
        registrationFormErrors.repeat_password = 'Пароли не совпадают'
        registrationFormErrors.password = 'Пароли не совпадают'
        registrationError.value = true
    }

    // если нет ошибок делаем запрос
    // TODO обработка ошибки если что то не так
    if (registrationError.value === false) {
        fetchSignup().then(result => {
                if (!result.status) {
                    router.push('/login')
                }
            })
    }
}

const fetchEmailCheck = async () => {
        const url = `/auth/email-check/`;
        const responseBody = {
                "email": registrationForm.email,
        }

        const response = await anonRequest(url, {
            method: 'POST',
            body: responseBody
        });
        return response

};

const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^(7|\+7|8)\d{10}$/;
    return regex.test(phoneNumber);
}

// В текущем варианте проверяет только на кол-во символов
const isValidPassword = (password) => {
    return password.length > 5
}

const isMatchingPasswords = (password, repeatPassword) => {
    return password === repeatPassword
}

const isNotEmpty = (string) => {
    return string.length > 0;
}

const route = useRoute();

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const showPopup = ref(false);

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>