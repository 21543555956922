import {ofetch} from "ofetch";
import {useRouter} from "vue-router/dist/vue-router";
import {useRoute} from "vue-router";
import {useFetch} from "@/composables/useFetch";
import {onMounted, onUnmounted, ref} from "vue";

export const useMobile = () => {


  const isMobile = ref(window.innerWidth < 768); // Инициализируем реактивную переменную

  const checkScreenWidth = () => {
    isMobile.value = window.innerWidth < 768; // Обновляем значение на основе текущей ширины
  };

  onMounted(() => {
    window.addEventListener('resize', checkScreenWidth); // Добавляем слушатель на событие resize
  });

  onUnmounted(() => {
    window.removeEventListener('resize', checkScreenWidth); // Убираем слушатель при размонтировании
  });



  return {
    isMobile,
  };
};