<template>
    <div :class="computedClasses">
      <slot></slot>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import { defineProps } from 'vue';
  
  const standardClass = "bg-[#FFFFFF] rounded-[0.85rem] p-[1.7rem] md:p-[24px] md:rounded-[12px] w-full h-full";
  const props = defineProps({
    class: {
      type: String,
      default: ''
    },
  });
  
  const computedClasses = computed(() => {
    return standardClass + ' ' + props.class;
  });
  </script>
  
  <style scoped>
  /* Дополнительные стили, если нужны */

  </style>