<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.4301 8.85898C11.2629 9.34445 8.9302 10.3492 5.43195 11.8734C4.86389 12.0993 4.56632 12.3203 4.53923 12.5363C4.49345 12.9015 4.95077 13.0453 5.57354 13.2412C5.65825 13.2678 5.74602 13.2954 5.836 13.3247C6.4487 13.5238 7.27289 13.7568 7.70135 13.7661C8.09 13.7745 8.52379 13.6142 9.0027 13.2854C12.2712 11.0791 13.9584 9.96386 14.0644 9.93982C14.1391 9.92286 14.2427 9.90153 14.3128 9.9639C14.383 10.0263 14.3761 10.1444 14.3687 10.1761C14.3234 10.3692 12.5282 12.0381 11.5992 12.9018C11.3096 13.1711 11.1041 13.3621 11.0621 13.4057C10.9681 13.5034 10.8722 13.5958 10.78 13.6846C10.2108 14.2334 9.78397 14.6449 10.8037 15.3168C11.2937 15.6397 11.6858 15.9068 12.077 16.1732C12.5042 16.4641 12.9303 16.7543 13.4817 17.1157C13.6221 17.2078 13.7563 17.3034 13.8869 17.3966C14.3841 17.751 14.8308 18.0694 15.3826 18.0187C15.7033 17.9892 16.0345 17.6876 16.2027 16.7884C16.6003 14.6632 17.3817 10.0585 17.5623 8.16103C17.5781 7.99479 17.5582 7.78203 17.5422 7.68863C17.5262 7.59524 17.4928 7.46217 17.3714 7.36366C17.2277 7.247 17.0057 7.2224 16.9064 7.22414C16.4551 7.2321 15.7626 7.47288 12.4301 8.85898Z" fill="#0088CC"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>