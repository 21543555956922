<template>
    <div class="flex flex-col gap-y-[40px]">
        <div class="flex flex-col justify-between gap-y-[8px]">
            <div class="font-medium text-[24px] leading-[29px]">{{ props.label }}</div>
            <div class="flex gap-[12px] items-center">

                <UIIcon size="10" name="asterisk"></UIIcon>
                <div class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Поля обязательные
                    для
                    заполнения
                </div>
            </div>
        </div>

        <div class="inline-flex w-full justify-between">
            <div class="flex flex-row-reverse justify-end w-[50%]">
                Юридическое лицо
                <UIRadioButton v-model="legalType" value="legal">
                </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end w-[50%]">
                Физическое лицо
                <UIRadioButton v-model="legalType" value="nelegal" style="pointer-events: none;">
                </UIRadioButton>
            </div>
        </div>

        <div class="inline-flex w-full">
            <div class="flex flex-col w-[140px] mr-[16px]">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Вид
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UISelect :options="types" v-model="formsStore.employerProfileDetails.form.type">
                    <template #prepend-dropdown>
                        <div class="w-[140px]"></div>
                    </template>
                </UISelect>
                <!-- <Select :options="types" v-model="form.type" @update:modelValue="updateData('type', $event)" :filterable="false" class="mb-[12px] mt-[-3px]"
                    inputClass="!h-[40px]" placeholder="Вид юрлица">
                </Select> -->
            </div>
            <div class="flex flex-col w-[435px]">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Название комапании
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.company_name" id="form-name"
                    placeholder="Введите название компании"
                    :errorText="formsStore.employerProfileDetails.validator.errors.company_name">
                </UIInput>
            </div>
        </div>
        <div class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Контактное лицо
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.contact_person_first_name" id="form-name"
                    placeholder="Имя"
                    :errorText="formsStore.employerProfileDetails.validator.errors.contact_person_first_name">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="h-[34px]"></div>
                <UIInput v-model="formsStore.employerProfileDetails.form.contact_person_last_name" id="form-name"
                    placeholder="Фамилия"
                    :errorText="formsStore.employerProfileDetails.validator.errors.contact_person_last_name">
                </UIInput>
            </div>
        </div>
        <div class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Телефон
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.phone" id="form-name" placeholder="+7"
                    :errorText="formsStore.employerProfileDetails.validator.errors.phone">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Электронная почта
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.email" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.email">
                </UIInput>
            </div>
        </div>
        <div class="flex flex-col w-full">
            <div class="flex gap-[8px] items-start">
                <div class="form-label">Юридический адрес
                </div>
                <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="formsStore.employerProfileDetails.form.legal_address" id="form-name" placeholder=""
                :errorText="formsStore.employerProfileDetails.validator.errors.legal_address">
            </UIInput>
        </div>
        <div class="flex flex-col w-full">
            <div class="flex gap-[8px] items-start">
                <div class="form-label">Фактический адрес
                </div>
                <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="formsStore.employerProfileDetails.form.fact_address" id="form-name" placeholder=""
                :errorText="formsStore.employerProfileDetails.validator.errors.fact_address">
            </UIInput>
        </div>
        <div class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">ИНН
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.tin" id="form-name" placeholder="10 цифр"
                    :errorText="formsStore.employerProfileDetails.validator.errors.tin">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">ОГРН
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.ogrn" id="form-name" placeholder="10 цифр"
                    :errorText="formsStore.employerProfileDetails.validator.errors.ogrn">
                </UIInput>
            </div>
        </div>
        <div class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Расчетный счет
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.current_account" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.current_account">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Корреспондентский счет
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.correspondent_account" id="form-name"
                    placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.correspondent_account">
                </UIInput>
            </div>
        </div>
        <div v-if="formsStore.employerProfileDetails.form.type !== 'ИП'" class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">КПП
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.kpp" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.kpp">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">БИК
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.bik" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.bik">
                </UIInput>
            </div>
        </div>
        <div v-else class="flex flex-row gap-x-[8px] w-full">
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">БИК
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.bik" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.bik">
                </UIInput>
            </div>
            <div class="flex flex-col w-full">
                <div class="flex gap-[8px] items-start">
                    <div class="form-label">Название банка
                    </div>
                    <UIIcon size="10" name="asterisk"></UIIcon>
                </div>
                <UIInput v-model="formsStore.employerProfileDetails.form.bank_name" id="form-name" placeholder=""
                    :errorText="formsStore.employerProfileDetails.validator.errors.bank_name">
                </UIInput>
            </div>
        </div>
        <div v-if="formsStore.employerProfileDetails.form.type !== 'ИП'" class="flex flex-col w-[50%]">
            <div class="flex gap-[8px] items-start">
                <div class="form-label">Название банка
                </div>
                <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="formsStore.employerProfileDetails.form.bank_name" id="form-name" placeholder=""
                :errorText="formsStore.employerProfileDetails.validator.errors.bank_name">
            </UIInput>
        </div>
    </div>
</template>

<script setup>
import ButtonNew from "@/components/ui/ButtonNew";
import UIIcon from "@/components/ui/UIIcon";
import { ref, watch, reactive, onMounted, onBeforeMount, computed, defineEmits } from "vue";
import UIInput from "@/components/ui/UIInput";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "../ui/UISelect.vue";
import UserProfileField from "@/components/ui/UserProfileField.vue";
import Select from "@/components/ui/Select.vue";
import { useFetch } from "@/composables/useFetch";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { data } from "autoprefixer";
import { useFormsStore } from "@/stores/formsStore";
import { useValidateFuncs } from "@/composables/useValidateFuncs";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    label: {
        type: String,
        default: 'Данные комапнии'
    }
});
const formsStore = useFormsStore()
const legalType = ref('legal')

const types = ref([
    { title: 'ООО', value: 'ООО' },
    { title: 'ОАО', value: 'ОАО' },
    { title: 'ЗАО', value: 'ЗАО' },
    { title: 'ПАО', value: 'ПАО' },
    { title: 'АО', value: 'АО' },
    { title: 'УП', value: 'УП' },
    { title: 'ГУ', value: 'ГУ' },
    { title: 'ТОО', value: 'ТОО' },
    { title: 'ИП', value: 'ИП' },
    { title: 'НО', value: 'НО' }
])

const emit = defineEmits(['update:modelValue'])

onMounted (() => {
    if (formsStore.employerProfileDetails.form.type === 'ИП' && (formsStore.employerProfileDetails.form.kpp === null || formsStore.employerProfileDetails.form.kpp === ''))
            formsStore.employerProfileDetails.form.kpp = '0'
})

const form = ref({ ...props.modelValue })

watch(
    () => formsStore.employerProfileDetails.form.type,
    (newValue) => {
        if (newValue === 'ИП' && (formsStore.employerProfileDetails.form.kpp === null || formsStore.employerProfileDetails.form.kpp === ''))
            formsStore.employerProfileDetails.form.kpp = '0'
        // Ваши действия при изменении bank_name
    }
);

const updateData = () => {
    //
    // form.value[key] = value; // Обновляем локальное состояние формы
    emit('update:modelValue', form.value); // Эмитим изменения обратно
};

// Следите за изменениями props.modelValue и обновляйте локальное состояние
watch(() => props.modelValue, (newValue) => {
    form.value = newValue;
});
</script>

<style scoped></style>