<template>
  <!--  <HeaderNew></HeaderNew>-->
  <DefaultLayout>


    <div class="flex flex-row bg-[#D4D3D3] justify-center">


      <div class="flex flex-col w-[992px]">

        <div>
          Dropdown:
          <UIDropdown ref="dropdownRef" >
            <template #dropdown="data">
              Slot data = {{data}} <br>
              Trigger content: {{data.triggerElement.innerHTML}}
            </template>

          </UIDropdown>

          <br>

          UIMenu: <br>
          <UIMenu></UIMenu>

          <br>

          UISelectNew: <br>
          Selected: {{selected}}<br>
          <UISelectNew
            v-model="selected"
            :options="options"
          ></UISelectNew>


          <br>

          UISelectNew: <br>
          Selected: {{selected}}<br>
          <UISearchSelect
            v-model="selected"
            :options="options"
          ></UISearchSelect>

          <div class="p-1 flex gap-[10px]">
            <ButtonNew @click="dropdownOpen">Open</ButtonNew>
            <ButtonNew @click="dropdownHide">Hide</ButtonNew>
          </div>

        </div>

        <!-- <TestPopup v-if="showPopup" @closePopup="togglePopup" title="Фильтры"></TestPopup> -->
        <div class="flex flex-col gap-y-4 mt-20">

          <ButtonNew type="blue">Найти</ButtonNew>
          <ButtonNew type="stroke">Найти</ButtonNew>
          <ButtonNew type="white">Найтиs</ButtonNew>

          <ButtonNew type="blue" add="true">Найти</ButtonNew>
          <ButtonNew type="stroke" add="true">Найти</ButtonNew>
          <ButtonNew type="white" add="true">Найти</ButtonNew>

          <ButtonNew type="stroke2">Найти</ButtonNew>
        </div>

        <div class="flex flex-row gap-y-4 gap-x-2 mt-20">

          <BoolIcon></BoolIcon>
          <BoolIcon checked="true"></BoolIcon>
          <Icon name="eye"></Icon>
          <Icon name="eyeNovision"></Icon>
          <Icon name="mail"></Icon>
          <Icon name="phone"></Icon>
          <Icon name="telegram"></Icon>
          <Icon name="whatsapp"></Icon>
          <Icon name="chevron-down"></Icon>
          <Icon name="chevron-up"></Icon>
          <BoolIcon name="checkbox"></BoolIcon>
          <BoolIcon name="checkbox" checked="true"></BoolIcon>

          <Icon name="arrow-left"></Icon>
          <Icon name="check"></Icon>
        </div>
        <div class="flex flex-row gap-y-4 gap-x-2 mt-20">
          <div class="w-[480px]" v-for="(item, index) in vacancies" :key="index">
            <VacancyCardNew :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from :priceTo=item.price_to
                            :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                            :experience=item.experience
                            v-model="vacanciesIdsWithFavorite[item.id]">
            </VacancyCardNew>
          </div>

        </div>
        <div class="flex flex-col gap-y-4 gap-x-2 mt-20">
        </div>


      </div>
    </div>
  </DefaultLayout>
  <!--  <FooterNew></FooterNew>-->
</template>

<script setup>
import ButtonNew from '@/components/ui/ButtonNew.vue'
import BoolIcon from '@/components/ui/BoolIcon.vue';
import Icon from '@/components/ui/Icon.vue';
import VacancyCardNew from '@/components/cards/VacancyCardNew.vue';
import {ref} from 'vue';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIDropdown from "@/components/ui/UIDropdown";
import UIMenu from "@/components/ui/UIMenu";
import UISelectNew from "@/components/ui/UISelectNew";
import UISearchSelect from "@/components/ui/UISearchSelect";


const dropdownRef = ref(null)

const dropdownOpen = () => {
  dropdownRef.value.open()
}

const dropdownHide = () => {
  dropdownRef.value.hide()
}

const selected = ref(null)

const options = [
  { label: 'Опция 1', value: '1' },
  { label: 'Опция 2', value: '2' },
  { label: 'Опция 3', value: '3' },
]

const vacancies = [
  {
    "id": 7,
    "currency_display_name": "руб.",
    "employer_name": "ЗАО РАМОЗ коспания",
    "views": 0,
    "responses_count": 0,
    "created_at": "2024-07-25T13:56:53.498748+03:00",
    "updated_at": "2024-10-10T14:22:59.492312+03:00",
    "name": "Проектная 1",
    "specialization": null,
    "key_skills": null,
    "manager": null,
    "manager_email": null,
    "manager_phone": null,
    "show_contacts": true,
    "chat_available": true,
    "can_respond_no_resume": true,
    "can_respond_incomplete_resume": true,
    "can_respond_cover_letter": true,
    "future_publication_time": null,
    "full_description": "Проектная 1 описание",
    "employment_type": "project",
    "employment_schedule": "full_day",
    "price_from": null,
    "price_to": 100000,
    "city": "Москва",
    "experience": "years_3_6",
    "no_resume": true,
    "status": "DRAFT",
    "avatar": null,
    "employer": 2
  },
]
const vacanciesIdsWithFavorite = ref({})

// const count = ref(0);
// const isChecked = ref(false)
// const isChecked2 = ref(false)
// const isChecked3 = ref(false)
// const gradeCompany = ref(3.9)
// const experiences = ref([
//   {
//     time: '2018 - по н.в.',
//     company: 'ООО Тепловые Сети',
//     jobTitle: 'Старший инженер-геодезист',
//     description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
//   },
//   {
//     time: '2016 - 2018',
//     company: 'ООО МосСтрой',
//     jobTitle: 'Главный инженер-геодезист',
//     description: 'Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
//   },
//   {
//     time: '2002 - 2016',
//     company: 'ООО МосСтрой',
//     jobTitle: 'Инженер-геодезист',
//     description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. '
//   },
// ]);
// const educations = ref([
//   {
//     timeEnd: '2001',
//     university: 'Российский Гидрометеорологический Университет',
//     educationTitle: 'Инженер-эколог, магистр'
//   },
//   {
//     timeEnd: '1998',
//     university: 'Российский Государственный Авиационный Университет',
//     educationTitle: 'Инженер-ппрограммист, бакалавр'
//   },
// ]);

const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  {name: "tab1", label: "Новые вакансии"},
  {name: "tab2", label: "Все вакансии"},
  {name: "tab3", label: "Вакансии 3"},
  {name: "tab4", label: "Вакансии 4"},
  {name: "tab5", label: "Вакансии 5"}
];

const activeTab = ref(0);


const page = ref(1)

const checkboxValue = ref(true)
</script>

<style>
/* Базовый размер шрифта для десктопов */
html {
  font-size: 18px;
  /* Размер шрифта по умолчанию */
}

/* Размер шрифта для планшетов */
@media (max-width: 1024px) {
  html {
    font-size: 16px;
    /* Уменьшаем размер шрифта для планшетов */
  }
}

/* Размер шрифта для мобильных устройств */
@media (max-width: 768px) {
  html {
    font-size: 3.84vw;
    /* Еще больше уменьшаем размер шрифта для мобильных устройств */
  }
}

.menu-items {


  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: left;

  color: black;
}
</style>