<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.48081 1.99274C5.10515 1.50974 4.39117 1.46529 3.9585 1.89796L2.40762 3.44884C1.68241 4.17406 1.4158 5.20148 1.73265 6.1027C3.00506 9.72185 5.08823 13.1201 7.98408 16.0159C10.8799 18.9118 14.2781 20.9949 17.8973 22.2673C18.7985 22.5842 19.8259 22.3176 20.5512 21.5924L22.102 20.0415C22.5347 19.6088 22.4903 18.8949 22.0073 18.5192L18.548 15.8286C18.3012 15.6367 17.98 15.5689 17.6767 15.6447L14.3931 16.4656C13.5012 16.6886 12.5577 16.4273 11.9077 15.7772L8.22281 12.0923C7.57274 11.4423 7.31141 10.4988 7.53438 9.60689L8.35528 6.32329C8.43109 6.02004 8.36328 5.69876 8.17137 5.45202L5.48081 1.99274ZM2.82716 0.766614C3.94033 -0.346558 5.77724 -0.232196 6.74375 1.01045L9.4343 4.46974C9.92805 5.10456 10.1025 5.93113 9.90747 6.71134L9.08657 9.99494C8.99991 10.3416 9.10148 10.7083 9.35415 10.961L13.039 14.6459C13.2917 14.8985 13.6584 15.0001 14.0051 14.9134L17.2887 14.0925C18.0689 13.8975 18.8954 14.072 19.5303 14.5657L22.9895 17.2563C24.2322 18.2228 24.3466 20.0597 23.2334 21.1728L21.6825 22.7237C20.5729 23.8333 18.9133 24.3205 17.3666 23.7767C13.5263 22.4266 9.92157 20.2161 6.85273 17.1473C3.78389 14.0784 1.57342 10.4737 0.223257 6.63337C-0.320524 5.08669 0.166695 3.42708 1.27627 2.3175L2.82716 0.766614Z" fill="#0A5CD5"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>