<template>
    <DefaultLayout>
        <div v-if="fetched" class="flex flex-col items-center">
            <div class="wj-container md:w-[992px] md:min-h-[300px] px-[60px] py-[52px]">
                <div class="flex flex-col justify-between items-start mb-[60px]">
                    <div class="title">Отправка отклика</div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Резюме</label>
                        <ResumeCard :publicationTime=resume.created_at :resumeName=resume.speciality_name
                            :age=resume.full_years :price=resume.salary_expectations
                            :currencyName=resume.currency_display_symbol :experience=resume.work_experience
                            :lastJobPlace="(resume.last_job_info !== null) ? resume.last_job_info.company_name : null"
                            :lastJobTimeStart="(resume.last_job_info !== null) ? resume.last_job_info.start_date : null"
                            :lastJobTimeEnd="(resume.last_job_info !== null) ? resume.last_job_info.end_date : null">
                            <!-- <template #actions>
                                        <Button @click="withdrawResumeMethod(item.id)" type="send-welcome-1"
                                            class="md:h-[46px] md:w-[275px] mr-[2vw]">Снять с публикации</Button>
                                        <Button @click="updateResumeMethod(item.id)" type="send-welcome-1"
                                            class="md:h-[46px] md:w-[275px] mr-[2vw]">Изменить</Button>
                                    ТУТ БУДЕТ ПОЛЕЗНО ВОТКНУТЬ КНОПКУ ДРУГИЕ РЕЗЮМЕ ПОЛЬЗОЛВАТЕЛЯ, ПОСМОТРЕТЬ ПОЛНОЕ РЕЗЮМЕ
                                    </template> -->
                        </ResumeCard>

                    </div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Вакансия(не обязательно)</label>
                        <UISelect v-model="vacancy" :options="vacancies" class="w-full"></UISelect>
                    </div>
                    </div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Текст приглашения(не обязательно)</label>
                        <UITextArea v-model="invationText" class="max-h-[400px] min-h-[100px]">
                        </UITextArea>
                    </div>

                    <div class="mt-[30px]">
                        <Button type="login-logincard" class="md:w-[362px]" @click="doInvation">Отправить
                            приглашение</Button>
                    </div>


                
            </div>
        </div>

    </DefaultLayout>


</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch } from "vue";
import ResumeCard from "@/components/cards/ResumeCard.vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import {useAuthStore} from "@/stores/authStore";
const fetch = useFetch()
const route = useRoute();
const router = useRouter();

const fetched = ref(false)
const resumeId = route.query.resume_id;
const employerProfileId = ref(null)
const invationText = ref(null)

const resume = ref({})
const vacancies = ref([])
const vacancy = ref(null)

onMounted(() => {

    const resumeRequest = fetch.anonRequest(`/resumes/${resumeId}/`, {
        method: 'GET',
    }).then((data) => {
        resume.value = data
        fetch.authRequest(`/me/`, {
            method: 'GET',
        }).then((data) => {
            employerProfileId.value = data.employer_profile_id
            fetch.authRequest(`/vacancies/?employer=${employerProfileId.value}`, {
                method: 'GET',
            }).then((data) => {
                vacancies.value = data.results.map(item => ({
                    title: item.name,
                    value: item.id // Преобразуем id в строку
                }));
                vacancies.value.push({ title: '-', value: null })
                fetched.value = true
            })
        })


    })
})

const doInvation = () => {
    fetch.authRequest('account/employer/invitations/', {
        method: 'POST',
        body: {
            text: invationText.value,
            employer: employerProfileId.value,
            resume: resumeId,
            vacancy: vacancy.value
        }
    })
        .then(response => {
            router.push('/employer/resumes')
        })
}

</script>

<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;


}

.form-label {


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
}


.form-sublabel {


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;

    margin-bottom: 20px;


}

.title {
    /* Создание вакансии */


    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    color: #4A4A4A;


}
</style>