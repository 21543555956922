<template>
  <EducationLayout class="md:pb-[100px]" :loading="loading">
    <Sheet v-if="article"
      class="mb-[40px] bg-white shadow-[4px_4px_1.5rem_0_rgba(10,92,213,0.1)] rounded-[1.5rem] p-[24px] border-[transparent] border-[1px] !h-fit">
      <div class="flex flex-col gap-[20px]">
        <div class="flex justify-between items-start">
          <div class="flex gap-[8px]">
            <div v-if="article.user.role === 'employer'">
              <img :src="`${fetch.baseURL}/files/${article.user.employer_profile.avatar}/download`"
                class="w-[44px] h-[44px] rounded-[22px] object-contain">
            </div>
            <div v-else-if="article.user.role === 'employee'">
              <img :src="`${fetch.baseURL}/files/${article.user.avatar}/download`"
                class="w-[44px] h-[44px] rounded-[22px] object-contain">
            </div>
            <div>
              <div class="font-medium text-[#1F1E1E] text-[1.125rem] leading-[1.375rem]">
                <template v-if="$authStore.me.role === 'employer'">
                  {{ article.user.employer_profile.contact_person_first_name }}
                  {{ article.user.employer_profile.contact_person_last_name }}
                </template>
              </div>
              <div>
                <template v-if="$authStore.me.role === 'employer'">
                  {{ article.user.employer_profile.company_name }}
                </template>
              </div>
            </div>
          </div>
          <div class="flex gap-[19px]">
            <div class="font-light text-[0.75rem] leading-[0.9375rem] text-[#7B7A7A]">
              {{ formatDateTime(article.created_at) }}
            </div>
            <div @click="copyUrl" class="cursor-pointer">
              <UIIcon size="16" name="dots"></UIIcon>
            </div>
          </div>
        </div>
        <div class="font-medium text-[1.125rem] leading-[1.375rem] text-[#1F1E1E]">
          {{ article.name }}
        </div>
      </div>




      <div>
        <img :src="`${fetch.baseURL}/files/${article.cover}/download`"
          class="mt-[20px] w-full h-[346px] rounded-[12px] object-cover">
      </div>

      <div class="mt-[20px] whitespace-pre-line text-base leading-5 text-black font-normal">

        {{ article.content }}
      </div>

      <div class="h-[1px] w-full bg-[#7B7A7A] my-[20px]"></div>

      <div v-if="article.user.role === 'employer'">
        <div class="text-[#1F1E1E] font-medium text-[1.125rem] leading-[1.375rem]">
          {{ article.user.employer_profile.contact_person_first_name }}
          {{ article.user.employer_profile.contact_person_last_name }}
        </div>
        <div class="text-[#1F1E1E] text-base leading-5 font-normal">
          {{ article.user.employer_profile.company_name }}
        </div>

        <div class="flex flex-row gap-x-3 mt-5">
          <div v-for="vacancy in article.related_vacancies">
            <div class="relevant-frame" @click="router.push(`/employee/vacancies/${vacancy.id}`)">
              <div class="text-[20px] font-medium h-[48px] leading-[24.5px] mb-1">{{ vacancy.name }}</div>
              <div v-if="(vacancy.price_from && vacancy.price_to) && (vacancy.price_from !== vacancy.price_to)"
                class="header-price">От {{
                  formatPrice(vacancy.price_from)
                }} {{ vacancy.currency_display_name }} до {{ formatPrice(vacancy.price_to) }} {{
                  vacancy.currency_display_name
                }}</div>
              <div v-else-if="(vacancy.price_from && vacancy.price_to) && (vacancy.price_from === vacancy.price_to)"
                class="header-price">{{
                  formatPrice(vacancy.price_from) }}
                {{ vacancy.currency_display_name }}</div>
              <div v-else-if="(vacancy.price_from)" class="header-price">От {{
                formatPrice(vacancy.price_from) }} {{ vacancy.currency_display_name }}</div>
              <div v-else-if="(vacancy.price_to)" class="header-price">От {{
                formatPrice(vacancy.price_to) }} {{ vacancy.currency_display_name }}</div>
              <div v-else class="md:mb-[20px] mb-[1.25rem]"></div>
              <div class="mb-4"></div>

              <div v-if="vacancy.city"
                class="md:text-[16px] md:leading-[19.6px] md:mb-[4px] text-[1rem] leading-[1.2rem] font-medium">
                {{ vacancy.city }}</div>
              <div v-else class="md:mb-[20px]"></div>
              <div v-if="vacancy.experience"
                class="md:text-[16px] md:leading-[19.6px] md:font-normal font-light text-[0.75rem] leading-[0.86rem] text-[#01B00A] h-[36px]">
                {{ formatexperienceForVacancy(vacancy.experience) }}</div>
              <div v-else class="md:mb-[36px]"></div>

              <div
                class="font-light text-[#7B7A7A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem]">{{
                  formatDateTime(vacancy.created_at) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="article.resume" class="flex flex-row justify-between items-end w-full">
        <div class="flex flex-row items-end gap-x-[20px]">
          <div>
            <img :src="`${fetch.baseURL}/files/${article.resume.avatar}/download`"
              class="w-[120px] h-[120px] rounded-[12px] object-fit">
          </div>
          <div class="flex flex-col">
            <div class="text-[18px] leading-[22px] font-medium">{{ article.user.first_name }} {{ article.user.last_name
              }}
            </div>
            <div class="text-[16px] leading-[20px]">{{ article.resume.speciality_name }}</div>
            <div v-if="article.resume.city_of_residence" class="text-[16px] leading-[20px] font-medium">
              {{ article.resume.city_of_residence }}</div>
            <div v-if="article.resume.work_experience">
              {{ formatAge(article.resume.work_experience) }}
              <div class="leading-[14px] text-[12px] font-light">{{ getDateYear(article.resume.last_job_info.start_date)
                }}-{{
                  getDateYear(article.resume.last_job_info.end_date) }}, {{ article.resume.last_job_info.company_name }}
              </div>
            </div>

            <div v-else class="mb-8"></div>
            <div class="font-light text-[#01B00A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem]">В
              поиске</div>
          </div>
        </div>
        <ButtonNew class="md:!h-[40px] md:!w-[150px] !" @click="router.push(`/employer/resumes/${article.resume.id}`)">Полное резюме</ButtonNew>
        
      </div>

    </Sheet>

    <div class="mb-[40px] flex justify-end gap-[4px] items-center cursor-pointer"
      @click="$router.push('/education/articles')">
      <UIIcon size="16" name="arrowLeft"></UIIcon>
      <div class="text-[#1F1E1E] font-normal text-[1rem] leading-[1.25rem]">
        Назад
      </div>
    </div>
  </EducationLayout>
</template>

<script setup>

import EducationLayout from "@/layouts/EducationLayout";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import { computed, onBeforeMount, ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import UIIcon from "@/components/ui/UIIcon";
import { formatPrice, formatDateTime, formatexperienceForVacancy, formatAge, getDateYear } from "@/composables/utils";
import { useFetch } from "@/composables/useFetch";
import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import router from "@/router";


const toast = useToast();

const fetch = useFetch()
const route = useRoute()

const articlesStore = useArticlesStore()

const id = ref(null)

const article = computed(() => {
  return articlesStore.item
})

const copyUrl = async () => {
  try {
    // Получаем текущий URL
    const currentUrl = window.location.href;

    // Копируем URL в буфер обмена
    await navigator.clipboard.writeText(currentUrl);
    toast.success('URL статьи скопирован в буфер обмена')
  } catch (err) {
    console.error('Ошибка при копировании URL: ', err);
  }
};


const loading = ref(false)

onBeforeMount(() => {
  loading.value = true
  id.value = route.params.id
  articlesStore.getPublicArticle(id.value).finally(() => {
    loading.value = false
  })
})
</script>

<style scoped>
.relevant-frame {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;

  width: 190px;

  /* White */
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  /* Blue */

  border-radius: 12px;
  border: 1px solid #0A5CD5;
  cursor: pointer;
  /* Inside auto layout */
}

.relevant-frame:hover {
  color: white !important;
  background-color: #0A5CD5;
}
</style>