<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8ZM8 5C8 6.10457 7.10457 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5Z" fill="#7B7A7A"/>
    <path d="M12 13C12 14 11 14 11 14H1C1 14 0 14 0 13C0 12 1 9 6 9C11 9 12 12 12 13ZM11 12.9965C10.9986 12.7497 10.8462 12.0104 10.1679 11.3321C9.51563 10.6798 8.2891 10 5.99999 10C3.71088 10 2.48435 10.6798 1.8321 11.3321C1.15375 12.0104 1.00142 12.7497 1 12.9965H11Z" fill="#7B7A7A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 5C13.7761 5 14 5.22386 14 5.5V7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H14V9.5C14 9.77614 13.7761 10 13.5 10C13.2239 10 13 9.77614 13 9.5V8H11.5C11.2239 8 11 7.77614 11 7.5C11 7.22386 11.2239 7 11.5 7H13V5.5C13 5.22386 13.2239 5 13.5 5Z" fill="#7B7A7A"/>
  </svg>

</template>

<script setup>

</script>

<style scoped>

</style>