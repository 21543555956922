<template>
  <LKLayout>
    <Sheet v-if="$authStore.me" class="flex flex-col justify-between h-min md:!p-[32px] !p-[12px] md:gap-y-[40px] gap-y-[20px] md:w-full w-[16.97rem]">
      <div class="flex flex-col justify-between gap-y-[8px]">
        <div class="font-medium md:text-[24px] md:leading-[29px] text-[18px] leding-[22px]">Общая информация</div>
        <div class="flex gap-[12px] items-center">

          <UIIcon size="10" name="asterisk"></UIIcon>
          <div class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Поля обязательные для
            заполнения
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-between md:gap-y-[24px] gap-y-[16px]">
        <div class="flex flex-row">
          <div class="flex flex-col">
            <div class="flex gap-[8px] items-start">
              <div class="form-label">Название вакансии
              </div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="form.name" id="form-name" placeholder="Введите название"
                     :rules="[isNotEmpty]">
            </UIInput>
          </div>
        </div>
        <div class="flex md:flex-row flex-col gap-x-[8px]">
          <div class="flex flex-col w-[50%]">
            <div class="flex gap-[8px] items-start">
              <div class="form-label">Город
              </div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <!-- <UIInput v-model="form.city" id="form-name" placeholder="Введите название"
                :rules="[isNotEmpty]">
            </UIInput> -->
            <Select :options="cities" v-model="form.city" :errorText="registrationFormErrors.city"
                    class="mb-[12px]" placeholder="Город">
              <template #error>
                <div class="mt-[10px] text-[#DB4545] text-[14px]">{{ registrationFormErrors.city }}
                </div>

              </template>
            </Select>
          </div>
          <div class="flex flex-col">
            <div class="flex gap-[8px] items-start justify-start">
              <div class="form-label">Зарплатные ожидания
              </div>
              <UIIcon size="10" name="asterisk"></UIIcon>
            </div>
            <UIInput v-model="form.price_from" id="form-name" placeholder="от"
                     :rules="[isNotEmpty, isValidNumber]">
            </UIInput>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex gap-[8px] items-start">
            <div class="form-label">Описание вакансии
            </div>
          </div>
          <UITextArea v-model="form.full_description" id="form-name" textAreaClass="h-[276px]" placeholder="">
          </UITextArea>
        </div>
      </div>
      <div class="md:mt-[24px] flex md:gap-[81px] gap-[16px] md:flex-row flex-col">
        <div>
          <div class="flex gap-[8px] items-start">
            <div class="form-label">Опыт работы</div>
            <UIIcon size="10" name="asterisk"></UIIcon>
          </div>

          <div class="md:mt-[29px] flex flex-col gap-[8px]">
            <div class="flex flex-row-reverse justify-end">
              Нет опыта работы
              <UIRadioButton v-model="form.experience" value="no_experience">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              От 3 месяцев до 1 года
              <UIRadioButton v-model="form.experience" value="months_3_12">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              От 1 года до 3 лет
              <UIRadioButton v-model="form.experience" value="years_1_3">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              От 3 до 6 лет
              <UIRadioButton v-model="form.experience" value="years_3_6">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              Более 6 лет
              <UIRadioButton v-model="form.experience" value="years_more_6">
              </UIRadioButton>
            </div>

          </div>
        </div>

        <div>
          <div class="flex gap-[8px] items-start ">
            <div class="form-label">Тип занятости</div>
            <UIIcon size="10" name="asterisk"></UIIcon>
          </div>

          <div class="md:mt-[29px] flex flex-col gap-[8px]">
            <div class="flex flex-row-reverse justify-end">
              Полная занятость
              <UIRadioButton v-model="form.employment_type" value="full_day">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              Частичная занятость
              <UIRadioButton v-model="form.employment_type" value="not_full_day">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              Проектная работа
              <UIRadioButton v-model="form.employment_type" value="project">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              Стажировка
              <UIRadioButton v-model="form.employment_type" value="internship">
              </UIRadioButton>
            </div>
            <div class="flex flex-row-reverse justify-end">
              Подработка
              <UIRadioButton v-model="form.employment_type" value="volunteering">
              </UIRadioButton>
            </div>

          </div>
        </div>


      </div>
      <div class="flex gap-[32px]">
        <div class="flex flex-col flex-grow">
          <div class="flex gap-[8px] items-start">
            <div class="form-label">Ключевые навыки</div>
          </div>
          <UIInput v-model="skillsInputValue" @change="addSkill(skillsInputValue)" id="form-name"
                   placeholder="">
          </UIInput>
        </div>
        <div class="flex-grow w-[calc(50%-8px)]"></div>
      </div>

      <div class="mt-[-22px] flex gap-[12px] flex-wrap">
        <div v-for="skill in form.skills"
             class="p-[4px] flex items-start rounded-[4px] bg-[#0A5CD5] bg-opacity-10 w-fit">
          <div class="text-black text-base leading-5 font-normal">{{ skill }}</div>
          <UIIcon size="16" name="x" color="black" class="cursor-pointer" @click="removeSkill(skill)">
          </UIIcon>
        </div>

      </div>
      <div class="flex flex-col justify-between gap-y-[8px]">
        <div class="font-medium md:text-[24px] md:leading-[29px] text-[18px] leding-[22px]">Контакты</div>
        <div class="flex gap-[12px] items-center">

          <UIIcon size="10" name="asterisk"></UIIcon>
          <div class="text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Поля обязательные для
            заполнения
          </div>
        </div>
      </div>
      <div class="flex md:flex-row flex-col gap-x-[8px]">
        <div class="flex flex-col">
          <div class="flex gap-[8px] items-start">
            <div class="form-label">Телефон
            </div>
            <UIIcon size="10" name="asterisk"></UIIcon>
          </div>
          <UIInput v-model="form.manager_phone" id="form-phone" placeholder="+7" type="tel"
                   :rules="[isNotEmpty, isValidPhoneNumber]">
          </UIInput>
        </div>
        <div class="flex flex-col">
          <div class="flex gap-[8px] items-start">
            <div class="form-label">Электронная почта
            </div>
            <UIIcon size="10" name="asterisk"></UIIcon>
          </div>
          <UIInput v-model="form.manager_email" id="form-email" placeholder="example@mail.com" type="email"
                   :rules="[isNotEmpty, isValidEmail]">
          </UIInput>
        </div>
      </div>
      <div class="flex flex-col gap-[8px]">
        <div class="flex gap-[8px] items-start">
          <div class="form-label">Адрес офиса/месты работы сотрудника
          </div>
        </div>
        <div class="flex flex-row-reverse justify-end">
          Не указывать
          <UIRadioButton v-model="setAddress.val" value="no">
          </UIRadioButton>
        </div>
        <div class="flex flex-row-reverse justify-end">
          Указывать
          <UIRadioButton v-model="setAddress.val" value="yes">
          </UIRadioButton>
        </div>
        <UIInput id="form-name" v-model="form.office_address" placeholder="Введите город" class="mt-[16px]"
                 v-if="setAddress.val === 'yes'">
        </UIInput>
      </div>
      <div class="flex md:flex-row flex-col justify-end gap-x-[24px] gap-y-[16px]">
        <div class="w-[237px]">
          <ButtonNew type="stroke" class="md:!h-[48px] !h-[40px]" @click="createOrSave('DRAFT')">Сохранить</ButtonNew>
        </div>
        <div class="w-[237px]">
          <ButtonNew class="md:!h-[48px] !h-[40px]" @click="createOrSave('PUBLISHED')">Опубликовать</ButtonNew>
        </div>
      </div>

    </Sheet>
  </LKLayout>


</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import {onMounted, reactive, ref, computed, watch} from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import {useFetch} from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import UIIcon from "@/components/ui/UIIcon.vue";
import Sheet from "@/components/cards/Sheet.vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import UICheckbox from "@/components/ui/UICheckbox";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import {useToast} from "vue-toast-notification";
import Select from "@/components/ui/Select.vue";
import {data} from "autoprefixer";

const toast = useToast()

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore()

const skillsInputValue = ref(null)

const cities = ref([])

const addSkill = (skill) => {
  if (form.skills.includes(skill)) {
    return
  }
  if (!skill) {
    return
  }
  form.skills.push(skill)
  skillsInputValue.value = null
}

const removeSkill = (skill) => {
  if (form.skills.includes(skill)) {
    form.skills.splice(form.skills.indexOf(skill), 1)
  }

}

const vacancyId = ref(route.params.id);

const mode = computed(() => {
  if (vacancyId.value) {
    return 'update'
  } else {
    return 'create'
  }
});

const savePublishedButtonLabel = computed(() => {
  if (mode.value === 'update') {
    return 'Cохранить как опубликованное'
  } else {
    return 'Опубликовать'
  }
});

const isValidCity = (city) => {
  return cities.value.some(item => item.value === city);
}

const form = reactive({
  name: null,
  city: '',
  price_from: null,
  full_description: 'Обязанности:\n-\n-\nТребования:\n-\n-\nУсловия:\n-\n-',
  employment_type: 'full_day',
  experience: 'no_experience',
  skills: [],
  manager_phone: null,
  manager_email: null,
  office_address: null,
  status: null
})

watch(() => form.city, (newValue) => {
  if (isValidCity(newValue)) {
    registrationFormErrors.city = null
  } else {
    registrationFormErrors.city = 'Укажите город правильно'
  }
});

const registrationFormErrors = reactive({
  city: 'Укажите город правильно'
})

const requiredFields = ['phone', 'email', 'name', 'city', 'price_from'];

function validateForm() {
  let isValid = requiredFields.every(field => form[field] !== null && form[field] !== '');

  if (isValidEmail(form.manager_email) !== null) {

    isValid = false
  }

  if (isValidPhoneNumber(form.manager_phone) !== null) {

    isValid = false
  }

  if (isValidNumber(form.price_from) !== null) {

    isValid = false
  }

  if (!isValidCity(form.city)) {
    isValid = false
  }

  return isValid;
}

const setAddress = reactive({
  val: 'no'
})


const isNotEmpty = (string) => {
  let s = string
  if (s === null) {
    return 'Поле не должно быть пустым'
  } else {
    s = String(s)
  }
  if (s.length > 0) {

    return null
  } else {
    return 'Поле не должно быть пустым'
  }
}

const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailPattern.test(email)) {
    return null
  } else {
    return 'Невалидный email'
  }
}

const isValidPhoneNumber = (phoneNumber) => {
  const regex = /^(7|\+7|8)\d{10}$/;
  if (regex.test(phoneNumber)) {
    return null
  } else {
    return 'Невалидный номер телефона'
  }
}

const isValidNumber = (input) => {
  const integer = parseInt(input, 10); // Используем основание 10 для десятичной системы

  // Проверяем, является ли результат целым числом
  if (!isNaN(integer) && Number.isInteger(integer)) {
    return null; // Целое число валидно
  } else {
    return 'Невалидное целое число'; // Не валидное значение
  }
};

const scrollToTop = () => {
  window.requestAnimationFrame(() => {
    window.scrollTo(0, 0);
  });
}

const createOrSave = (type) => {
  let needToTariff = false
  if (!validateForm()) {
    // scrollToTop()
    toast.error('Заполните обязательеные поля',
      {duration: 6000}
    )

    return
  }
  if (setAddress.val === 'no')
    form.office_address = null
  if (type === 'PUBLISHED') {
    if (!authStore.me.user_subscription) {
      type = 'DRAFT'
      needToTariff = true
      toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
        {duration: 6000}
      )
      router.push('/employer/lk/tarif')
    }
    else {
      if (authStore.me.vacancies_count >= authStore.me.user_subscription.vacancies_count) {
        type = 'DRAFT'
        needToTariff = true

        toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
          {duration: 6000}
        )
        router.push('/employer/lk/tarif')
      }
    }



  }
  form.status = type
  if (mode.value === 'update') {
    fetch.authRequest(`account/vacancies/${vacancyId.value}/`, {
      method: 'PATCH',
      body: {
        ...form
      }
    }).then(response => {
      toast.success('Сохранено')

      authStore.getMe(true)
      if (!needToTariff) {
        router.push(`/employer/lk/vacancies?status=${response.status}`)

      }
    })
  } else {
    fetch.authRequest('account/vacancies/', {
      method: 'POST',
      body: {
        ...form
      }
    }).then(response => {

      toast.success('Вакансия создана')
      authStore.getMe(true)
      if (!needToTariff) {
        router.push(`/employer/lk/vacancies?status=${response.status}`)


      }

    })
  }

}

onMounted(() => {
  const citiesRequest = fetch.anonRequest('/references/cities').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })
  if (mode.value === 'update') {
    const vacancyRequest = fetch.authRequest(`account/vacancies/${vacancyId.value}/`).then((data) => {
      form.name = data.name
      form.city = data.city
      form.price_from = data.price_from
      form.full_description = data.full_description
      form.employment_type = data.employment_type
      form.experience = data.experience
      form.skills = data.skills
      form.manager_phone = data.manager_phone
      form.manager_email = data.manager_email
      form.office_address = data.office_address
      form.status = data.status

      if (form.office_address !== null)
        setAddress.val = 'yes'
    })

  }
});


</script>

<style scoped>

@media (min-width: 769px) {
  .form-label {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.5rem;
  /* 24px */
  color: black;
  /* Черный цвет */
}
}

@media (max-width: 768px) {
  .form-label {
  font-size: 0.88rem;
  /* 20px */
  line-height: 1.1rem;
  /* 24px */
  color: black;
  /* Черный цвет */
}
}

</style>