import { defineStore } from 'pinia';

export const useStaticStore = defineStore('static', {
  state: () => ({
    support: {
        phone: '+79689209408 ',
        email: 'info@waterjob.ru',
        telegram: '@BVVteh',
        whatsapp: '+79689209408',
      }
  }),
});