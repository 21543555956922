<template>
<!--  <Header @toggleScroll="handleToggleScroll"></Header>-->
  <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>
  <div id="page" class="flex flex-col h-full overflow-auto"  style="background-color: rgba(10, 92, 213, 0.03)">
    <div class="flex-grow md:mt-[64px] mdt-[56px]">
      <slot name="default">
<!--        Содержимое странички (дефаулт)-->
      </slot>
    </div>

<!--    <Footer class="box-border"></Footer>-->
    <FooterNew class="box-border"></FooterNew>
  </div>

</template>

<script setup>
import {ref} from "vue";
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";
import FooterNew from "@/components/ui/FooterNew";
import HeaderNew from "@/components/ui/HeaderNew";

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

</script>

<style >


</style>