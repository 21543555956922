import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';

export const useReferencesStore = defineStore('references', {
  state: () => ({
    cities: [], // Список городов
  }),
  actions: {
    getCities() {
      if (this.cities.length > 0) {
        return
      }
      const fetch = useFetch()
      return fetch.anonRequest('/references/cities').then((data) => {
        this.cities = data.results
      })
    },

  },
});