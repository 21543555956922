<template>
    <div class="flex md:flex-row flex-col p-[24px] md:items-center md:w-[486px] w-[245px] bg-white md:gap-x-[24px] gap-x-[0.7rem] rounded-[15px] h-full hover:opacity-75 cursor-pointer">
            <img :src="props.imageSrc" class="w-[187px] h-[164px] rounded-[15px] object-cover">
            <div class="flex flex-col md:w-[277px] w-[197px] items-start">
                <div class="font-medium md:text-[24px] md:leading-[29.4px] md:mb-[12px] mt-[1.5rem] mb-[0.75rem] text-[1.12rem] leading-[1.37rem]">{{ props.name }}</div>
                <div class="md:font-bold md:text-[16px] md:leading-[19.6px] mb-[12px] text-[1.12rem] leading-[1.37rem]">{{ props.date }}</div>
                <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem] leading-[1.17rem]">{{ props.text }}</div>
            </div>
    </div>
</template>
<script setup>
import router from '@/router';
import { defineProps } from 'vue';
const props = defineProps({
    name: {
        type: String,
        default: 'Текст',
    },
    date: {
        type: String,
        default: 'Текст',
    },
    text: {
        type: String,
        default: 'Текст',
    },
    imageSrc: {
        default: null,
    },
});
</script>

<style scopen>
</style>