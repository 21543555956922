import {ofetch} from "ofetch";
import {useRouter} from "vue-router/dist/vue-router";
import {useRoute} from "vue-router";
import {useFetch} from "@/composables/useFetch";
import {onMounted, onUnmounted, ref} from "vue";

export const useUtils = () => {


  const emptySC = (v, r = null) => {
    if (!v) {
      return 'Не указано'
    }
    return r || v
  }



  return {
    emptySC,
  };
};