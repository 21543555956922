<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.09998 0.5C6.09998 0.776142 5.87612 1 5.59998 1H3.09998C2.54769 1 2.09998 1.44772 2.09998 2V14C2.09998 14.5523 2.54769 15 3.09998 15H5.59998C5.87612 15 6.09998 15.2239 6.09998 15.5C6.09998 15.7761 5.87612 16 5.59998 16H3.09998C1.99541 16 1.09998 15.1046 1.09998 14V2C1.09998 0.895431 1.99541 0 3.09998 0L5.59998 0C5.87612 0 6.09998 0.223858 6.09998 0.5Z" fill="#FF3B30"/>
    <path d="M14.8536 7.64645C15.0488 7.84171 15.0488 8.15829 14.8536 8.35355L11.8536 11.3536C11.6583 11.5488 11.3417 11.5488 11.1464 11.3536C10.9512 11.1583 10.9512 10.8417 11.1464 10.6464L13.2929 8.5H4.5C4.22386 8.5 4 8.27614 4 8C4 7.72386 4.22386 7.5 4.5 7.5L13.2929 7.5L11.1464 5.35355C10.9512 5.15829 10.9512 4.84171 11.1464 4.64645C11.3417 4.45118 11.6583 4.45118 11.8536 4.64645L14.8536 7.64645Z" fill="#FF3B30"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>