import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

import { ofetch } from "ofetch";
import { useFetch } from '@/composables/useFetch';
const { baseURL } = useFetch();

const route = useRoute();
const router = useRouter();


export const auth = async (login, password) => {
    const response = await ofetch(`${baseURL}/auth/token/`, {
        method: 'POST',
        body: {
            email: login,
            password: password,
        },
    }).catch((err) => {
        return err
    })
    
    return {response}

};