<template>
  <div class="card-slider overflow-hidden-scroll">
    <div class="card-container pb-[1rem] pt-[1rem]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
</script>

<style>
.card-slider {
  overflow-x: auto;
   /* white-space: nowrap; ЭТО растягивает карточку!!! */
  scrollbar-width: none; /* For Firefox */
}

.card-slider::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Показать скроллбар на больших экранах */
@media (min-width: 768px) { /* Для md и выше */
  .card-slider {
    overflow-x: auto; /* Включаем скролл при ширине экрана md и выше */
    scrollbar-width: thin; /* Настройка для Firefox */
  }

  .card-slider::-webkit-scrollbar {
    width: 8px; /* Ширина скроллбара */
  }

  .card-slider::-webkit-scrollbar-track {
    background: #f1f1f1; /* Цвет дорожки скроллбара */
  }

  .card-slider::-webkit-scrollbar-thumb {
    background: #888; /* Цвет ползунка */
    border-radius: 4px; /* Закругление углов */
  }

  .card-slider::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
  }
}
@media (min-width: 768px) {
  .card-container {
    display: inline-flex;
    gap: 20px;
  }
}

@media (max-width: 769px) {
  .card-container {
    display: inline-flex;
    gap: 1rem;
  }
}

.overflow-hidden-scroll::-webkit-scrollbar {
    display: none; /* Скрыть полосу прокрутки */
}

.overflow-hidden-scroll {
    scrollbar-width: none; /* Firefox 64 и выше */
    -ms-overflow-style: none; /* Internet Explorer и Edge */
}
</style>