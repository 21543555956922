<template>
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.99991 0.5C5.34509 0.5 5.62491 0.779822 5.62491 1.125V4.41747L8.47629 2.77123C8.77522 2.59865 9.15746 2.70107 9.33005 3C9.50264 3.29893 9.40022 3.68118 9.10128 3.85377L6.24991 5.50001L9.10126 7.14623C9.4002 7.31882 9.50262 7.70107 9.33003 8C9.15744 8.29893 8.7752 8.40135 8.47626 8.22877L5.62491 6.58254V9.875C5.62491 10.2202 5.34509 10.5 4.99991 10.5C4.65474 10.5 4.37491 10.2202 4.37491 9.875V6.58254L1.52356 8.22877C1.22463 8.40135 0.842386 8.29893 0.669797 8C0.497208 7.70107 0.59963 7.31882 0.898563 7.14623L3.74991 5.50001L0.898542 3.85377C0.599609 3.68118 0.497187 3.29893 0.669776 3C0.842365 2.70107 1.22461 2.59865 1.52354 2.77123L4.37491 4.41747V1.125C4.37491 0.779822 4.65474 0.5 4.99991 0.5Z" fill="#0A5CD5"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>