<template>
    <Sheet
        class="pt-[1.1rem] pl-[2rem] pr-[2rem] pb-[1.1rem] md:pt-[18px] md:pl-[17px] md:pb-[18px] md:pr-[17px] flex flex-col">
        <div class="flex flex-row justify-between">
            <div class=" text-[0.87rem] md:text-[14px]">
                Связь с поддержкой
            </div>
            <div class="cursor-pointer mt-[0.35rem] md:mt-[5px] flex flex-row" @click="toggle">
                <Icon v-if="modelValue" name="chevron-up"></Icon>
                <Icon v-else name="chevron-down"></Icon>
            </div>
        </div>

        <div v-if="modelValue" class="flex flex-col w-full mt-[1.71rem] md:mt-[24px]">
            <div class="flex flex-row w-full">
                <Button type="telegram" class="mr-[1rem] md:mr-[16px]"></Button>
                <Button type="whatsapp"></Button>
            </div>
            <div class="flex flex-row justify-start cursor-pointer mt-[1rem] md:mt-[15px]">
                <Icon name="mail"></Icon>
                <div class="ml-[0.33rem] md:ml-[5px] font-inter text-[#2894FF] text-[0.81rem] md:text-[12px]">{{ props.email }}</div>
            </div>
            <div class="flex flex-row justify-start cursor-pointer mt-[0.75rem] md:mt-[10px]">
                <Icon name="phone"></Icon>
                <div class="ml-[0.33rem] md:ml-[5px] font-inter font-light text-[0.81rem] md:text-[12px]">{{ props.phone }}</div>
            </div>

        </div>

    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Checkbox from './../../components/ui/Checkbox.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import Icon from './../../components/ui/Icon.vue';
import { defineProps, defineEmits, computed, ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    phone : {
        type: Text,
        default: "+7 (495) 221-10-27"
    },
    email : {
        type: Text,
        default: "example@mail.ru"
    }
});

const toggle = () => {

    internalChecked.value = !internalChecked.value
}

const emit = defineEmits(['update:modelValue'])

const internalChecked = ref(props.modelValue)

watch(() => props.modelValue, (newVal) => {
    internalChecked.value = newVal
})

watch(internalChecked, (newVal) => {
    emit('update:modelValue', newVal)
})

</script>

<style scoped></style>