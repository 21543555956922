<template>
    <div class="wj-checkbox-list">
        <div class="wj-checkbox-container" v-bind:class="{ 'scrollable': !showAll && props.options.length > 8 }">
            <div v-for="(item, index) in displayedOptions" :key="index"
                class="checkbox-item flex flex-row items-center">
                <Checkbox :id="generateId(index)" :title="item.title" :model-value="isChecked(item.value)"
                    @update:modelValue="updateCheckbox(item.value, $event)" />
            </div>
        </div>
        <div @click="toggleShowAll" v-if="props.options.length > 5"
            class="text-[14px] mt-[16px] !text-[#0A5CD5] font-semi-bold underline cursor-pointer">
            {{ showAll ? 'Скрыть' : 'Еще' }}
        </div>
    </div>
</template>

<script setup>
import Checkbox from './Checkbox.vue';
import { ref, reactive, watch, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});

const showAll = ref(false);
const checkedOptions = ref([...props.modelValue]);

const isChecked = (item) => {

    for (const region of props.modelValue) {
        if (region.value === item) {
            return true
        }
    }
    return false
}

const updateCheckbox = (item, value) => {

    if (value === true) {
        for (const region of props.options) {
            if (region.value === item) {
                checkedOptions.value.push(region);
            }
        }
    } else {
        for (let i = 0; i < checkedOptions.value.length; i++) {
            if (checkedOptions.value[i].value === item) {

                checkedOptions.value.splice(i, 1);
            }
        }
    }
}

const displayedOptions = computed(() => {
    if (showAll.value === false) {
        return props.options.slice(0, 5);
    } else {
        return props.options;
    }
});

const emit = defineEmits(['update:modelValue']);

watch(() => props.modelValue, () => {
    checkedOptions.value = props.modelValue
})

watch(checkedOptions, (newValue) => {
    emit('update:modelValue', newValue);

}, { deep: true });

const toggleShowAll = () => {
    showAll.value = !showAll.value;
}

const generateId = (index) => {
    return `checkbox-${index}`;
}
</script>

<style scoped>
.wj-checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.wj-checkbox-container {
    max-height: 200px;
    /* Ограничение высоты контейнера */
    overflow-y: auto;
    /* Добавление прокрутки по вертикали */
}



@media (max-width: 768px) {
    .wj-checkbox-container {
        max-height: 300px;
        /* Ограничение высоты контейнера */
        overflow-y: auto;
        /* Добавление прокрутки по вертикали */
    }
}

@media (max-width: 768px) {
    .scrollable {
        max-height: 180px;
        /* Высота для прокрутки, если items больше 8 и showAll = false */
    }
}

@media (min-width: 769px) {
    .scrollable {
        max-height: 120px;
        /* Высота для прокрутки, если items больше 8 и showAll = false */
    }
}



@media (max-width: 768px) {
    .checkbox-item {
        height: 48px;
        /* Стили для элементов чекбокса, если необходимо */
    }
}

/* Добавьте стили для вашего toggle-button и других элементов, если это необходимо */
</style>
