<template>
  <div class="flex justify-center w-full">
    <div class="w-full md:max-w-[992px] px-[1rem] md:px-0 md:pt-0">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
</style>