<template>
    <DefaultLayout>
        <div v-if="fetchedResumes && fetchedVacancy" class="flex flex-col items-center">
            <div class="wj-container md:w-[992px] md:min-h-[300px] px-[60px] py-[52px]">
                <div class="flex flex-col justify-between items-start mb-[60px]">
                    <div class="title">Отправка приглашения</div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Вакансия</label>
                        <VacancyCardListitem :vacancyId=vacancy.id :vacancyName=vacancy.name
                            :priceFrom=vacancy.price_from :experience=vacancy.experience
                            :priceTo=vacancy.price_to :currencyName=vacancy.currency_display_name
                            :city=vacancy.city :company=vacancy.employer.name
                            :publicationTime=vacancy.created_at disableResponseButton=true>
                        </VacancyCardListitem>

                    </div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Резюме(не обязательно)</label>
                        <UISelect v-model="resume" :options="resumes" class="w-full"></UISelect>
                    </div>
                    <div class="mt-[50px] w-full">
                        <label for="form-name" class="form-label">Текст отклика(не обязательно)</label>
                        <UITextArea v-model="responseText" class="max-h-[400px] min-h-[100px]">
                        </UITextArea>
                    </div>

                    <div class="mt-[30px]">
                        <Button type="login-logincard" class="md:w-[362px]" @click="doResponse">Отправить
                            отклик</Button>
                    </div>

                </div>



            </div>
        </div>

    </DefaultLayout>


</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch } from "vue";
import VacancyCardListitem from "@/components/cards/VacancyCardListitem.vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore()
const fetch = useFetch()
const route = useRoute();
const router = useRouter();

const fetchedVacancy = ref(false)
const fetchedResumes = ref(false)
const vacancy_id = route.query.vacancy_id;
const employerProfileId = ref(null)
const responseText = ref(null)

const vacancy = ref({})
const resumes = ref([])
const resume = ref(null)

const getVacancy = () => {
    fetch.anonRequest(`/vacancies/${vacancy_id}/`, {
        method: 'GET',
    }).then((data) => {
        vacancy.value = data
        fetchedVacancy.value = true
    })
}

const getResumes = () => {
    fetch.authRequest(`/resumes/?user=${authStore.me.id}`, {
            method: 'GET',
        }).then((data) => {
            resumes.value = data.results.map(item => ({
                title: item.speciality_name,
                value: item.id
            }));
            resumes.value.push({ title: '-', value: null })
            fetchedResumes.value = true
        })

}
    

onMounted(() => {
    getVacancy()
    getResumes()
})

const doResponse = () => {
    fetch.authRequest('account/employee/responses/', {
        method: 'POST',
        body: {
            text: responseText.value,
            user: authStore.me.id,
            resume: resume.value,
            vacancy: vacancy_id
        }
    })
        .then(response => {
            router.push('/employee/vacancies')
        })
}

</script>

<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;


}

.form-label {


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
}


.form-sublabel {


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;

    margin-bottom: 20px;


}

.title {
    /* Создание вакансии */


    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    color: #4A4A4A;


}
</style>