import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";
import {useToast} from "vue-toast-notification";



export const usePopupsStore = defineStore('popups', {
  state: () => ({
    invitation: {
      visible: false, // видимый popup или нет
      message: null, // сопроводительное письмо
      resumeId: null, // id резюме на которое отправляется приглашение

      callback: null, // функция которая вызывается на обе кнопки "Отправить без письма" и "Пригласить"
    },
    response: {
      visible: false, // видимый popup или нет
      message: null, // сопроводительное письмо
      vacancyId: null, // id вакансии на которое отправляется приглашение

      callback: null, // функция которая вызывается на обе кнопки "Откликнуться без письма" и "Откликнуться"
    },
    authorize: {
      visible: false,
      callback: null
    },
    logout: {
      visible: false,
      callback: null
    },
    info: {
      title: 'Заголовок',
      message: 'Информация',
      visible: false,
      callback: null
    },
  }),
  actions: {

    openInfo(title, message, callback) {
      this.info.visible = true
      this.info.title = title
      this.info.message = message
      this.info.callback = () => {
        this.info.visible = false
        if (callback) {
          callback()
        }
      }
    },

    // openAuthorizePopup(router) {
    //   const toSignup = () => {
    //     router.push('/signup')
    //   }
    //
    //   this.authorize.callback = routerPush
    // },
    openLogoutPopup() {
      const authStore = useAuthStore()
      this.logout.visible = true
      this.logout.callback = () => {
        this.logout.visible = false
        authStore.logout()
      }
    },
    openInvitationPopup(resumeId, router) {
      const authStore = useAuthStore()



      const toast = useToast()
      const fetch = useFetch()
      if (!authStore.isAuthenticated) {
        // this.openAuthorizePopup(router)
        this.authorize.visible = true
        return
      }
      if (authStore.me.role !== 'employer') {
        toast.error('Вы не являетесь работодателем', {duration: 6000})
        return;
      }
      const sendInvitation = () => {
        fetch.authRequest('account/employer/invitations/', {
          method: 'POST',
          body: {
            text: this.invitation.message,
            employer: authStore.me.employer_profile_id,
            resume: resumeId,
            vacancy: null,
          }
        })
          .then(response => {
            router.push('/employer/resumes')
            toast.success('Приглашение успешно отправлено');
          })
          .catch((err) => {
            toast.error('Ошибка при отправке приглашения');
          })
      }

      if (authStore.me.user_subscription) {
        if (authStore.me.user_subscription.can_send_responses) {
          this.invitation.resumeId = resumeId
          this.invitation.message = null
          this.invitation.visible = true
          this.invitation.callback = sendInvitation
          return
        }
      }
      toast.warning('У вас нет активного тарифа или ваш тариф не позволяет отпарвлять отклики. Оформите новый тариф.', {duration: 6000})

    },
    openResponsePopup(vacancyId, router) {
      const authStore = useAuthStore()

      const toast = useToast()
      const fetch = useFetch()

      if (!authStore.isAuthenticated) {
        this.authorize.visible = true
        return
      }
      if (authStore.me.role !== 'employee') {
        toast.error('Вы не являетесь соискателем', {duration: 6000})
        return;
      }
      const sendResponse = () => {
        fetch.authRequest('account/employee/responses/', {
          method: 'POST',
          body: {
            text: this.response.message,
            user: authStore.me.id,
            resume: null,
            vacancy: vacancyId,
          }
        })
          .then(response => {
            router.push('/employee/vacancies')
            toast.success('Отклик успешно отправлен');
          })
          .catch((err) => {
            toast.error('Ошибка при отправке отклика');
          })
      }

      this.response.vacancyId = vacancyId
      this.response.message = null
      this.response.visible = true
      this.response.callback = sendResponse

    }

  },
});