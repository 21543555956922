<template>
  <div class="flex flex-col md:w-[317px] mb-[50px]">
    <div class="flex flex-col">
      <div class="md:text-[24px] md:leading-[29.4px] font-medium md:mb-[32px]">Фильтр</div>
      <div class="filter-menu-item">Регион</div>
      <div class="mb-[20px]">
        <Input placeholder="Введите название города" v-model="inputRegion" outlineColor="#0A5CD5"></Input>
      </div>

      <!-- <CheckboxesList :items=filteredRegions v-model="regionsModel">
      </CheckboxesList> -->
      <UICheckboxList :options="filteredRegions" v-model="regionsModel">

      </UICheckboxList>
      <div class="md:mb-[32px]"></div>

      <div class="filter-menu-item">Тип занятости</div>
      <CheckboxesList :items=jobTypes v-model="jobTypesModel">
      </CheckboxesList>
      <div class="md:mb-[32px]"></div>
      <!-- {{ jobTypesModel }} -->

      <div class="filter-menu-item">График работы</div>
      <CheckboxesList :items=jobShedules v-model="jobShedulesModel">
      </CheckboxesList>
      <div class="md:mb-[32px]"></div>

      <div class="filter-menu-item">Заработная плата</div>
      <div class="flex flex-row justify-end items-center">
        <div class="md:text-[14px] md:mr-[11px]" style="word-break: normal;">От</div>
        <Input type="number" placeholder="Введите сумму" v-model="inputPrice" outlineColor="#0A5CD5"></Input>
      </div>
      <div class="md:mb-[32px]"></div>

      <div class="filter-menu-item">Образование</div>
      <CheckboxesList :items=educations v-model="educationsModel">
      </CheckboxesList>
      <div class="md:mb-[32px]"></div>

      <div class="filter-menu-item">Опыт работы</div>
      <CheckboxesList :items=jobExperiences v-model="jobExperiencesModel">
      </CheckboxesList>
      <div class="md:mb-[56px]"></div>

      <div class="flex flex-row justify-between items-center">
        <!--        {{modelValue}}-->
        <div class="font-medium md:text-[16px] md:leading-[19.6px] text-[#0A5CD5] cursor-pointer underline"
          @click="clearFilters">Сбросить все</div>
        <!--        <ButtonNew type="stroke" class="md:!w-[150px]">Готово</ButtonNew>-->
      </div>
    </div>
  </div>

</template>


<script setup>
import Sheet from './Sheet.vue';
import Input from '../ui/Input.vue';
import ButtonNew from '../ui/ButtonNew.vue';
import CheckboxesList from './../../components/ui/CheckboxesList.vue';
import UICheckboxList from '../ui/UICheckboxList.vue';
import { defineProps, defineEmits, computed, ref, watch, onMounted } from 'vue';
import { useFetch } from '@/composables/useFetch';

const { authRequest, anonRequest } = useFetch();

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => { },
  },
});

const inputRegion = ref(null)

const jobTypes = ref(['Полная занятость', 'Частичная занятость', 'Проектная работа', 'Стажировка'])
const jobShedules = ref(['Полный день', 'Сменный график', 'Удаленная работа', 'Гибкий график'])
const jobExperiences = ref(['Нет опыта', 'От 1 года до 3 лет', 'От 3 до 6 лет', 'Более 6 лет'])
const educations = ref(['Высшее', 'Среднее', 'Неполное среднее', 'Нет образования', 'Не указано'])
const jobTypesModel = ref([])
const regionsModel = ref([])
const jobShedulesModel = ref([])
const jobExperiencesModel = ref([])
const educationsModel = ref([])
const filtersModel = ref({
  'jobTypes': [],
  'cities': [],
  'jobShedules': [],
  'jobExperiences': [],
  'educations': [],
  'price': null
})

onMounted(() => {
  fetchRegionsData();
});

const regions = ref([])
const filteredRegions = ref([])
const loading = ref(false);
const error = ref(null);

const inputPrice = ref(null)



const clearFilters = () => {
  emit('clearFilters', {
    jobTypes: [],
    cities: [],
    jobShedules: [],
    jobExperiences: [],
    price: null,
    educations: [],
  })
  jobTypesModel.value = []
  regionsModel.value = []
  jobShedulesModel.value = []
  jobExperiencesModel.value = []
  educationsModel.value = []
  inputPrice.value = null
  inputRegion.value = ''
  // filtersModel.value.jobTypes = []
  // filtersModel.value.cities = []
  // jobShedulesModel.value = []
  // jobExperiencesModel.value = []
  // educationsModel.value = []
  // filtersModel.value.price = null
  // inputPrice.value = null
  // filtersModel.value.price = null
  // inputPrice.value = ''
}

const fetchRegionsData = async () => {
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку

  try {
    const url = `references/regions-cities/`;

    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      regions.value = Object.entries(response.results).map(([title, cities]) => ({
        title,
        value: title,
        cities
      }));
      filteredRegions.value = regions.value
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const emit = defineEmits(['update:modelValue', 'clearFilters']);

watch(inputRegion, (newValue) => {
  filteredRegions.value = regions.value.filter(region =>
    region.cities.some(city =>
      city.toLowerCase().includes(newValue.toLowerCase())
    )
  );

})


watch(filtersModel, (newValue) => {
  emit('update:modelValue', newValue);
}, { deep: true })

watch(jobTypesModel, (newValue) => {
  filtersModel.value.jobTypes = newValue
}, { deep: true })

watch(regionsModel, (newValue) => {
  let citiesArray = []
  newValue.forEach((element) => {
    citiesArray.push(element.value)
  })
  filtersModel.value.cities = citiesArray
}, { deep: true })

watch(jobShedulesModel, (newValue) => {
  filtersModel.value.jobShedules = newValue
}, { deep: true })

watch(jobExperiencesModel, (newValue) => {
  filtersModel.value.jobExperiences = newValue
}, { deep: true })


let changePriceFilterTimeout = null
watch(inputPrice, (newValue) => {
  if (changePriceFilterTimeout) {
    clearTimeout(changePriceFilterTimeout)
  }
  changePriceFilterTimeout = setTimeout(() => {
    filtersModel.value.price = newValue
  }, 500)
  // filtersModel.value.price = newValue
}, { deep: true })

watch(educationsModel, (newValue) => {
  filtersModel.value.educations = newValue
}, { deep: true })

</script>

<style scoped>
.filter-menu-item {
  @apply md:text-[16px] md:leading-[19.6px] font-bold md:mb-[20px];
}
</style>