<template>
  <LKLayout>
    <div class="font-medium text-[1.875rem] leading-[2.3125rem] text-[#1F1E1E]">Если у вас возникли вопросы, можете обратится в техподдержку</div>
    <div class="mt-[40px] text-black text-[1.25rem] leading-[1.5rem] font-normal">Выберите удобный способ связи:</div>

    <div class=" flex-col mt-[21px] md:mt-[32px]">
      <div class="flex items-center gap-[12px] px-[10px]">
        <img src="/icons/envelope-12-blue.svg" class="w-[24px] h-[24px]">

        <div
             class="flex items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E]">
          <a href="mailto:info@waterjob.ru">{{staticStore.support.email}}</a>

        </div>
      </div>
      <div class="flex items-center gap-[12px] px-[10px]">
        <img src="/icons/telephone-12-blue.svg" class="w-[24px] h-[24px]">
        <div
             class="flex flex-grow items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E] justify-between"
        >

          <a href="tel:+79689209408">{{staticStore.support.phone}}</a>

        </div>
      </div>


    </div>

    <div class="mt-[48px] text-black text-[1rem] leading-[1.5rem] font-normal">или напишите нам в мэсэнджеры</div>

    <div class="mt-[22px] flex flex-col gap-[24px] ">
      <div class="flex gap-[24px] items-center">
        <UIIcon name="telegram" size="40"></UIIcon>
        <div class="text-sm leading-5 text-center text-[#1F1E1E] font-normal">

          <a href="https://t.me/BVVteh" target="_blank">{{staticStore.support.telegram}}</a>

        </div>
      </div>
      <div class="flex gap-[24px] items-center">
        <UIIcon name="whatsApp" size="40"></UIIcon>
        <div class="text-sm leading-5 text-center text-[#1F1E1E] font-normal">
          <a href="https://wa.me/+79689209408" target="_blank">{{staticStore.support.whatsapp}}</a>
        </div>
      </div>
    </div>


  </LKLayout>
</template>

<script setup>
import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import UIIcon from "@/components/ui/UIIcon";
import { useStaticStore } from '@/stores/staticStore';

const staticStore = useStaticStore();

</script>

<style scoped>

</style>