<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 18C23 18.8284 22.3284 19.5 21.5 19.5H3.5C2.67157 19.5 2 18.8284 2 18V9C2 8.17157 2.67157 7.5 3.5 7.5H5.25736C6.45083 7.5 7.59543 7.02589 8.43934 6.18198L9.68198 4.93934C9.96329 4.65803 10.3448 4.5 10.7426 4.5H14.2574C14.6552 4.5 15.0367 4.65804 15.318 4.93934L16.5607 6.18198C17.4046 7.0259 18.5492 7.5 19.7426 7.5H21.5C22.3284 7.5 23 8.17157 23 9V18ZM3.5 6C1.84315 6 0.5 7.34315 0.5 9V18C0.5 19.6569 1.84315 21 3.5 21H21.5C23.1569 21 24.5 19.6569 24.5 18V9C24.5 7.34315 23.1569 6 21.5 6H19.7426C18.947 6 18.1839 5.68393 17.6213 5.12132L16.3787 3.87868C15.8161 3.31607 15.053 3 14.2574 3H10.7426C9.94699 3 9.18393 3.31607 8.62132 3.87868L7.37868 5.12132C6.81607 5.68393 6.05301 6 5.25736 6H3.5Z" fill="white"/>
    <path d="M12.5 16.5C10.4289 16.5 8.75 14.8211 8.75 12.75C8.75 10.6789 10.4289 9 12.5 9C14.5711 9 16.25 10.6789 16.25 12.75C16.25 14.8211 14.5711 16.5 12.5 16.5ZM12.5 18C15.3995 18 17.75 15.6495 17.75 12.75C17.75 9.8505 15.3995 7.5 12.5 7.5C9.6005 7.5 7.25 9.8505 7.25 12.75C7.25 15.6495 9.6005 18 12.5 18Z" fill="white"/>
    <path d="M5 9.75C5 10.1642 4.66421 10.5 4.25 10.5C3.83579 10.5 3.5 10.1642 3.5 9.75C3.5 9.33579 3.83579 9 4.25 9C4.66421 9 5 9.33579 5 9.75Z" fill="white"/>
  </svg>


</template>

<script setup>

</script>

<style scoped>

</style>