<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5303 2.46967C12.8232 2.76256 12.8232 3.23744 12.5303 3.53033L4.06066 12L12.5303 20.4697C12.8232 20.7626 12.8232 21.2374 12.5303 21.5303C12.2374 21.8232 11.7626 21.8232 11.4697 21.5303L2.46967 12.5303C2.17678 12.2374 2.17678 11.7626 2.46967 11.4697L11.4697 2.46967C11.7626 2.17678 12.2374 2.17678 12.5303 2.46967Z" fill="#0A5CD5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 2.46967C18.8232 2.76256 18.8232 3.23744 18.5303 3.53033L10.0607 12L18.5303 20.4697C18.8232 20.7626 18.8232 21.2374 18.5303 21.5303C18.2374 21.8232 17.7626 21.8232 17.4697 21.5303L8.46967 12.5303C8.17678 12.2374 8.17678 11.7626 8.46967 11.4697L17.4697 2.46967C17.7626 2.17678 18.2374 2.17678 18.5303 2.46967Z" fill="#0A5CD5"/>
  </svg>




</template>

<script setup>

</script>

<style scoped>

</style>