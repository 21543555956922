<template>
    <LKLayout>
        <div v-for="order in orders" class="choice-tarif-frame mb-[20px] cursor-pointer" @click="GoTo(order)">
            <div class="flex flex-row justify-between w-full">
                <div class="flex flex-col w-[50%]">
                    <div class="text-[16px] leading-[19.6px] font-medium">Cчет N{{ order.id }}</div>
                    <div class="text-[16px] leading-[19.6px]">{{ order.name }}</div>
                    <div class="text-[16px] leading-[19.6px]">Сумма {{ order.amount }} руб.</div>
                </div>
                <div class="flex flex-col w-[50%] items-end">
                    <div class="text-[12px] leading-[14.7px] text-[#7B7A7A] font-light">{{
                        formatDateTime(order.created_at)
                    }}</div>
                    <div class="flex flex-row items-center">
                        <div :class="orderStatusColor(order.status)"></div>
                        <div class="ml-2 text-[12px] leading-[14.7px] font-light">{{ orderStatusLabel(order.status)
                            }}</div>
                    </div>
                    <a @click.stop v-if="order.bill" download class="text-[14px] leading-[17px] text-[#0A5CD5] mt-3"
                        :href="downloadFile(order.bill)">
                        <div class="flex flex-row">
                            <UIIcon name="pdf"></UIIcon>
                            <div class="ml-1">Скачать Cчет N{{ order.id }}</div>
                        </div>
                    </a>
                    <a @click.stop v-if="order.acceptance_certificate"
                        class="text-[14px] leading-[17px] text-[#0A5CD5] mt-3"
                        :href="downloadFile(order.acceptance_certificate)">
                        <div class="flex flex-row">
                            <UIIcon name="pdf"></UIIcon>
                            <div class="ml-1">Скачать Акт N{{ order.id }}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-center">
            <UIPagination class="mb-[50px] md:mt-[33px]" v-if="pagination.totalPages > 0"
                :total-pages="pagination.totalPages" :current-page="pagination.page"
                @page-changed="pagination.page = $event"></UIPagination>

        </div>
    </LKLayout>
</template>

<script setup>
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch, onBeforeMount } from "vue";
import { formatDateTime, daysUntil, formatDateTimeToDate } from "@/composables/utils";
import { useFetch } from "@/composables/useFetch";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import UIPagination from "@/components/ui/UIPagination.vue";
import router from "@/router";

const fetch = useFetch()

const pagination = reactive({
    page: 1,
    limit: 10,
    totalPages: 1,
})

const orderStatusColor = (status) => {
    if (status === 'PENDING')
        return 'w-3 h-3 rounded-full bg-[#F0CE0E]'
    else if (status === 'WAITING')
        return 'w-3 h-3 rounded-full bg-[#01B00A]'
    else if (status === 'SUCCESS')
        return 'w-3 h-3 rounded-full bg-[#0B54BF]'
}

const orderStatusLabel = (status) => {
    if (status === 'PENDING')
        return 'Счет формируется'
    else if (status === 'WAITING')
        return 'Ожидание оплаты'
    else if (status === 'SUCCESS')
        return 'Услуга активирована'
}

const downloadFile = (id) => {
    return `http://localhost:8000/api/files/${id}/download`
}

const fetched = ref(false)

const getOrders = () => {
    let queryParams = {}
    queryParams.limit = pagination.limit
    queryParams.offset = (pagination.page - 1) * pagination.limit
    fetch.authRequest(`accountant/orders/?offset=${queryParams.offset}&limit=${queryParams.limit}`).then((data) => {
        orders.value = data.results
        pagination.totalPages = Math.ceil(data.count / pagination.limit)
    })
}

onBeforeMount(() => {
    getOrders()
    fetched.value = true
})

const GoTo = (orderInfo) => {
    router.push(`/accountant/lk/orders/${orderInfo.id}`)
}

// const order = ref(null)
const orders = ref([])
</script>

<style scoped>
.choice-tarif-frame {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;

    width: 655px;

    /* White */
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    /* Blue */
   
    border-radius: 24px;

    /* Inside auto layout */
}

.choice-tarif-frame:hover {
    border: 1px solid #0A5CD5;
}
</style>