<template>
  <EducationLayout class="md:pb-[100px]" :loading="loading">
    <Sheet v-if="event"
      class="mb-[40px] bg-white shadow-[4px_4px_1.5rem_0_rgba(10,92,213,0.1)] rounded-[1.5rem] p-[24px] border-[transparent] border-[1px] !h-fit">
      <div class="text-[20px] leading-[25px] font-medium text-[#0A5CD5]">{{ event.name }}</div>
      <div class="text-[16px] leading-[20px] font-medium">"{{ event.short_description }}"</div>




      <div>
        <img :src="`${fetch.baseURL}/files/${event.cover}/download`"
          class="mt-[20px] w-full h-[346px] rounded-[12px] object-cover">
      </div>

      <div class="mt-[20px] whitespace-pre-line text-base leading-5 text-black font-normal" v-html="event.content">
      </div>

      <div v-if="showForm">
        <div class="h-[1px] w-full bg-[#7B7A7A]"></div>
        <div class="flex flex-col gap-y-[16px]">
          <div class="flex flex-row gap-x-[16px] w-full mt-[40px]">
            <div class="flex flex-col w-full">
              <div class="flex gap-[8px] items-start">
                <div class="form-label">Имя
                </div>
                <UIIcon size="10" name="asterisk"></UIIcon>
              </div>
              <UIInput v-model="form.first_name" id="form-name" placeholder="Ваше имя"
                :errorText="validator.errors.first_name">
              </UIInput>
            </div>
            <div class="flex flex-col w-full">
              <div class="flex gap-[8px] items-start">
                <div class="form-label">Фамилия
                </div>
                <UIIcon size="10" name="asterisk"></UIIcon>
              </div>
              <UIInput v-model="form.last_name" id="form-name" placeholder="Ваша фамилия"
                :errorText="validator.errors.last_name">
              </UIInput>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-[290px]">
          <div class="flex gap-[8px] items-start">
            <div class="form-label">"Электронная почта"
            </div>
            <UIIcon size="10" name="asterisk"></UIIcon>
          </div>
          <UIInput v-model="form.email" id="form-name" placeholder="Ваша почта"
            :errorText="validator.errors.email">
          </UIInput>
        </div>
      </div>


      <div class="flex flex-row justify-end mt-[24px]">
        <ButtonNew @click="buttonAction" class="!w-[290px]">Зарегистрироваться</ButtonNew>
      </div>

    </Sheet>

    <div class="mb-[40px] flex justify-end gap-[4px] items-center cursor-pointer"
      @click="$router.push('/education/articles')">
      <UIIcon size="16" name="arrowLeft"></UIIcon>
      <div class="text-[#1F1E1E] font-normal text-[1rem] leading-[1.25rem]">
        Назад
      </div>
    </div>
  </EducationLayout>
</template>

<script setup>

import EducationLayout from "@/layouts/EducationLayout";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useArticlesStore } from "@/stores/articlesStore";
import UIIcon from "@/components/ui/UIIcon";
import UIInput from "@/components/ui/UIInput.vue";
import { formatPrice, formatDateTime, formatexperienceForVacancy, formatAge, getDateYear } from "@/composables/utils";
import { useFetch } from "@/composables/useFetch";
import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import ButtonNew from "@/components/ui/ButtonNew.vue";
import router from "@/router";
import { useEventsStore } from "@/stores/eventsStore";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import { useFormValidator } from "@/composables/useFormValidator";
import { useFormsStore } from "@/stores/formsStore";

const validatorFuncs = useValidateFuncs()

const save = () => {
  fetch.anonRequest(`/event-registration`, {
    method: 'POST',
    body: {
      event: id.value,
      ...form
    }
  }).then(response => {
    toast.success('Вы успешно зарегистрированы')
    router.push('/education/events')
  })
}

const buttonAction = () => {
  if (showForm.value === false) {
    showForm.value = true
  } else {
    if (validator.validateAll() === true) {
      save()
    } else {
      toast.error('Заполните форму корректно')
    }
    // validator.handleSubmit(save)
  }
}


const form = reactive({
  first_name: null,
  last_name: null,
  email: null,
})

const formsStore = useFormsStore()


const validator = useFormValidator(
  form,
  {
    'first_name': [validatorFuncs.isNotEmpty],
    'last_name': [validatorFuncs.isNotEmpty],
    'email': [validatorFuncs.isValidEmail],
  }, true
)

const toast = useToast();

const showForm = ref(false)

const fetch = useFetch()
const route = useRoute()

const eventsStore = useEventsStore()

const id = ref(null)

const event = computed(() => {
  return eventsStore.item
})



const loading = ref(false)

onBeforeMount(() => {
  loading.value = true
  id.value = route.params.id
  eventsStore.getPublicEvent(id.value).finally(() => {
    loading.value = false
  })
})
</script>

<style scoped>
/* Имя */
.form-label {

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;

}
</style>