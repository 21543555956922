<template>
    <DefaultLayout >


<!--    <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>-->
    <authTemplateCard>
<!--        <div class="flex flex-row w-full">-->
<!--            <div class="flex flex-row items-center relative w-full md:mb-[32px] mb-[2.9rem]">-->
<!--                <div class="absolute left-0">-->
<!--                    <Icon @click="router.back()" v-if="isMobile" name="chevron-left-back-mobile"></Icon>-->
<!--                </div>-->
<!--                <div class="flex flex-row justify-center w-full">-->
<!--                    <div class="registration-header">Вход</div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->

      <UIPageHeader class="md:hidden" title="Вход">
        <template #search> <div></div></template>
      </UIPageHeader>

      <div class="hidden md:block mb-[32px] registration-header ">Вход</div>

        <Input inputClass=" !p-[16px_16px]" class="mb-[12px]" type="email" :errorText="loginFormErrors.login" v-model="loginForm.login"
            placeholder="Электронная почта"></Input>
        <Input inputClass=" !p-[16px_16px]" class="mb-[12px]" type="password" :errorText="loginFormErrors.password" v-model="loginForm.password"
            placeholder="Пароль"></Input>
        <div class="flex flex-row justify-between items-center md:mb-[12px] md:mt-[12px] w-full">
            <div class="flex flex-row justify-start items-center">
                <BoolIcon @click="isChecked = !isChecked" name='checkbox' :checked="isChecked"></BoolIcon>
                <div class="ml-[6px] md:text-[16px] text-[0.85rem]">Запомнить пароль</div>
            </div>
            <div class="font-light text-[12px] text-[#0A5CD5] cursor-pointer hover:underline" @click="router.push('/reset_password')">Забыли пароль?</div>
        </div>
        <ButtonNew type="blue-medium" @click="login" class="mt-[32px]">Войти</ButtonNew>


        <div class="flex flex-row justify-between items-center md:w-[345px] w-[335px] h-[44px] mt-[32px]">
            <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem]">
                У меня нет аккаунта.
            </div>
            <div @click="router.push('/signup')"
                class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer">
                Зарегистрироваться
            </div>
        </div>
        <SupportCardNew></SupportCardNew>

    </authTemplateCard>
    </DefaultLayout>
<!--    <FooterNew></FooterNew>-->

</template>


<script setup>
import authTemplateCard from '@/components/cards/authTemplateCard.vue';
import Icon from '@/components/ui/Icon.vue';
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import router from '@/router';
import Input from '@/components/ui/Input.vue';
import BoolIcon from '@/components/ui/BoolIcon.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import {useFetch} from "@/composables/useFetch";
import {useAuthStore} from "@/stores/authStore";
import {auth} from '@/composables/auth';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";

const authStore = useAuthStore()

const isChecked = ref(false)
const loginError = ref(false)

const loginForm = reactive({
    login: '',
    password: '',
})

const loginFormErrors = reactive({
    login: null,
    password: null,
})

const login = async () => {
    Object.entries(loginFormErrors).forEach(([key, value]) => {
        loginFormErrors[key] = null
    });
    loginError.value = false

    if (!isNotEmpty(loginForm.login)) {
            loginFormErrors.login = 'Поле должно быть заполненно'
            loginError.value = true
    }

    if (!isNotEmpty(loginForm.password)) {
            loginFormErrors.password = 'Поле должно быть заполненно'
            loginError.value = true

    }

    if (!isValidEmail(loginForm.login)) {
        loginFormErrors.login = 'Невалидный email'
        loginError.value = true

    }
    
    if (loginError.value) {
        return
    }


    const result = await auth(loginForm.login, loginForm.password)
    if (result.response.status === 401) {
        loginFormErrors.login= 'Неправильный логин и/или пароль'
        loginFormErrors.password = 'Неправильный логин и/или пароль'
    } else {
        localStorage.setItem("store.auth.token", result.response.access);
        authStore.getMe(true).then(() => {
            router.push('/')
        })
    }
};

const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

const isNotEmpty = (string) => {
    return string.length > 0;
}

const route = useRoute();

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});



const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>