<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.402 3.48967C18.161 1.23735 15.1732 0 11.9914 0C5.43976 0 0.102637 5.33714 0.096935 11.8888C0.096935 13.9872 0.644332 16.0285 1.68211 17.8361L0 24L6.30647 22.3464C8.0456 23.2929 10.0014 23.7947 11.9914 23.7947H11.9971C18.5488 23.7947 23.8859 18.4576 23.8916 11.9002C23.8859 8.72416 22.6486 5.73628 20.402 3.48967ZM11.9914 21.7819C10.2124 21.7819 8.47326 21.3029 6.95651 20.402L6.59728 20.1853L2.85673 21.1661L3.85459 17.5168L3.62081 17.1404C2.62865 15.5666 2.10976 13.7477 2.10976 11.8831C2.10976 6.44334 6.54596 2.00713 11.9971 2.00713C14.6372 2.00713 17.1176 3.0392 18.9878 4.90378C20.8524 6.77406 21.8788 9.25446 21.8788 11.8945C21.8731 17.3514 17.4369 21.7819 11.9914 21.7819ZM17.4141 14.3806C17.1176 14.2324 15.6578 13.5139 15.3841 13.4113C15.1104 13.3143 14.9109 13.263 14.717 13.5595C14.5174 13.856 13.9472 14.5289 13.7762 14.7227C13.6051 14.9223 13.4283 14.9451 13.1318 14.7969C12.8353 14.6486 11.8774 14.335 10.7427 13.32C9.85886 12.5331 9.26584 11.5581 9.08908 11.2616C8.91802 10.9651 9.07197 10.8054 9.22023 10.6572C9.35137 10.526 9.51673 10.3093 9.66499 10.1383C9.81324 9.96721 9.86456 9.84177 9.96149 9.6422C10.0584 9.44262 10.0128 9.27156 9.93868 9.12331C9.86456 8.97505 9.27154 7.50962 9.02065 6.91661C8.78117 6.335 8.53598 6.41483 8.35351 6.40912C8.18245 6.39772 7.98288 6.39772 7.78331 6.39772C7.58373 6.39772 7.26442 6.47185 6.99072 6.76835C6.71702 7.06486 5.95295 7.78332 5.95295 9.24875C5.95295 10.7142 7.01923 12.1226 7.16749 12.3222C7.31574 12.5217 9.26014 15.521 12.2423 16.8097C12.9494 17.1176 13.5025 17.3001 13.9358 17.4369C14.6486 17.665 15.2929 17.6308 15.8061 17.5567C16.3763 17.4711 17.5623 16.8382 17.8132 16.1426C18.0584 15.4469 18.0584 14.8539 17.9843 14.7284C17.9102 14.603 17.7106 14.5289 17.4141 14.3806Z" fill="#25D366"/>
  </svg>

</template>

<script setup>

</script>

<style scoped>

</style>