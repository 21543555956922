import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";

export const useFilesStore = defineStore('filesStore', {
  state: () => ({
  }),
  actions: {
    getFile(id){
      const fetch = useFetch()
      const authStore = useAuthStore()

      return fetch.authRequest(`/files/${id}`).then((data) => {
        return data
      })
    },

  },
});