<template>
    <div class="flex flex-col">
        <label class="form-label">Отклик {{ formatDateTime(item.created_at) }}</label>

<!--        <div class="mb-[20px]"></div>-->
<!--        <label v-if="item.resume" class="form-sublabel">Резюме</label>-->
<!--        <label v-else class="form-sublabel">Соискатель не указал резюме</label>-->
        <div >
          <div  class="flex items-center gap-5">
            <label v-if="item.resume" class="form-sublabel">Резюме</label>
            <label v-else class="form-sublabel">Соискатель не указал резюме</label>
            <div  v-if="item.resume" class="nav-item" active @click="$router.push(`/employer/resumes/${item.resume.id}`)">
              {{ props.item.resume.speciality_name }}
            </div>
          </div>
<!--            <ResumeCard :publicationTime=props.item.resume.created_at :resumeName=props.item.resume.speciality_name-->
<!--                :age=props.item.resume.full_years :price=props.item.resume.salary_expectations-->
<!--                :currencyName=props.item.resume.currency_display_symbol :experience=props.item.resume.work_experience-->
<!--                :lastJobPlace="(props.item.resume.last_job_info !== null) ? props.item.resume.last_job_info.company_name : null"-->
<!--                :lastJobTimeStart="(props.item.resume.last_job_info !== null) ? props.item.resume.last_job_info.start_date : null"-->
<!--                :lastJobTimeEnd="(props.item.resume.last_job_info !== null) ? props.item.resume.last_job_info.end_date : null">-->
<!--            </ResumeCard>-->
        </div>


      <div v-if="item.vacancy" class="flex items-center gap-5">
        <label v-if="item.vacancy" class="form-sublabel">Вакансия</label>
        <div class="nav-item" active @click="$router.push(`/employee/vacancies/${props.item.vacancy.id}`)">
          {{ props.item.vacancy.name }}
        </div>
      </div>

<!--      -->
<!--        <label class="form-sublabel">Вакансия</label>-->
<!--        <div>-->
<!--            <VacancyCardListitem :vacancyId=props.item.vacancy.id :vacancyName=props.item.vacancy.name-->
<!--                :priceFrom=props.item.vacancy.price_from :experience=props.item.vacancy.experience-->
<!--                :priceTo=props.item.vacancy.price_to :currencyName=props.item.vacancy.currency_display_name-->
<!--                :city=props.item.vacancy.city :company=props.item.vacancy.employer_name-->
<!--                :publicationTime=props.item.vacancy.created_at disableResponseButton=true></VacancyCardListitem>-->
<!--        </div>-->
    </div>


</template>

<script setup>
import ResumeCard from "./../../components/cards/ResumeCard.vue";
import Tabs from "@/components/ui/Tabs";
import Pagination from "@/components/ui/Pagination";
import Button from "@/components/ui/Button";
import VacancyCardListitem from "./../../components/cards/VacancyCardListitem"
import { formatPrice, formatDateTime, formatDateTimeVacancyCreated, formatexperienceForVacancy } from '@/composables/utils';
import { defineProps, defineEmits, computed, ref, watch } from 'vue';

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    }
});
</script>
<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.inner-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
}

.title {
    /* Создание вакансии */


    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    color: #4A4A4A;


}

.form-label {


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
}

.form-sublabel {


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;

    /*margin-bottom: 20px;*/


}
</style>