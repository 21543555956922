<template>
  <div v-if="!large" class="flex items-center cursor-pointer  select-none w-fit">
    <input
      :id="id"
      type="checkbox"
      class="hidden"
      :checked="modelValue"
      @update="emit('update:modelValue', !$event.target.checked)"
    />
    <div v-if="isMobile"
      class="flex items-center justify-center w-[1.5rem] h-[1.5rem] border-[0.06rem] rounded-[0.21rem]"
      :class="[
        internalChecked ? internalCheckedCheckClasses : internalCheckedNocheckClasses
      ]"
      @click="toggle"
    >
      <svg
        v-if="internalChecked"
        class="w-[1.5rem] h-[1.5rem] text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <div v-else
      class="flex items-center justify-center w-[16px] h-[16px] border-[1px] rounded-[4px]"
      :class="[
        internalChecked ? internalCheckedCheckClasses : internalCheckedNocheckClasses
      ]"
      @click="toggle"
    >
      <svg
        v-if="internalChecked"
        class="w-[16px] h-[16px] text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <label :class=labelClasses :for="id">{{ title }}</label>
  </div>
  <div v-else class="flex items-center cursor-pointer  select-none ">
    <input
      :id="id"
      type="checkbox"
      class="hidden"
      v-model="internalChecked"
    />
    <div
         class="flex items-center justify-center !w-[26px] !h-[26px] border-[1px] rounded-[4px]"
         :class="[
        internalChecked ? internalCheckedCheckClasses : internalCheckedNocheckClasses
      ]"
         @click="toggle"
    >
      <svg
        v-if="internalChecked"
        class="!w-[26px] !h-[26px] text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
<!--

  /* Соискатели с неполным резюме */

width: 257px;
height: 19px;


font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #000000;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

  -->
    <label :class=labelClasses :for="id" class="text-[16px] ">{{ title }}</label>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, onUnmounted, computed} from 'vue'
import { v4 as uuidv4 } from 'uuid';

const id = ref(uuidv4())

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Label'
  },
  labelClass: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: '0A5CD5'
  },
  bgColor: {
    type: String,
    default: '0A5CD5'
  },
  large: {
    default: false,
  }
})

const labelClasses = computed(() => {
  return "text-[0.87rem] md:text-[14px] font-normal ml-[0.85rem] md:ml-[18px] pt-[2px] cursor-pointer " + props.labelClass
});


const internalCheckedCheckClasses = computed(() => {
    return `bg-[#${props.bgColor}] border-transparent`
});

const internalCheckedNocheckClasses = computed(() => {
    return `bg-transparent border-[#${props.color}]`
});


const toggle = () => {
  internalChecked.value = !internalChecked.value
}

const emit = defineEmits(['update:modelValue'])

const internalChecked = ref(props.modelValue)

watch(() => props.modelValue, (newVal) => {
  internalChecked.value = newVal
})

watch(internalChecked, (newVal) => {
  emit('update:modelValue', newVal)
})

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});
</script>
