<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3589 11.2396C15.0613 9.72296 16 8.00199 16 8.00199C16 8.00199 13 2.50199 8 2.50199C6.98462 2.50199 6.05172 2.72881 5.20967 3.09033L5.98054 3.8612C6.60983 3.63517 7.28441 3.50199 8 3.50199C10.1194 3.50199 11.879 4.67017 13.1679 5.9591C13.8037 6.5949 14.2978 7.23392 14.6327 7.71441C14.7055 7.81876 14.7704 7.9152 14.8273 8.00199C14.7704 8.08878 14.7055 8.18522 14.6327 8.28957C14.2978 8.77006 13.8037 9.40909 13.1679 10.0449C13.0031 10.2097 12.8306 10.3726 12.6506 10.5312L13.3589 11.2396Z"
            fill="#0A5CD5" />
        <path
            d="M11.2975 9.17813C11.4286 8.81055 11.5 8.41461 11.5 8.00199C11.5 6.06899 9.933 4.50199 8 4.50199C7.58738 4.50199 7.19144 4.57339 6.82386 4.70451L7.64618 5.52684C7.76176 5.51046 7.87989 5.50199 8 5.50199C9.38071 5.50199 10.5 6.62128 10.5 8.00199C10.5 8.1221 10.4915 8.24023 10.4752 8.35581L11.2975 9.17813Z"
            fill="#0A5CD5" />
        <path
            d="M8.35385 10.4771L9.17617 11.2995C8.80858 11.4306 8.41263 11.502 8 11.502C6.067 11.502 4.5 9.93499 4.5 8.00199C4.5 7.58936 4.5714 7.19341 4.70253 6.82582L5.52485 7.64814C5.50847 7.76374 5.5 7.88188 5.5 8.00199C5.5 9.3827 6.61929 10.502 8 10.502C8.12012 10.502 8.23825 10.4935 8.35385 10.4771Z"
            fill="#0A5CD5" />
        <path
            d="M3.34944 5.47274C3.16945 5.63142 2.99693 5.79427 2.83211 5.9591C2.19631 6.5949 1.70216 7.23392 1.36727 7.71441C1.29454 7.81876 1.22963 7.9152 1.1727 8.00199C1.22963 8.08878 1.29454 8.18522 1.36727 8.28957C1.70216 8.77006 2.19631 9.40909 2.83211 10.0449C4.12103 11.3338 5.88062 12.502 8 12.502C8.7156 12.502 9.39018 12.3688 10.0195 12.1428L10.7904 12.9136C9.9483 13.2752 9.01539 13.502 8 13.502C3 13.502 0 8.00199 0 8.00199C0 8.00199 0.938717 6.28101 2.64112 4.76441L3.34944 5.47274Z"
            fill="#0A5CD5" />
        <path d="M13.6464 14.3555L1.64645 2.35554L2.35355 1.64844L14.3536 13.6484L13.6464 14.3555Z" fill="#0A5CD5" />
    </svg>




</template>

<script setup>

</script>

<style scoped></style>