import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';
import {useAuthStore} from "@/stores/authStore";
import {useToast} from "vue-toast-notification";


export const useActionSheetsStore = defineStore('actionSheets', {
  state: () => ({
    filters: {
      visible: false, // видимый sheet или нет
      confirmCallback: null, // функция которая вызывается на "Готово"

      //  СЮДА ДОБАВИТЬ ФИЛЬТРЫ КОТОРЫЕ ЕСТЬ И КОТОРЫЕ ВЫБРАНЫ
      filterValues: {
        jobTypes: [],
        cities: [],
        jobShedules: [],
        jobExperiences: [],
        price: null,
        educations: [],
      }
      

    },
    ordering: {
      visible: false, // видимый popup или нет
      callback: null, // функция которая вызывается на обе кнопки "Откликнуться без письма" и "Откликнуться"

      confirmCallback: null, // функция которая вызывается на "Готово"

      value: 'newest'

      //  СЮДА ДОБАВИТЬ ВАРИАНТЫ СОРТИРОВКИ И ТОТ КОТОРЫЙ ВЫБРАН
    }
  }),
  actions: {
    openFiltersSheet(confirmCallback) {
      this.filters.visible = true
      this.filters.confirmCallback = () => {
        confirmCallback()
        this.filters.visible = false
      }
    },
    openOrderingSheet(confirmCallback) {
      this.ordering.visible = true
      this.ordering.confirmCallback = () => {
        confirmCallback()
        this.ordering.visible = false
      }
    }
    // openInvitationPopup(resumeId, router) {
    //   const authStore = useAuthStore()
    //
    //   const toast = useToast()
    //   const fetch = useFetch()
    //   if (authStore.me.role !== 'employer') {
    //     toast.error('Вы не являетесь работодателем', {duration: 6000})
    //     return;
    //   }
    //   const sendInvitation = () => {
    //     fetch.authRequest('/invations/do', {
    //       method: 'POST',
    //       body: {
    //         text: this.invitation.message,
    //         employer: authStore.me.employer_profile_id,
    //         resume: resumeId,
    //         vacancy: null,
    //       }
    //     })
    //       .then(response => {
    //         router.push('/employer/resumes')
    //         toast.success('Приглашение успешно отправлено');
    //       })
    //       .catch((err) => {
    //         toast.error('Ошибка при отправке приглашения');
    //       })
    //   }
    //
    //   if (authStore.me.user_subscription) {
    //     if (authStore.me.user_subscription.can_send_responses) {
    //       this.invitation.resumeId = resumeId
    //       this.invitation.message = null
    //       this.invitation.visible = true
    //       this.invitation.callback = sendInvitation
    //       return
    //     }
    //   }
    //   toast.warning('У вас нет активного тарифа или ваш тариф не позволяет отпарвлять отклики. Оформите новый тариф.', {duration: 6000})
    //
    // },
    // openResponsePopup(vacancyId, router) {
    //   const authStore = useAuthStore()
    //
    //   const toast = useToast()
    //   const fetch = useFetch()
    //
    //   if (authStore.me.role !== 'employee') {
    //     toast.error('Вы не являетесь соискателем', {duration: 6000})
    //     return;
    //   }
    //   const sendResponse = () => {
    //     fetch.authRequest('/responses/do', {
    //       method: 'POST',
    //       body: {
    //         text: this.response.message,
    //         user: authStore.me.id,
    //         resume: null,
    //         vacancy: vacancyId,
    //       }
    //     })
    //       .then(response => {
    //         router.push('/employee/vacancies')
    //         toast.success('Отклик успешно отправлен');
    //       })
    //       .catch((err) => {
    //         toast.error('Ошибка при отправке отклика');
    //       })
    //   }
    //
    //   this.response.vacancyId = vacancyId
    //   this.response.message = null
    //   this.response.visible = true
    //   this.response.callback = sendResponse
    //
    // }

  },
});