import { onMounted, onUnmounted, ref } from "vue";
import { useReferencesStore } from "@/stores/referencesStore";

export const useValidateFuncs = () => {

  const referencesStore = useReferencesStore()

  // В текущем варианте проверяет только на кол-во символов
  const isValidPassword = (password) => {
    return password.length > 5
  }

  const isValidCity = (city) => {
    return referencesStore.cities.some(item => item.value === city);
  }

  const isNotEmpty = (string) => {
    let s = string
    if (s === null) {
      return 'Поле не должно быть пустым'
    } else {
      s = String(s)
    }
    if (s.length > 0) {

      return null
    } else {
      return 'Поле не должно быть пустым'
    }
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
      return null
    } else {
      return 'Невалидный email'
    }
  }

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^(7|\+7|8)\d{10}$/;
    if (regex.test(phoneNumber)) {
      return null
    } else {
      return 'Невалидный номер телефона'
    }
  }

  const isValidNumber = (input) => {
    // Проверяем, что ввод состоит только из цифр и является целым числом
    const isInteger = /^-?\d+$/.test(input); // Поддержка отрицательных чисел

    if (isInteger) {
        return null; // Целое число валидно
    } else {
        return 'Невалидное целое число'; // Не валидное значение
    }
};

  return {
    isValidPassword,
    isValidCity,
    isNotEmpty,
    isValidEmail,
    isValidPhoneNumber,
    isValidNumber,
  };
};