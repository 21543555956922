<template>
    <InvationCard :item=invation></InvationCard>
    <div class="mb-[20px]"></div>
    <label class="form-sublabel">Компания</label>
    <CompanyCard :companyName=props.invation.employer.name
        :companyShortDescription="props.invation.employer.short_description" :grade=props.invation.employer.grade
        :reportsCount=props.invation.employer.reports_count :imageSrcId=props.invation.employer.avatar>
    </CompanyCard>

    <div class="mb-[20px]"></div>
    <div v-if="props.type === 'employee'">
        <label class="form-sublabel">Контакты для связи с работодателем</label>
        <div class="contacts">{{ invation.employer.email }}</div>
        <div class="contacts mt-[10px]">{{ invation.employer.phone }}</div>
    </div>
    <div v-else>
        <div v-if="showEmployeeContacts === true">
            <label class="form-sublabel">Контакты для связи с потенциальным работником</label>
            <div class="contacts">{{ invation.resume.contacts.email }}</div>
            <div class="contacts mt-[10px]">{{ invation.resume.contacts.phone }}</div>
        </div>
        <div v-else>
            <label class="form-sublabel">Контакты для связи с потенциальным работником  доступны только для принятых приглашений</label>
        </div>
        
    </div>


    <div style="white-space: normal; overflow-wrap: break-word;"
        class="md:w-[685px] mt-[20px] text-[0.7rem] md:text-[14px] text-[#000000]  font-normal">
        {{ invation.text }}
    </div>
    <!-- <slot name="default"></slot> -->
</template>

<script setup>
import InvationCard from "./../../components/cards/InvationCard.vue";
import CompanyCard from './../../components/cards/CompanyCard.vue'
import Button from "@/components/ui/Button";
import { formatPrice, formatDateTime, formatDateTimeVacancyCreated, formatexperienceForVacancy } from '@/composables/utils';
import { defineProps, defineEmits, computed, ref, watch } from 'vue';
const props = defineProps({
    invation: {
        type: Object,
        default: () => ({})
    },
    type: {
        type: String,
        default: 'employee'
    },
    showEmployeeContacts: {
        type: Boolean,
        default: false
    }
});


</script>

<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;


}

.form-label {


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
}


.form-sublabel {


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;

    margin-bottom: 20px;


}

.title {
    /* Создание вакансии */


    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    color: #4A4A4A;


}

.inner-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
}

.contacts {


    font-style: semi-bold;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: left;

    color: black;
}
</style>