<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289L28.7071 21.2929C29.0976 21.6834 29.0976 22.3166 28.7071 22.7071C28.3166 23.0976 27.6834 23.0976 27.2929 22.7071L16 11.4142L4.70711 22.7071C4.31658 23.0976 3.68342 23.0976 3.29289 22.7071C2.90237 22.3166 2.90237 21.6834 3.29289 21.2929L15.2929 9.29289Z" fill="#0A5CD5" stroke="#0A5CD5" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>



</template>

<script setup>

</script>

<style scoped>

</style>